import {AccountSubscriptionDto, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {Draft, values} from '@peachy/utility-kit-pure'
import {createContext, ParentComponent, useContext} from 'solid-js'
import {createStore} from 'solid-js/store'
import {IStore} from '../../../global/storage/IStore'
import {editSubscriptionSessionStore} from '../../../providers/AccountSubscription/session/stores'
import {SolidStore} from '../../../global/storage/SolidStore'
import {getAccountSubscription} from '../../../providers/AccountProvider'
import {cloneDeep} from 'lodash-es'
import {PlanConfig} from '@peachy/product-client'
import {usePlanConfig} from '../../../config/configureApp'

type ContextState = {
    isEditing: boolean

    stores: {
        sessionStore: IStore<Draft<MrSubscriptionQuoteResponse>>
        solidStore: IStore<Draft<MrSubscriptionQuoteResponse>>
    }
}

type ContextValue = [
    state: ContextState,
    actions: {
        editPlan(isEditing: boolean): void
        isEditingPlan(): boolean
    }
]

const YourPlanContext = createContext<ContextValue>()

export const YourPlanProvider: ParentComponent = (props) => {

    const planConfig = usePlanConfig()
    const sessionStore = editSubscriptionSessionStore()
    const subscriptionRequest = mapToSubscriptionRequest(getAccountSubscription(), planConfig)

    const [state, setState] = createStore({
        isEditing: sessionStore.exists(),
        stores: {
            sessionStore: sessionStore,
            solidStore: new SolidStore<Draft<MrSubscriptionQuoteResponse>>(subscriptionRequest)
        }
    })

    const isEditingPlan = () => state.isEditing

    const editPlan = (isEditing: boolean) => {
        if (isEditing && !sessionStore.exists()) {
            sessionStore.save(subscriptionRequest as MrSubscriptionQuoteResponse)
        }

        setState('isEditing', isEditing)
    }

    return (
        <YourPlanContext.Provider value={ [state, { editPlan, isEditingPlan }] as ContextValue }>
            {props.children}
        </YourPlanContext.Provider>
    )
}

export const getYourPlanContext = () => useContext(YourPlanContext)

export function isEditingPlan (): boolean {
    const [_, { isEditingPlan }] = getYourPlanContext()
    return isEditingPlan()
}

export function getSolidStore() {
    const [state] = getYourPlanContext()
    return state.stores.solidStore
}

export function getSessionStore() {
    const [state] = getYourPlanContext()
    return state.stores.sessionStore
}

const mapToSubscriptionRequest = (dto: AccountSubscriptionDto, planConfig: PlanConfig): Draft<MrSubscriptionQuoteResponse> => {
    const dtoClone = cloneDeep(dto)

    const { account, subscription } = dtoClone
    const { policies, plans, ...restSubscriptionProps } = subscription

    const orderedPlans = planConfig.getAllPlans().map(configuredPlan => values(plans).find(p => p.name === configuredPlan.name))

    return {
        account,
        subscription: restSubscriptionProps,
        plans: orderedPlans,
        policies: values(policies)
    } as Draft<MrSubscriptionQuoteResponse>
}
