import {TextBox} from '@peachy/client-kit'
import {Component, createEffect, createMemo, createSignal, Show} from 'solid-js'
import {Draft, formatDmyDate, NOOP} from '@peachy/utility-kit-pure'
import {addMonths, addYears, isAfter, isWithinInterval, startOfToday, subDays} from 'date-fns'
import {DEPENDANT_MAX_AGE, DEPENDANT_MIN_AGE, MAX_AGE, MIN_AGE} from '../../service/validation/ValidationService'
import styles from './StartDate.module.css'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {PeachyDatePicker} from '../DatePicker/PeachyDatePicker'
import {MrLife} from '@peachy/core-domain-pure'

export const StartDate: Component<{ datePicker?: boolean }> = (props) => {
    const store = useStore()

    const [showDatePicker, setShowDatePicker] = createSignal(false)

    const minStartDate = startOfToday()
    const oneMonthAfterMinStartDate = addMonths(minStartDate, 1)

    const maxStartDateFor = (minAge: number, maxAge: number, filter: (life: Draft<MrLife>) => boolean) => {
        // One day before they are no longer max age
        return subDays(
            addYears(
                store.getOldestDateOfBirthForLives(minAge, filter),
                maxAge + 1,
            ), 1)
    }

    const calculateMaxStartDate = createMemo(() => {
        const maxStartDateForOldestNonDependant = maxStartDateFor(MIN_AGE,MAX_AGE, life => life.type === 'PRIMARY' || life.type === 'SECONDARY')
        const maxStartDateForOldestDependants = maxStartDateFor(DEPENDANT_MIN_AGE, DEPENDANT_MAX_AGE, life => life.type === 'DEPENDANT')

        const maxStartDateForAllLives = maxStartDateForOldestNonDependant < maxStartDateForOldestDependants ? maxStartDateForOldestNonDependant : maxStartDateForOldestDependants

        if (isAfter(maxStartDateForAllLives, oneMonthAfterMinStartDate)) {
            return oneMonthAfterMinStartDate
        }

        if (isWithinInterval(maxStartDateForAllLives, { start: minStartDate, end: oneMonthAfterMinStartDate })) {
            return maxStartDateForAllLives
        }

        return minStartDate
    })

    const [maxStartDate, setMaxStartDate] = createSignal(calculateMaxStartDate())

    createEffect(() => {
        // handle cases where the start date is before today
        if (store.getSubscriptionStartDate() < startOfToday().getTime()) {
            store.updateStartDate(startOfToday().getTime())
        }

        setMaxStartDate(calculateMaxStartDate())
    })

    const isInvalidStartDateRange = () => maxStartDate() < minStartDate

    return (
        <div class={styles.startDate}>
            <label>Start date:</label>
            <div>
                <TextBox disabled value={formatDmyDate(store.getSubscriptionStartDate())} onChange={NOOP}
                         class={isInvalidStartDateRange() ? styles.invalidField : ''}/>
                <Show when={props.datePicker}>
                    <i onClick={() => setShowDatePicker(true)} class={'icon-calendar'}/>
                    <PeachyDatePicker
                        isOpen={showDatePicker()}
                        onDismiss={() => setShowDatePicker(false)}
                        onSelect={startDate => store.updateStartDate(startDate.getTime())}
                        minDate={minStartDate}
                        maxDate={maxStartDate()}
                        selectedDate={new Date(store.getSubscriptionStartDate())}
                        headerText={'Choose your start date'}
                        submitText={'Confirm'}
                    />
                </Show>
            </div>
        </div>
    )
}
