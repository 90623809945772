import {createSignal, Match, Switch} from 'solid-js'
import {PasswordBox} from '../../../global/forms/PasswordBox/PasswordBox'
import {TextBox} from '../../../global/forms/TextBox/TextBox'
import {useIamService} from '../../../providers/AppServiceProvider'
import {isValidEmail, isValidPassword} from '../../../to-abstract/validate'
import {IamError} from '../components/IamError'
import {PasswordPolicy} from '../components/PasswordPolicy'
import {IamProps} from '../IamModal'
import {Button} from '../../../global/forms/Button'


export function ResetPassword(props: IamProps) {

    const iamService = useIamService()

    const [email, setEmail] = props.email

    const [password, setPassword] = createSignal('')
    const [passwordCode, setPasswordCode] = createSignal('')

    const hasCredentials = () => isValidEmail(email()) && isValidPassword(password()) && !!passwordCode()

    const resetPassword = () => {
        if (hasCredentials()) iamService.resetPassword(email(), password(), passwordCode())
    }

    const sendCode = () => {
        if (isValidEmail(email())) iamService.requestPasswordCode(email())
    }

    return <>
        <h3>Reset password</h3>
        <IamError/>
        <TextBox value={email()} onInput={(e) => setEmail(e.currentTarget.value)} placeholder={'email address'}/>
        <Switch>
            <Match when={iamService.isInState('PasswordResetRequested.Idle')}>
                <Button theme="primary" onClick={sendCode} disabled={!isValidEmail(email())}>Send code</Button>
            </Match>
            <Match when={iamService.isInState('PasswordResetRequested.PasswordCodeRequired')}>
                <TextBox value={passwordCode()} onInput={(e) => setPasswordCode(e.currentTarget.value)} placeholder={'code'}/>
                <a onClick={sendCode}>Resend code &rarr; </a>
                <PasswordBox
                    placeholder={'new password'}
                    value={password()}
                    onInput={(e) => setPassword(e.currentTarget.value)}
                    onEnter={resetPassword}
                />
                <PasswordPolicy/>

                <Button theme="primary" onClick={resetPassword} disabled={!hasCredentials()}>Reset Password</Button>
            </Match>
        </Switch>
        <a onClick={iamService.cancelPasswordReset}>Cancel &rarr; </a>
    </>
}
