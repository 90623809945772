import {Component, createSignal, Show} from 'solid-js'
import {classList} from '@peachy/utility-kit-pure'
import {createToggleSignal, TextBox} from '@peachy/client-kit'
import styles from './PlanSelector.module.css'
import policyRowStyles from '../PolicyRow.module.css'
import tableStyles from '../ManageMembersTable.module.css'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {PopupListSelector} from '../../../PopupListSelector/PopupListSelector'
import {LifeRowCellProps} from '../PolicyRow'

const BENCH_PLAN_INDEX = 0

export const PlanSelector: Component<LifeRowCellProps> = (props) => {
    const store = useStore()
    const plansInSelector = store.getPlansInSelector()
    const [showPlanSelector, togglePlanSelector] = createToggleSignal(false)
    let labelElement: HTMLLabelElement

    const validPlanCss = () => store.isFullValidation() && !props.life.planId ? policyRowStyles.invalidField : undefined

    const [currentPlan, setCurrentPlan] = createSignal(
        props.life.planId ? store.getPlanById(props.life.planId).name : plansInSelector[BENCH_PLAN_INDEX]
    )

    const onPlanSelect = (_: string, planIndex) => {
        const selectedPlan = plansInSelector[planIndex]

        if (currentPlan() !== selectedPlan) {
            setCurrentPlan(selectedPlan)
            const selectedPlanId = (planIndex > BENCH_PLAN_INDEX) ? store.getPlans()[planIndex - 1].id : undefined

            store.assignPolicyToPlan({
                policy: store.getPolicy(props.policyId),
                fromPlanId: props.life.planId,
                policyIndex: props.policyIndex,
                toPlanId: selectedPlanId
            })
        }

        togglePlanSelector()
    }

    const ReadonlyPlan = () => <TextBox value={currentPlan()} readonly={true}/>

    const SelectPlan = () => <>
        <label ref={labelElement} class={classList(styles.planSelector, validPlanCss())} onClick={togglePlanSelector}>
            {currentPlan()}
            <i class={'fa-solid fa-caret-down'} />
        </label>
        <PopupListSelector
            list={plansInSelector}
            onSelect={onPlanSelect}
            onDismiss={togglePlanSelector}
            selection={currentPlan()}
            enabled={showPlanSelector()}
            locatorElement={labelElement}
        />
    </>

    return (
        <td class={tableStyles.plan}>
            <Show when={!props.readonly} fallback={<ReadonlyPlan/>}>
                <SelectPlan/>
            </Show>
        </td>
    )
}
