import {createToggleSignal} from '@peachy/client-kit'
import {classList, Dictionary, Draft} from '@peachy/utility-kit-pure'
import {
    closestCenter,
    createDroppable,
    createSortable,
    DragDropProvider,
    DragDropSensors,
    Draggable,
    DragOverlay,
    Droppable,
    Id,
    SortableProvider
} from '@thisbeyond/solid-dnd'
import {Component, createEffect, createMemo, For, JSXElement, Match, Show, Switch} from 'solid-js'
import {createStore} from 'solid-js/store'
import {Button} from '../../../global/forms/Button'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {BENCH_ID} from '../../../providers/AccountSubscription/stores/AccountSubscriptionStore'
import {getPolicy} from '../../../providers/AccountProvider'
import {ManageMembers} from '../../ManageMembers/ManageMembers'
import {MoreInfoModal} from '../../Modal/MoreInfoModal'
import styles from './PlanEmployeeAssignment.module.css'
import {MrLife, MrPolicy, toClass} from '@peachy/core-domain-pure'

export const FIRST_NAME_PLACEHOLDER = 'Employee'

const viewModeClass = () => {
    const store = useStore()
    return styles[store.getViewMode().toLowerCase()]
}

type EmployeeState = 'EDITABLE' | 'READONLY' | 'DELETED'

type EmployeeDroppable = Draft<MrLife> & {
    isMovable: boolean
    hasChanged: boolean
    state: EmployeeState
    dependantCount: number
}

const getDependantCount = (policy: Draft<MrPolicy>) => {
    const policies = policy ? Object.values(policy.lives).filter(l => l.type !== 'PRIMARY') : []
    return policies.length
}

const isEmployeeEditable = (life: Draft<MrLife>) => {
    const store = useStore()
    return store.isPlanEditable() && store.canEditLife(life.id)
}

const isEmployeeDeleted = (life: Draft<MrLife>) => {
    const store = useStore()
    return store.isPlanEditable() && store.getDeletedLivesForPlan(life.planId).find((l) => l.id === life.id)
}

const getEmployeeState = (life: Draft<MrLife>): EmployeeState => {
    if (isEmployeeEditable(life)) {
        return 'EDITABLE'
    }

    if (isEmployeeDeleted(life)) {
        return 'DELETED'
    }

    return 'READONLY'
}

const hasPolicyChanged = (policy?: Draft<MrPolicy>): boolean => {
    const originalPolicy = toClass(getPolicy(policy?.id), MrPolicy)
    const currentPolicy = toClass(policy, MrPolicy)

    // new or deleted policy / employee
    if (!currentPolicy || !originalPolicy) {
        return true
    }

    // determine dependant changes
    const originalPolicyLives = originalPolicy.getNonPrimaryLives()
    const currentPolicyLives = currentPolicy.getNonPrimaryLives()

    if (originalPolicyLives.length !== currentPolicyLives.length) {
        return true
    }

    const uniqueDependants = new Set([ ...originalPolicyLives.map(l => l.id), ...currentPolicyLives.map(l => l.id) ])

    return (uniqueDependants.size !== originalPolicyLives.length)
}


const asEmployeeDroppable = (employee: Draft<MrLife>): EmployeeDroppable => {
    const store = useStore()

    const policy = createMemo(() => store.getPolicyForLife(employee.id))
    const dependantCount = getDependantCount(policy())

    const hasChanged = store.getViewMode() === 'PORTAL' ? hasPolicyChanged(policy()) : false
    const employeeState = getEmployeeState(employee)

    return {
        ...employee,
        state: employeeState,
        isMovable: employeeState === 'EDITABLE',
        hasChanged: hasChanged,
        dependantCount: dependantCount
    }
}

const asEmployeeDroppables = (employees: Draft<MrLife>[]): EmployeeDroppable[] => employees.map(asEmployeeDroppable)

const EmployeeDisplay: Component<{ employee: EmployeeDroppable }> = (props) => {
    return (
        <>
            <div class={styles.firstName}>{props.employee.firstname.length ? props.employee.firstname : FIRST_NAME_PLACEHOLDER}</div>
            <div class={styles.lastName}>{props.employee.lastname}</div>
            <Show when={props.employee.dependantCount}>
                <div class={styles.dependantCount}>+{props.employee.dependantCount}</div>
            </Show>
        </>
    )
}

// decorator which adds dragging abilities to a JSX element
const draggable = (element: JSXElement, data: any) => {
    const sortable = createSortable(data.id, data)

    return (
        <div use:sortable class={sortable.isActiveDraggable ? styles.dragging : ''}>
            {element}
        </div>
    )
}

const DraggableEmployee: Component<{ employee: EmployeeDroppable }> = (props) => {
    return draggable(<EmployeeCard employee={props.employee}/>, props.employee)
}

const EmployeeCard: Component<{ employee: EmployeeDroppable }> = (props) => {
    const classes = classList(
        styles.employee,
        props.employee.hasChanged ? styles.changed : '',
        props.employee.state !== 'EDITABLE'? styles.readonly : '',
        props.employee.state === 'DELETED' ? styles.deleted : ''
    )

    return (
        <div class={classes}>
            <EmployeeDisplay employee={props.employee}/>
        </div>
    )
}

const EmployeeCardOverlay: Component<{ employee: EmployeeDroppable }> = (props) => {
    return <span class={classList(viewModeClass(), styles.overlay)}>
        <EmployeeCard employee={props.employee}/>
    </span>
}


const PlanEmployees: Component<{ id: string, employees: EmployeeDroppable[], class?: string }> = (props) => {
    // @ts-ignore this is actually used (via the use:directive)
    const droppable = createDroppable(props.id)
    const store = useStore()

    return (
        <div use:droppable class={props.class ?? styles.panel}>
            <Show when={store.isPlanEditable() && props.id != 'bench' && store.canAddPolicy()}>
                <Button class={classList(styles.addEmployee)} onClick={() => store.addPolicy(props.id)}>
                    <span>Add employee</span>
                </Button>
            </Show>

            <SortableProvider ids={props.employees.map(employee => employee.id)}>
                <For each={props.employees}>{(employee) =>
                    <Switch>
                        <Match when={store.isPlanEditable()}><DraggableEmployee employee={employee} /></Match>
                        <Match when={!store.isPlanEditable()}><EmployeeCard employee={employee} /></Match>
                    </Switch>
                }
                </For>
            </SortableProvider>
        </div>
    )
}

const BenchContainer: Component<{ containers: Record<string, EmployeeDroppable[]> }> = (props) => {
    const store = useStore()

    return (
        <div class={styles.sidePanel}>
            <Show when={store.isPlanEditable()}
                  fallback={<Button theme="dark" class={styles.edit} onClick={store.onPortalEdit}>Edit members</Button>}
            >
                <ManageMembers/>
                <Show when={store.getViewMode() === 'PORTAL'}>
                    <Button theme="darkSecondary" class={styles.cancel} onClick={store.onPortalCancel}>Cancel & exit</Button>
                </Show>
                <section class={styles.benchContainer}>
                    <header>
                        <span>Member bench:</span><BenchModal/>
                    </header>
                    <div class={styles.benchArea}>
                        <PlanEmployees id={BENCH_ID} employees={props.containers[BENCH_ID]} class={styles.bench}/>
                    </div>
                </section>
            </Show>
        </div>
    )
}

const BenchModal = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    return (
        <>
            <i class={'icon-info'} onClick={toggleIsOpen}/>
            <MoreInfoModal isOpen={isOpen()} onDismiss={toggleIsOpen}>
                <h3>Member Bench</h3>
                <section>
                    <p>Members on here can be dragged and dropped onto different plans</p>
                    <p>Any employees on the bench won't be included or priced into your plan</p>
                </section>
            </MoreInfoModal>
        </>
    )
}

/**
 * The plan -> employee assigner component
 */
export const PlanEmployeeAssignment: Component = () => {
    const store = useStore()
    const plans = store.getPlans()

    const [containers, setContainers] = createStore<Dictionary<EmployeeDroppable[]>>({})

    //runs everytime a change to the lives occurs
    createEffect(() => {
        const employeeToPlans = {bench: asEmployeeDroppables(store.getLivesForPlanOrdered())}

        plans.forEach(plan => {
            // TODO tidy this up maybe?
            const orderedLives = store.getLivesForPlanOrdered(plan.id).filter(life => life.type === 'PRIMARY')
            const deletedLives = store.getDeletedLivesForPlan(plan.id).filter(life => life.type === 'PRIMARY')
            const allLives = [].concat(orderedLives).concat(deletedLives).filter(employee => employee)
            employeeToPlans[plan.id] = asEmployeeDroppables(allLives)
        })

        setContainers(employeeToPlans)
    })

    const containerIds = createMemo(() => Object.keys(containers))
    const planContainerIds = () => containerIds().filter((key) => key !== BENCH_ID)
    const isContainer = (id: Id) => containerIds().includes(id as string)

    const getContainerId = (lifeId: Id) => {
        for (const [key, lives] of Object.entries(containers)) {
            if (lives.find(life => life.id === lifeId)) {
                return key
            }
        }
    }

    const closestContainerOrItem = (draggable: Draggable, droppables: Droppable[], context) => {
        const closestContainer = closestCenter(
            draggable,
            droppables.filter((droppable) => isContainer(droppable.id)),
            context
        )

        if (closestContainer) {
            const containerItemIds = containers[closestContainer.id].map(life => life.id)
            const closestItem = closestCenter(
                draggable,
                droppables.filter((droppable) => containerItemIds.includes(droppable.id as string)),
                context
            )

            if (!closestItem) {
                return closestContainer
            }

            if (getContainerId(draggable.id) !== closestContainer.id) {
                const isLastItem = (containerItemIds.findIndex(employeeId => employeeId === closestItem.id)) === containerItemIds.length - 1

                if (isLastItem) {
                    const belowLastItem = draggable.transformed.center.y > closestItem.transformed.center.y

                    if (belowLastItem) {
                        return closestContainer
                    }
                }
            }
            return closestItem
        }
    }

    const move = (draggable: Draggable, droppable: Droppable, hasFinishedDragging = false) => {
        if (!draggable.data.isMovable) {
            return
        }

        const {id: lifeId} = draggable as { id: string }
        const {id: droppableId} = droppable as { id: string }

        const draggableContainerId = getContainerId(lifeId)
        const droppableContainerId = isContainer(droppableId) ? droppableId : getContainerId(droppableId)

        if (draggableContainerId != droppableContainerId || hasFinishedDragging) {
            const containerItemIds = containers[droppableContainerId].map(employee => employee.id)

            let index = containerItemIds.indexOf(droppableId)

            if (index === -1) index = containerItemIds.length

            store.assignPolicyToPlan({
                policy: store.getPolicyForLife(lifeId),
                fromPlanId: draggableContainerId,
                toPlanId: droppableContainerId,
                orderIndex: index
            })
        }
    }

    const onDragOver = ({draggable, droppable}) => {
        if (draggable && droppable) {
            move(draggable, droppable)
        }
    }

    const onDragEnd = ({draggable, droppable}) => {
        if (draggable && droppable) {
            move(draggable, droppable, true)
        }
    }

    return (
        <div class={classList(styles.planEmployees, viewModeClass())}>
            <DragDropProvider
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                collisionDetector={closestContainerOrItem}
            >
                <DragDropSensors/>
                <BenchContainer containers={containers}/>
                <For each={planContainerIds()}>
                    {(containerId) => <PlanEmployees id={containerId} employees={containers[containerId]}/>}
                </For>
                <DragOverlay>
                    {/* @ts-ignore - this works fine */}
                    {(draggable: Draggable) => <EmployeeCardOverlay employee={draggable.data}/>}
                </DragOverlay>
            </DragDropProvider>
        </div>
    )
}
