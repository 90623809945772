import {Component, createEffect, createSignal, Show} from 'solid-js'
import {Spinner} from '../../../../global/icons/Spinner/Spinner'
import {usePaymentsService} from '../../../../providers/AppServiceProvider'
import {PeachyPaymentCard} from '@peachy/payments-pure'

export const InfoPanelCreditCardDetails: Component = () => {
    const paymentService = usePaymentsService()
    const [paymentCard, setPaymentCard] = createSignal<PeachyPaymentCard>()

    const expiryDate = () => paymentCard()
        ? `${paymentCard().expireMonth?.toString().padStart(2, '0')}/${paymentCard().expireYear % 100}`
        : 'N/A'

    createEffect(async () => {
        const peachyPaymentCards = await paymentService.getCustomerCompanyPaymentCards()
        if (peachyPaymentCards.length) {
            setPaymentCard(peachyPaymentCards[0])
        }
    }, [])

    return (
        <>
            <p>Card number <Show when={paymentCard()} fallback={<Spinner/>}>
                <span>**** **** **** {paymentCard()?.last4Digits}</span>
            </Show>
            </p>

            <p>Expiry <Show when={paymentCard()} fallback={<Spinner/>}>
                <span>{expiryDate()}</span>
            </Show>
            </p>
        </>
    )
}
