import {Address, Company} from '@peachy/core-domain-pure'
import {Component} from 'solid-js'
import {SubHeader} from '../pure/SubHeader'
import {InfoPanelCreditCardDetails} from '../pure/InfoPanelDirectDebitDetails'
import {InfoPanelBillingAddressDetails} from '../pure/InfoPanelBillingAddressDetails'
import {InfoPanel} from '../pure/InfoPanel'

export const BillingCompanyDetailsPanel: Component<{ company: Company, billingAddress?: Address  }> = (props) => {
    return (
        <InfoPanel>
            <SubHeader heading={props.company?.name} onEditClick={() => Intercom('showNewMessage', 'I want to change my company information')} />
            <InfoPanelBillingAddressDetails company={props.company} billingAddress={props.billingAddress} />
            <InfoPanelCreditCardDetails />
        </InfoPanel>
    )
}
