import styles from './PriceBreakdown.module.css'


export function PriceBreakdownTable() {
    return (
        <table class={styles.priceBreakdown}>
            <thead>
            <tr>
                <th>Breakdown</th>
                <th>Per month</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td>Premium</td>
                <td>£35.71</td>
                <td></td>
            </tr>
            <tr>
                <td>Insurance premium tax</td>
                <td>£4.29</td>
                <td>12% of premium</td>
            </tr>
            <tr>
                <th>Total monthly price</th>
                <th>£40.00</th>
                <th></th>
            </tr>
            <tr>
                <th>How Peachy makes money</th>
                <th>Per month</th>
                <td></td>
            </tr>
            <tr>
                <td>Peachy app & connected services (inc. Virtual GP and Advice & Counselling)</td>
                <td>£4.50</td>
                <td>Per member per month</td>
            </tr>
            <tr>
                <th>Commission</th>
                <th></th>
                <td>20% of premium excluding app and services fee</td>
            </tr>
            </tbody>
        </table>
    )
}
