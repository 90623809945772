import {createComputed, createResource, createSignal, For, Show} from 'solid-js'
import {TextBox} from '../TextBox/TextBox'
import {ListSelectorAppearance, ListSelectorProps} from '../ListSelector/ListSelector'
import {createThrottledSignal} from '../../hooks/createThrottledSignal'

import {PopupListSelector} from '../PopupListSelector/PopupListSelector'
import {identity, isEmpty} from 'lodash-es'
import styles from './Autocomplete.module.css'
import {classList, Optional} from '@peachy/utility-kit-pure'


type AutocompleteLookupClient<T> = {
    lookup(searchTerm: string): Promise<{results: T[], searchTerm: string}>
}

const maxLookupRateMs = 500
const minSearchTermLength = 3

export type AutocompleteAppearance = ListSelectorAppearance & {
    lookupField?: string
}



type AutocompleteProps<T> = AutocompleteCommonProps<T> & {
    selected?: T
    onChange?(latestChoice: T): void
    resetStateOnClearInput?: boolean
    initialSearchTerm?: string
}
export function Autocomplete<T>(props: AutocompleteProps<T>) {
    return <AutocompleteSingleOrMultiple {...props} />
}

type AutocompleteMultipleProps<T> = AutocompleteCommonProps<T> & {
    selected?: T[]
    onChange?(latestChoice: T, allChoices?: T[]): void
}
export function AutocompleteMultiple<T>(props: AutocompleteMultipleProps<T>) {
    return <AutocompleteSingleOrMultiple {...props} multiple/>
}


type AutocompleteCommonProps<T> = {
    autoFocus?: boolean
    onTextInputChange?(value: string): void
    onBlur?: () => void
    onFocus?: () => void
    appearance?: AutocompleteAppearance
    lookupClient: AutocompleteLookupClient<T>
    placeholder?: string
    disabled?: boolean
    optionTextFn?: ListSelectorProps<T>['optionTextFn']
    optionComparisonValueFn?: ListSelectorProps<T>['optionComparisonValueFn']
}
type AutocompleteSingleAndMultipleProps<T> = AutocompleteCommonProps<T> & {
    resetStateOnClearInput?: boolean
    initialSearchTerm?: string
    multiple?: boolean
    selected?: T | T[]
    onChange?(latestChoice: Optional<T>, allChoices?: T[]): void
}

function AutocompleteSingleOrMultiple<T>(props: AutocompleteSingleAndMultipleProps<T>) {

    const choicesLookupClient = props.lookupClient
    const initialSearchTerm = props.initialSearchTerm ?? ''

    const textFn = props.optionTextFn ?? ((it: T) => it ? String(it) : '')
    const optionComparisonValueFn = props.optionComparisonValueFn ?? identity

    const [textInputValue, setTextInputValue] = createSignal(initialSearchTerm ?? '')
    const [debouncedSearchTerm, pushSearchTerm] = createThrottledSignal(initialSearchTerm, maxLookupRateMs)
    createComputed(() => pushSearchTerm(textInputValue()))

    const [spin, setSpin] = createSignal(false)

    const [choicesMade, setChoicesMade] = createSignal<T[]>([props.selected].flatMap(identity).filter(it => !!it))
    const latestChoiceMade = () => choicesMade()[0]

    const [availableChoices] = createResource(debouncedSearchTerm, async (searchTerm): Promise<T[]> => {
        const searchResults: T[] = []
        const enoughCharsToSearchOn = searchTerm.length >= minSearchTermLength
        const searchTermIsDifferentToExistingChoice = searchTerm !== textFn(latestChoiceMade())

        const searchTermIsDifferentToInitialSearchTerm = searchTerm !== initialSearchTerm
        if (enoughCharsToSearchOn && searchTermIsDifferentToExistingChoice && searchTermIsDifferentToInitialSearchTerm) {
            setSpin(true)
            const resp = await choicesLookupClient.lookup(searchTerm)
            if (resp.searchTerm === searchTerm) {
                searchResults.push(...resp.results)
            } else {
                searchResults.push(...availableChoices())
            }
            setSpin(false)
        }
        return searchResults
    })

    const choicesExist = () => !availableChoices.loading && !isEmpty(availableChoices())

    const onChoiceMade = (chosen?: T) => {
        setTextInputValue(props.multiple ? '' : textFn(chosen))
        setChoicesMade(previous => props.multiple ? [...previous, chosen] : [chosen])
        props.onChange?.(chosen, choicesMade())
    }

    const removeChoice = (toRemove: T) => {
        setChoicesMade(previous => previous.filter(it => optionComparisonValueFn(it) !== optionComparisonValueFn(toRemove)))
        props.onChange?.(undefined, choicesMade())
    }

    const onBlur = (e: Event) => {
        if (props.resetStateOnClearInput) {
            const element = e.currentTarget as HTMLInputElement
            if (!element.value) {
                onChoiceMade(undefined)
            }
        }
        props.onBlur?.()
    }


    const onFocus = (e: Event) => {
        const element = e.currentTarget as HTMLInputElement
        element.select()
        props.onFocus?.()
    }

    const onTextInputChange = (text: string) => {
        setTextInputValue(text)
        props.onTextInputChange?.(text)
    }

    let textBoxRef: HTMLElement

    const removeTagClass = classList(styles.clickable, 'fa-regular' ,'fa-circle-xmark', props.disabled ? styles.hidden : '')

    return <>
        <Show when={!(props.multiple && props.disabled)}>
            <TextBox
                ref={textBoxRef}
                placeholder={props.placeholder}
                value={textInputValue()}
                onChange={onTextInputChange}
                onFocus={onFocus}
                onBlur={onBlur}
                autoFocus={props.autoFocus}
                disabled={props.disabled}
                selectAllOnFocus={true}
                spin={spin()}>
            </TextBox>
        </Show>

        <Show when={props.multiple}>
            <For each={choicesMade()}>{choice =>
                <span class={styles.tag}>{textFn(choice)}&nbsp;<i onClick={() => removeChoice(choice)} class={removeTagClass}/>
                </span>
            }</For>
        </Show>

        <PopupListSelector
            list={availableChoices()}
            enabled={choicesExist()}
            selection={latestChoiceMade()}
            onSelect={onChoiceMade}
            appearance={getAppearence(props.appearance)}
            locatorElement={textBoxRef}
            center={false}
            optionTextFn={textFn}
            optionComparisonValueFn={optionComparisonValueFn}
        />
    </>
}

function getAppearence(overrides?: AutocompleteAppearance): AutocompleteAppearance {
    return {...{
        lookupField: styles.validField,
        list: styles.choiceList,
        listItem: styles.choiceListItem,
        selectedItem: styles.selectedChoiceListItem
    }, ...(overrides ?? {})}
}
