import {Address, Api, defineApi, postOf} from '@peachy/core-domain-pure'
import {FetchAddressRequest, LookupAddressRequest, LookupAddressResponse} from './address-types'


export const GeoLocationApiDefinition = defineApi('GeoLocationApi')
    .withActions({
        lookupAddress: postOf(LookupAddressRequest).fromAnyone().willReturn(LookupAddressResponse),
        fetchAddress: postOf(FetchAddressRequest).fromAnyone().willReturn(Address),
    })

export type GeoLocationApi = Api<typeof GeoLocationApiDefinition>
