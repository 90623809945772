import {createPaymentsService, PaymentsService} from '@peachy/payments-client'
import {createIamApi} from './iam/IamApi'
import {createIamService, IamService} from './iam/IamService'
import {API} from '@aws-amplify/api'
import {createSubscriptionService, SubscriptionService} from './subscription/SubscriptionService'
import {makeApiClient, makeApiGatewayClient} from '@peachy/core-domain-client'
import {SubscriptionApiGatewayDefinition} from '@peachy/subscription-pure'
import {useServicePatch} from '@peachy/client-kit'
import {Auth} from '@aws-amplify/auth'
import {SmeUserService} from '@peachy/iam-sme-client'
import {createDocGenService, DocGenService} from './DocGenService'
import {DocGenApiDefinition} from '@peachy/doc-gen-pure'
import {Signer} from '@aws-amplify/core'
import {PaymentApiGatewayDefinition} from '@peachy/payments-pure'

export type AppServices = {
    iamService: IamService
    paymentsService: PaymentsService
    subscriptionService: SubscriptionService
    smeUserService : SmeUserService
    docGenService: DocGenService
}

export async function createAppServices(): Promise<AppServices> {

    const servicePatch = useServicePatch()
    const smeUserService = new SmeUserService(API)
    const paymentsService = await createPaymentsService(await makeApiGatewayClient(PaymentApiGatewayDefinition, API, Auth))

    return {
        iamService: createIamService(createIamApi(), smeUserService),
        paymentsService: paymentsService,
        subscriptionService: createSubscriptionService(await makeApiGatewayClient(SubscriptionApiGatewayDefinition, API, Auth)),
        smeUserService: smeUserService,
        docGenService: createDocGenService(await makeApiClient(DocGenApiDefinition, servicePatch, Signer))
    }
}
