import {clampNumber, classList, trueMod} from '@peachy/utility-kit-pure'
import {createComputed, createSignal, For} from 'solid-js'
import styles from './ListSelector.module.css'
import {useOnKeyDown} from '../../hooks/useOnKey'
import {identity} from 'lodash-es'

export type ListSelectorProps<T> = {
    list: T[] | readonly T[]
    selection?: T
    selectionIsLocus?: boolean
    onSelect?(selection: T, index?: number): void
    onDismiss?(): void
    enabled?: boolean
    appearance?: ListSelectorAppearance
    optionTextFn?: (option?: T) => string
    optionComparisonValueFn?: (option?: T) => any
}


export type ListSelectorAppearance = {
    list: string
    listItem: string
    selectedItem: string
}


export function ListSelector<T>(props: ListSelectorProps<T>) {

    let initialLocus: number
    const [locus, setLocus] = createSignal(0)

    const textFn = props.optionTextFn ?? ((it: T) => it ? String(it) : '')
    const comparisonValueFn = props.optionComparisonValueFn ?? identity

    createComputed(() => {
        if (props.selectionIsLocus) {
            initialLocus = props.selection as number
        } else {
            const list = props.list
            const initalSelection = props.selection
            const indexOfInitialSelection = list.findIndex(it =>
                comparisonValueFn(it) === comparisonValueFn(initalSelection)
            )
            initialLocus = clampNumber(indexOfInitialSelection, 0, list.length)
        }
         setLocus(initialLocus)
    })

    const listClasses = () => classList(styles.ListSelector, props.appearance?.list)

    let listRef: HTMLUListElement


    useOnKeyDown((evt: KeyboardEvent) => {
        if (!props.enabled) return

        let targetLocus: number = null
        if (evt.key === 'ArrowUp') {
            evt.preventDefault()
            setLocus(currentLocus => {
                return targetLocus = trueMod(currentLocus - 1, props.list.length)
            })
        } else if (evt.key === 'ArrowDown') {
            evt.preventDefault()
            setLocus(currentLocus => {
                return targetLocus = trueMod(currentLocus + 1, props.list.length)
            })
        } else if (evt.key === 'Enter' || evt.key === 'Tab') {
            setLocus(currentLocus => {
                props.onSelect?.(props.list[currentLocus], currentLocus)
                return targetLocus = currentLocus
            })
        } else if (evt.key === 'Escape') {
            setLocus(initialLocus)
            targetLocus = initialLocus
            props.onDismiss?.()
            evt.stopImmediatePropagation()
        }
        if (targetLocus !== null) {
            ensureIsInView(targetLocus, listRef)
        }
    })


    const onClickOption = (event: Event, targetLocus: number) => {
        setLocus(targetLocus)
        props.onSelect?.(props.list[targetLocus], targetLocus)
        event.stopPropagation()
    }

    const itemClasses = (index: number, locus: number) => {
        return classList(props.appearance?.listItem, index === locus && props.appearance?.selectedItem)
    }

    return (
        <ul ref={listRef} tabIndex={props.enabled ? 0 : undefined} class={listClasses()}>
            <For each={props.list}>{(item, index) => {
                return (
                    <li class={itemClasses(index(), locus())} data-is-selected={index() == locus()} onMouseDown={(e) => onClickOption(e, index())}>
                        <span>{textFn(item)}</span>
                    </li>
                )
            }}</For>
        </ul>
    )
}

function ensureIsInView(targetLocus: number, listElement: HTMLUListElement) {
    const targetItem = getListItem(targetLocus, listElement)
    if (!isInView(targetItem, listElement)) {
        requestAnimationFrame(() => {
            targetItem.scrollIntoView({behavior: 'smooth', block: 'nearest'})
        })
    }
}

function getListItem(index: number, list: HTMLUListElement): HTMLLIElement {
    return list.children.item(index) as HTMLLIElement
}

function isInView(item: HTMLLIElement, list: HTMLUListElement) {
    const itemBounds = item.getBoundingClientRect()
    const listBounds = list.getBoundingClientRect()
    return listBounds.top <= itemBounds.top && listBounds.bottom >= itemBounds.bottom
}
