import styles from './Toggle.module.css'
import {Signal} from 'solid-js'
import {classList} from '@peachy/utility-kit-pure'

export type ToggleProps = {
    signal: Signal<boolean>
    label?: string
    class?: string
}


export function Toggle(props: ToggleProps) {

    const [value, setValue] = props.signal

    const classes = classList(styles.Toggle, props.class)
    return (
        <label class={classes}>
            <span>{props.label}</span>
            <input type={'checkbox'} checked={value()} onChange={() => setValue(v => !v)}/>
        </label>
    )
}

export type Toggle2Props = {
    isOn: boolean
    onToggle: (isOn: boolean) => void
    label?: string
    class?: string
    disabled?: boolean
}

export function Toggle2(props: Toggle2Props) {

    const classes = classList(styles.Toggle, props.class)

    const onChange = (evt: Event) => {
        const element = evt.currentTarget as HTMLInputElement
        props.onToggle(element.checked)
    }

    return (
        <label class={classes}>
            <span>{props.label}</span>
            <input type={'checkbox'} disabled={props.disabled} checked={props.isOn} onChange={onChange}/>
        </label>
    )
}