import {Component} from 'solid-js'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import tableStyles from '../ManageMembersTable.module.css'
import {LifeRowCellProps} from '../PolicyRow'

export const RemoveDependant: Component<LifeRowCellProps> = (props) => {

    return <td class={tableStyles.delete}>
        <button onClick={() => useStore().removeLife(props.policyIndex, props.life.id)}>
            <i class="fas fa-trash" />
        </button>
    </td>
}
