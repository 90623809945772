import {DatePickerStore} from '../DatePickerStore'
import {DatePickerAppearance} from '../DatePicker'
import {classList} from '@peachy/utility-kit-pure'
import styles from '../DatePickerHeader/DatePickerHeader.module.css'
import {PopupListSelector} from '../../PopupListSelector/PopupListSelector'
import {createSignal, Show} from 'solid-js'


export type NudgeSelectorProps = {
    datePickerStore: DatePickerStore
    appearance?: DatePickerAppearance
    options: string[]
    selection: string
    onSelect: (selection: string) => void
    onNudgeLeft: () => void
    onNudgeRight: () => void
}


export function NudgeSelector(props: NudgeSelectorProps) {
    const [showPopup, setShowPopup] = createSignal(false)

    let locatorElement

    const nudgeSelectorClasses = classList(styles.nudgeSelector, props.appearance?.nudgeSelector)
    const nudgeLeftClasses = classList(styles.nudgeLeft, props.appearance?.nudgeLeft)
    const nudgeRightClasses = classList(styles.nudgeRight, props.appearance?.nudgeRight)

    const onDismiss = () => {
        setShowPopup(false)
    }

    const onSelect = (selection: string) => {
        setShowPopup(false)
        props.onSelect(selection)
    }

    const canNudge = (direction: 'LEFT' | 'RIGHT') => {
        const index = props.options.indexOf(props.selection)
        return direction === 'LEFT' ? index > 0 : index < props.options.length - 1
    }

    return (
        <nav class={nudgeSelectorClasses}>
            <Show when={canNudge('LEFT')} fallback={<span class={props.appearance?.nudgeLeft} />}>
                <button class={nudgeLeftClasses} onClick={props.onNudgeLeft}><i /></button>
            </Show>
            <span class={props.appearance?.nudgeSelectorText} ref={locatorElement} onclick={() => setShowPopup(true)}>{props.selection}</span>
            <Show when={canNudge('RIGHT')} fallback={<span class={props.appearance?.nudgeRight} />}>
                <button class={nudgeRightClasses} onClick={props.onNudgeRight}><i /></button>
            </Show>
            <PopupListSelector
                list={props.options}
                selection={props.selection}
                onDismiss={onDismiss}
                onSelect={onSelect}
                enabled={showPopup()}
                locatorElement={locatorElement}
                appearance={{
                    modal: props.appearance?.nudgeSelectorModal,
                    list: props.appearance?.nudgeSelectorList,
                    listItem: props.appearance?.nudgeSelectorListItem,
                    selectedItem: props.appearance?.nudgeSelectorListItemSelected,
                }}
            />
        </nav>
    )
}
