import styles from './SubHeader.module.css'
import {Component, Show} from 'solid-js'
import {Button} from '../../../../global/forms/Button'

export const SubHeader: Component<{ heading: string, onEditClick?: () => void }> = (props) => {
    return <div class={styles.SubHeader}>
        <h5>{props.heading}</h5>
        <Show when={props.onEditClick}>
            <div class={styles.Edit}>
                <Button theme="secondary" onClick={() => props.onEditClick()}><i class="icon-edit"/></Button>
            </div>
        </Show>
    </div>
}
