import {classList} from '@peachy/utility-kit-pure'
import type {JSX} from 'solid-js'
import styles from './Card.module.css'

export type CardProps = {
    class?: string
    highlight?: boolean
    children: any
    onClick?: JSX.EventHandler<HTMLDivElement, MouseEvent>
    onMouseOver?: JSX.EventHandler<HTMLDivElement, MouseEvent>
}

export function Card(props: CardProps) {
    const highlight = props.highlight ? styles.highlight : undefined
    const clickable = props.onClick ? 'clickable' : undefined
    const classes = classList(styles.Card, highlight, clickable, props.class)
    return <div class={classes} onClick={props.onClick} onMouseOver={props.onMouseOver}>{props.children}</div>
}
