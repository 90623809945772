import {createContext, createResource, ParentProps, Show, useContext} from 'solid-js'
import {createGeoLocationServiceClient, GeoLocationServiceClient} from '@peachy/geo-location-client'
import {Address} from '@peachy/core-domain-pure'
import {PEACHY_HQ} from '../../../index'
import {Signer} from '@aws-amplify/core'

const GeoLocationServiceClientContext = createContext<GeoLocationServiceClient>()

type GeoLocationServiceControllerProps = ParentProps & {
    servicePatchUrl: string
}

export function GeoLocationServiceController(props: GeoLocationServiceControllerProps) {
    const [serviceClient] = createResource<GeoLocationServiceClient>( () => createGeoLocationServiceClient(props.servicePatchUrl, Signer))
    const hasServiceClient = () => !!serviceClient()
    return (
        <Show when={hasServiceClient()}>
            <GeoLocationServiceClientContext.Provider value={serviceClient()}>
                {props.children}
            </GeoLocationServiceClientContext.Provider>
        </Show>
    )
}


export function useGeoLocationServiceClient(): GeoLocationServiceClient {
    return useContext(GeoLocationServiceClientContext)
}

export function firstMatchingAddressFetcher(): (searchTerm: string) => Promise<Address | undefined> {
    const geoLocationServiceClient = useGeoLocationServiceClient()
    let requestId = 1

    return async searchTerm => {
        if (!searchTerm?.trim()) {
            return
        }
        const results = await geoLocationServiceClient.lookupAddress({
            searchTerm,
            requestId: `${requestId++}`,
            location: PEACHY_HQ
        })
        if (results.matches.length > 0) {
            return geoLocationServiceClient.fetchAddress({ addressId: results.matches[0].id })
        } else {
            console.warn('No matching address found for: ', searchTerm)
        }
    }
}
