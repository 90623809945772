

export type KeyEventType = 'keyup' | 'keydown'

export type Key =
    | 'Escape'
    | 'Enter'
    | 'Tab'
    | 'ArrowUp'
    | 'ArrowDown'
    | 'ArrowLeft'
    | 'ArrowRight'
    | 'End'
    | 'Home'
    | 'PageUp'
    | 'PageDown' // etc
    | string

export type KeyboardHandler = (event: KeyboardEvent) => void


export type KeyboardHandlerEntry = {
    type?: KeyEventType
    key?: Key
    handler: KeyboardHandler
}


export function invokeHandlers(evt: KeyboardEvent, handlerStack: KeyboardHandlerEntry[]) {

    const withIntercept = addPropagationIntercept(evt)

    for (let i = handlerStack.length; i > 0; i--) {
        const entry = handlerStack[i-1]
        const shouldRun = (!entry.type || entry.type === withIntercept.type) && (!entry.key || entry.key === withIntercept.key)

        if (shouldRun) {
            entry.handler(withIntercept)
            if (withIntercept._propagationStopped) {
                break
            }
        }
    }
    removePropagationIntercept(evt)
}





type KeyboardEventWithPropagationIntercept = KeyboardEvent & {
    _originalStopPropagation?: () => void,
    _originalStopImmediatePropagation?: () => void,
    _propagationStopped?: boolean
}


function addPropagationIntercept(evt:KeyboardEvent): KeyboardEventWithPropagationIntercept {

    const withIntercept: KeyboardEventWithPropagationIntercept = evt

    withIntercept._originalStopPropagation = evt.stopPropagation
    withIntercept._originalStopImmediatePropagation = evt.stopImmediatePropagation

    withIntercept.stopPropagation = () => {
        withIntercept._propagationStopped = true
        withIntercept._originalStopPropagation()
    }

    withIntercept.stopImmediatePropagation = () => {
        withIntercept._propagationStopped = true
        withIntercept._originalStopImmediatePropagation()
    }

    return withIntercept
}


function removePropagationIntercept(withIntercept: KeyboardEventWithPropagationIntercept): KeyboardEvent {
    withIntercept.stopPropagation = withIntercept._originalStopPropagation
    withIntercept.stopImmediatePropagation = withIntercept._originalStopImmediatePropagation
    delete withIntercept._originalStopPropagation
    delete withIntercept._originalStopImmediatePropagation
    delete withIntercept._propagationStopped
    return withIntercept // without!
}

