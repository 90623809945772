import {Component} from 'solid-js'
import {PlanBuilder} from '../../../components/PlanBuilder/PlanBuilder'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {getYourPlanContext, isEditingPlan} from './YourPlanProvider'

export const DashboardPlanBuilder: Component = () => {
    const store = useStore()
    const [_, {editPlan}] = getYourPlanContext()

    store.setPlanEditable(isEditingPlan() as boolean)
    store.setViewMode('PORTAL')

    // FIXME: bit hacky - but need to provide what happens on hitting 'Edit' and 'Cancel' which only appear for the portal Your Plan view
    store.onPortalEdit = () => {
        editPlan(true)
    }

    store.onPortalCancel = () => {
        store.reset()
        editPlan(false)
    }

    return (<PlanBuilder/>)
}
