import {ParentComponent} from 'solid-js'
import styles from './InfoPanel.module.css'
import {classList} from '@peachy/utility-kit-pure'

export const InfoPanel: ParentComponent<{ class?: string }> = (props) => {
    return (
        <div class={classList(styles.infoPanel, props.class)}>
            {props.children}
        </div>
    )
}
