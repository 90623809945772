import {
    ConfirmedPaymentIntent,
    Coupon,
    PaymentApiGatewayDefinition,
    PaymentCustomer,
    PaymentIntent,
    PeachyPaymentCard
} from '@peachy/payments-pure'
import {ConfirmCardSetupData, loadStripe, Stripe, StripeElements} from '@stripe/stripe-js'
import {CardDetails, Quote} from '@peachy/legacy-domain'
import {last} from '@peachy/utility-kit-pure'

import {Address} from '@peachy/core-domain-pure'

import {ApiGatewayClient} from '@peachy/core-domain-client'

export type PaymentsApiGateway = ApiGatewayClient<typeof PaymentApiGatewayDefinition>


export function createPaymentsService(api: PaymentsApiGateway): Promise<PaymentsService> {
    return PaymentsService.create(api)
}


export class PaymentsService {

    private paymentIntent: PaymentIntent

    private confirmedPaymentIntent: ConfirmedPaymentIntent

    private stripe: Stripe

    private apiKey: string

    private loaded = false

    private constructor(private paymentsApiGateway: PaymentsApiGateway) {

    }

    static async create(api: PaymentsApiGateway): Promise<PaymentsService> {
        const paymentsService = new PaymentsService(api)
        paymentsService.stripe = await loadStripe(await paymentsService.requestApiKey())
        paymentsService.loaded = true
        return paymentsService
    }

    getClientSecret() {
        return this.paymentIntent?.clientSecret
    }

    getIntentId() {
        return this.paymentIntent?.intentId
    }

    getIntent() {
        return this.paymentIntent
    }

    getStripe() {
        return this.stripe
    }

    hasLoaded() {
        return this.loaded
    }

    private async requestApiKey(): Promise<string> {
        return this.apiKey ?? (
            (await this.paymentsApiGateway.requestApiKey())?.key
        )
    }

    async getCoupon(code: string): Promise<Coupon> {
        try {
            return await this.paymentsApiGateway.getCoupon({id: code})
        } catch (e) {
            return null
        }
    }

    async getCustomerCompanyPaymentCards(): Promise<PeachyPaymentCard[]> {
        try {
            return await this.paymentsApiGateway.companyCards()

        } catch (e) {
            return null
        }
    }

    async abandonPaymentIntent(): Promise<PaymentIntent> {
        if (this.paymentIntent) {
            return this.paymentsApiGateway.abandonIntent({
                intentId: this.paymentIntent.intentId
            })
        }
        return null
    }

    async createPaymentIntent(abandonIntent: boolean = true): Promise<PaymentIntent> {
        if (abandonIntent) await this.abandonPaymentIntent()
        this.paymentIntent = await this.paymentsApiGateway.createIntent()
        return this.paymentIntent
    }

    async confirmCardPaymentIntent(stripeElements: StripeElements, quote: Quote, paymentDetails: CardDetails) {
        const primaryLife = quote.request.getPrimaryLife()
        const address = paymentDetails.hasSeparateBillingAddress
            ? paymentDetails.billingAddress
            : primaryLife.address

        const customer: PaymentCustomer = {email: primaryLife.email, fullName: primaryLife.fullName()}

        // TODO handle stripe being null?
        const {error, setupIntent} = await this.stripe.confirmCardSetup(
            this.getClientSecret(),
            {
                payment_method: {
                    card: stripeElements.getElement('cardNumber'),
                    billing_details: {
                        name: customer.fullName,
                        email: customer.email,
                        address: {
                            line1: address.building[0],
                            line2: address.building[1],
                            city: last(address.settlement),
                            state: address.county,
                            country: 'GB',
                            postal_code: address.postcode
                        }
                    }
                }
            }
        )

        if (error) {
            console.log('Throwing stripe confirm intent error', error)
            throw error
        } else {
            console.log('Successfully set up intent', setupIntent)
        }

        const paymentMethod = setupIntent.payment_method

        const paymentMethodId = typeof paymentMethod === 'string'
            ? paymentMethod
            : paymentMethod.id

        this.confirmedPaymentIntent = {
            customer,
            paymentMethodId
        }

        return this.confirmedPaymentIntent
    }

    async confirmCardPaymentIntentV2(stripeElements: StripeElements, address: Address, paymentCustomer: PaymentCustomer) {
        console.log(`confirming card setup for customer ${paymentCustomer.fullName}`)
        console.log(`using address: ${address.building[0]} ${address.county} ${address.postcode}`)

        const data: ConfirmCardSetupData = {
            payment_method: {
                card: stripeElements.getElement('cardNumber'),
                billing_details: {
                    name: paymentCustomer.fullName,
                    email: paymentCustomer.email,
                    address: {
                        line1: address.building[0],
                        line2: address.building[1],
                        city: last(address.settlement),
                        state: address.county,
                        country: 'GB',
                        postal_code: address.postcode
                    }
                }
            }
        }

        console.debug('confirm card setup data: ', data)

        // TODO handle stripe being null?
        const {error, setupIntent} = await this.stripe.confirmCardSetup(this.getClientSecret(), data)

        if (error) {
            console.log('Throwing stripe confirm intent error', error)
            throw error
        } else {
            console.log('Successfully set up intent', setupIntent)
        }

        const paymentMethod = setupIntent.payment_method
        const paymentMethodId = typeof paymentMethod === 'string' ? paymentMethod : paymentMethod.id

        this.confirmedPaymentIntent = {
            customer: paymentCustomer,
            paymentMethodId
        }

        return this.confirmedPaymentIntent
    }

}
