import {Policy} from '../Policy'
import {Plan} from '../Plan'
import {KeyMapped} from '@peachy/utility-kit-pure'
import {EmailData} from './EmailData'

export type PolicyDocEmailData = EmailData & {
    policy: Policy
    plans: KeyMapped<Plan>
    firstName: string
}
