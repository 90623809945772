import {Api, defineApi, DocGenTicket, postOf} from '@peachy/core-domain-pure'

export class TicketId {
    ticketId: string
}

export class PdfBytes extends String {
}


export const DocGenApiDefinition = defineApi('DocGenApi')
    .withActions({
        preparePdfTicket: postOf(DocGenTicket).fromAnyone().willReturn(TicketId),
        renderPdfTicket: postOf(TicketId).fromAnyone().willReturn(PdfBytes),
    })


export type DocGenApi = Api<typeof DocGenApiDefinition>

