import {createEffect, createSignal, onCleanup, ParentProps} from 'solid-js'
import {Dynamic} from 'solid-js/web'
import {classList} from '@peachy/utility-kit-pure'


export type Transitions<T extends string> = {
    [k in T]: string
}

export type TransitionProps<T extends string> = ParentProps & {
    transitions: Transitions<T>
    commonClass: string
    containerElement?: string
    targetState: T,
    onTransitionStart?: (fromState: T, toState: T) => void
    onTransitionEnd?: (fromState: T, toState: T) => void
}


export function Transition<T extends string>(props: TransitionProps<T>) {
    let containerRef: HTMLElement

    let transitionEventStack = 0

    const [currentState, setCurrentState] = createSignal(props.targetState)

    const start = (evt: TransitionEvent) => {
        if (transitionEventStack === 0) {
            props.onTransitionStart?.(currentState(), props.targetState)
        }
        transitionEventStack++
    }

    const end = (evt: TransitionEvent) => {
        transitionEventStack--
        if (transitionEventStack === 0) {
            props.onTransitionEnd?.(currentState(), props.targetState)
            setCurrentState(() => props.targetState)
        }
    }

    const cancel = (evt: TransitionEvent) => {
        transitionEventStack--
    }

    createEffect(() => {
        containerRef.addEventListener('transitionrun', start)
        containerRef.addEventListener('transitionend', end)
        containerRef.addEventListener('transitioncancel', cancel)

        onCleanup(() => {
            containerRef.removeEventListener('transitionrun', start)
            containerRef.removeEventListener('transitionend', end)
            containerRef.removeEventListener('transitioncancel', cancel)
        })
    })

    return (
        <Dynamic ref={containerRef} component={props.containerElement ?? 'div'} class={classList(props.commonClass, props.transitions[props.targetState])}>
            {props.children}
        </Dynamic>
    )
}

