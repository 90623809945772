import {
    AccountSubscriptionDto,
    CancelLifeRequest,
    CancelPolicyRequest,
    CancelSubscriptionRequest,
    defineApiGateway,
    MrSubscriptionQuoteRequest,
    MrSubscriptionQuoteResponse
} from '@peachy/core-domain-pure'

import {ApiGatewayClient} from '@peachy/core-domain-client'

import {ContentBucketResponse, ReissuePolicyDocRequest, ReissuePolicyDocResponse} from '../index'


export const SubscriptionApiGatewayDefinition = defineApiGateway('SubscriptionApiGateway').withRoutes({
    getSmeQuote: {
        method: 'POST',
        path: '/quote/sme',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },
    saveSmeDraftQuote: {
        method: 'POST',
        path: '/quote/sme/draft/{quoteId}',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },
    loadSmeDraftQuote: {
        method: 'GET',
        path: '/quote/sme/draft/{quoteId}',
        isPublic: false,
        responseType: MrSubscriptionQuoteResponse
    },
    latestSmeDraftQuote: {
        method: 'GET',
        path: '/quote/sme/draft/latest',
        isPublic: false,
        responseType: MrSubscriptionQuoteResponse
    },
    loadSmeDraftQuotes: {
        method: 'GET',
        path: '/quote/sme/draft/all',
        isPublic: false,
        responseType: MrSubscriptionQuoteResponse
    },

    loadActiveSmeSubscription: {
        method: 'GET',
        path: '/subscription/sme/load',
        isPublic: false,
        responseType: AccountSubscriptionDto
    },

    adminLoadActiveSubscription: {
        method: 'GET',
        path: '/subscription/load/{accountId}/{subscriptionId}',
        isPublic: false,
        responseType: AccountSubscriptionDto
    },
    activateSmeSubscription: {
        method: 'POST',
        path: '/quote/sme/activate',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },

    cancelSubscription: {
        method: 'POST',
        path: '/subscription/cancel',
        isPublic: false,
        requestType: CancelSubscriptionRequest,
    },

    cancelPolicy: {
        method: 'POST',
        path: '/policy/cancel',
        isPublic: false,
        requestType: CancelPolicyRequest,
    },

    cancelLife: {
        method: 'POST',
        path: '/life/cancel',
        isPublic: false,
        requestType: CancelLifeRequest,
    },

    alterSmeSubscription: {
        method: 'POST',
        path: '/subscription/sme/alter',
        isPublic: false,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse

    },
    getIndividualQuote: {
        method: 'POST',
        path: '/quote/individual',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },
    activateIndividualSubscription: {
        method: 'POST',
        path: '/quote/individual/activate',
        isPublic: true,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse

    },
    alterIndividualSubscription: {
        method: 'POST',
        path: '/subscription/individual/alter',
        isPublic: false,
        requestType: MrSubscriptionQuoteRequest,
        responseType: MrSubscriptionQuoteResponse
    },

    reissueCompanyPolicyDoc: {
        method: 'POST',
        path: '/quote/sme/policy-doc',
        isPublic: true,
        requestType: ReissuePolicyDocRequest,
        responseType: ReissuePolicyDocResponse
    },

    resolveContentBucket: {
        method: 'POST',
        path: '/resolve-content-bucket',
        isPublic: false,
        responseType: ContentBucketResponse
    }

})

export type SubscriptionApiGateway = ApiGatewayClient<typeof SubscriptionApiGatewayDefinition>
