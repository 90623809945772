import {Accessor, createSignal} from 'solid-js'
import {last} from '@peachy/utility-kit-pure'


export default function createBufferedSignal<T>(initialValue: T, size: number = 3): BufferedSignal<T> {

    const [buffer, setBuffer] = createSignal([initialValue])
    const advanceBuffer = () => {
        setBuffer((currentBuffer) => {
            if (currentBuffer.length > 1) {
                return currentBuffer.slice(1)
            }
            return currentBuffer
        })
    }

    const pushValue = (value: T) => {
        let pushed = false
        setBuffer(currentBuffer => {
            if (last(currentBuffer) === value) {
                return currentBuffer
            }
            const newBuffer = [...currentBuffer]
            if (newBuffer.length == size) {
                newBuffer.pop()
            }
            if (last(newBuffer) !== value) {
                newBuffer.push(value)
                pushed = true
            }
            return newBuffer
        })
        return pushed
    }
    const accessValue = () => buffer()[0]

    return [accessValue, pushValue, advanceBuffer, buffer]
}

export type BufferedSignal<T> = [Accessor<T>, (value: T) => boolean, () => void, Accessor<T[]>]


