import {Coupon, PaymentApiGatewayDefinition, Promo} from '@peachy/payments-pure'
import {ApiGatewayClient} from '@peachy/core-domain-client'


export class CouponService {
    constructor(private paymentApi: ApiGatewayClient<typeof PaymentApiGatewayDefinition>) {

    }

    async getCoupon(code: string): Promise<Coupon> {
        try {
            return await this.paymentApi.getCoupon({id: code})
        } catch (e) {
            return null
        }
    }

    async getPromo(code: string): Promise<Promo> {

        console.log('Getting promo?', code)

        try {
            return await this.paymentApi.getPromo({code})
        } catch (e) {
            return null
        }
    }
}
