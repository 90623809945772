import {ValidationErrorMap} from '@peachy/core-domain-pure'
import {Component, createEffect, createSignal, For} from 'solid-js'
import styles from './ValidationErrors.module.css'

export interface ValidationErrorProps<T> {
    errors: ValidationErrorMap<T>
    touchedFields: Set<string>
    fields: string[]
}

export function ValidationErrors<T> (props: ValidationErrorProps<T>) {
    const hasErrors = (fieldName: string) => Boolean((fieldName in props.errors) && props.touchedFields.has(fieldName))
    const getErrorMessage = (fieldName: string) => hasErrors(fieldName) ? props.errors[fieldName][0].message : ''

    const [errorMessages, setErrorMessages] = createSignal([])

    createEffect(() => {
        setErrorMessages(props.fields.map(field => hasErrors(field) ? getErrorMessage(field) : '').filter(error => Boolean(error)))
    })

    return <ErrorMessages errors={errorMessages()} />
}

export const ErrorMessages: Component<{ errors: string[] }> = (props) => {
    return (
        <div class={styles.errors}>
            <For each={props.errors} fallback={<span/>}>
                {(message) =><span class={styles.errorItem}>{message}</span>}
            </For>
        </div>
    )
}