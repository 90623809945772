import {APIClass} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import {CognitoUserAttributes} from '@peachy/iam-pure'
import {Optional, stringifyIfItCanBe} from '@peachy/utility-kit-pure'

export class IntercomBrowserClient {

    constructor(
        private api: APIClass,
        private auth: typeof Auth,
        private apiGatewayName: string = 'Intercom'
    ) {}

    async convertVisitorToLead(leadDetails: PartialLeadDetails) {
        log('convertVisitorToLead', stringifyIfItCanBe(leadDetails, 2))

        try {
            const visitorId = this.getVisitorId(true)
            if (visitorId) {
                log('convert visitor id: ', visitorId, 'to lead')
                const response = await this.api.put(this.apiGatewayName, '/visitorToLead/' + visitorId, {body: leadDetails})

                log('visitorToLead response', {response})

                return response
            } else {
                log('no visitor to convert to lead')
            }

        } catch (e) {
            log('ERROR received from intercom', e)
            console.error(e)
            // don't let this break the FE, it can be resolved later
        }
    }

    getVisitorId(rebootIfUndefined = false) {
        let id = window.Intercom('getVisitorId')
        if (!id && rebootIfUndefined) {
            this.reboot()
            id = window.Intercom('getVisitorId')
        }
        return id
    }

    trackEvent(eventName: string, eventMetadata?: object) {
        try {
            log('track event:', eventName, JSON.stringify(eventMetadata, null, 2))
            window.Intercom('trackEvent', eventName, eventMetadata)
        } catch (e) {
            log('ERROR received from intercom', e)
            console.error(e)
            // don't let tracking errors break the FE
        }
    }

    reboot(settings: Intercom_.IntercomSettings = window.intercomSettings) {
        this.shutdown()
        this.boot(settings)
    }

    shutdown() {
        log('shutdown session')
        window.Intercom('shutdown')
    }

    boot(settings: Intercom_.IntercomSettings = window.intercomSettings) {
        log('boot')
        window.Intercom('boot', settings)
    }

    async bootAsAuthenticatedUser() {
        try {
            log('Authenticating Intercom...')
            const authenticatedUser = (await this.auth.currentAuthenticatedUser())?.attributes as Optional<CognitoUserAttributes>
            log('...for sub:', authenticatedUser.sub)
            const user_hash = (await this.getAuthedUsersIntercomRecord('admin')).hash

            this.reboot({
                ...window.intercomSettings,
                ...{
                    email: authenticatedUser.email,
                    user_id: `sub:${authenticatedUser.sub}`,
                    user_hash
                }
            })
        } catch (e) {
            console.error(e)
            throw e
        }
    }

    private getAuthedUsersIntercomRecord(pool: 'user' | 'admin') {
        return this.api.get(this.apiGatewayName, `/${pool}/self?source=web`, {})
    }

}

function log(message?: any, ...optionalParams: any[]) {
    console.log('[Intercom]' + message, ...optionalParams)
}

type PartialLeadDetails = {
    email?: string
    name?: string
    phone?: string
    acceptMarketingEmails?: boolean
    userType?: 'SME_MEMBER' | 'INDIVIDUAL_MEMBER' | 'SME_ADMIN'
    company?: {id: string, name: string}
}
