import {MrLife} from './MrLife'
import {Pence} from '../../some-types'
import {toClass} from '../../validation/validation-core'
import {KeyMapped, values} from '@peachy/utility-kit-pure'
import {MrPlan} from './MrPlan'
import {HasLifecycleStatus, isCancelled, LifecycleStatus} from './LifecycleStatus'


export class MrPolicy implements HasLifecycleStatus{
    id: string

    status: LifecycleStatus
    startDate: number
    endDate?: number
    cancellationReason?:string
    nextRenewalDate?: number
    lastRenewalDate?: number


    totalMonthlyPremium?: Pence

    lives: KeyMapped<MrLife> = {}

    plans?: KeyMapped<MrPlan> = {}

    getAllLives?(): MrLife[] {
        return values(this.lives).map(l => toClass(l, MrLife))
    }

    getActiveLives?(): MrLife[] {
        return values(this.lives)
            .filter(l => !isCancelled(l))
            .map(l => toClass(l, MrLife))
    }

    getPrimaryLife?(): MrLife {
        const life = Object.values(this.lives).find(l => l.type === 'PRIMARY')
        return toClass(life, MrLife)
    }

    getSecondaryLife?(): MrLife {
        const life = Object.values(this.lives).find(l => l.type === 'SECONDARY')
        return toClass(life, MrLife)
    }

    getDependents?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.type === 'DEPENDANT')
        return lives.map(l => toClass(l, MrLife))
    }

    getAppCustomers?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.cognitoUserId)
        return lives.map(l => toClass(l, MrLife))
    }

    getNonPrimaryLives?(): MrLife[] {
        const lives = Object.values(this.lives).filter(l => l.type !== 'PRIMARY')
        return lives.map(l => toClass(l, MrLife))
    }

    getLifeByCognitoUserId?(cognitoUserId: string) {
        const life = Object.values(this.lives).find(it => it.cognitoUserId === cognitoUserId)
        return toClass(life, MrLife)
    }

    findLife? = (where: (life: MrLife) => boolean) =>
        values(this.lives).find(p => where(p))

    hasLife? = (lifeId: string) => this.lives[lifeId]
}
