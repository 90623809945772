import styles from './WhatsNotCovered.module.css'
import {PreExistingConditions} from './Exclusion/PreExistingConditions'
import {ChronicConditions} from './Exclusion/ChronicConditions'
import {Other} from './Exclusion/Other'

export const WhatsNotCovered = () => {
    return (
        <section class={styles.whatsNotCovered}>
            <h2>What's not covered</h2>
            <ul>
                <PreExistingConditions/>
                <ChronicConditions/>
                <Other/>
            </ul>
        </section>
    )
}