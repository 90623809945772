import {enumerate} from '@peachy/utility-kit-pure'
import {UkRegions} from '@peachy/core-domain-pure'

export const QuoteRegions = enumerate([
    'EAST',
    'LONDON',
    'MIDLANDS',
    'NORTHEAST',
    'NORTHWEST',
    'SCOTLAND',
    'SOUTHEAST',
    'SOUTHWEST',
    'WALES'
] as const)

export type QuoteRegion = keyof typeof QuoteRegions

export const quoteRegionMap: {[k: string]: QuoteRegion} = {
    // [UkRegions['Crown Dependencies']]: null,
    [UkRegions['Scotland']]: QuoteRegions.SCOTLAND,
    // [UkRegions['Northern Ireland']]: null,
    [UkRegions['Wales']]: QuoteRegions.WALES,
    [UkRegions['North East England']]: QuoteRegions.NORTHEAST,
    [UkRegions['North West England']]: QuoteRegions.NORTHWEST,
    [UkRegions['Yorkshire and the Humber']]: QuoteRegions.MIDLANDS,
    [UkRegions['West Midlands']]: QuoteRegions.MIDLANDS,
    [UkRegions['East Midlands']]: QuoteRegions.MIDLANDS,
    [UkRegions['East of England']]: QuoteRegions.EAST,
    [UkRegions['London']]: QuoteRegions.LONDON,
    [UkRegions['South East England']]: QuoteRegions.SOUTHEAST,
    [UkRegions['South West England']]: QuoteRegions.SOUTHWEST,
} as const
