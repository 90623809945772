import {Ipid} from '../../../components/Documents/Ipid/Ipid'
import {StartDate} from '../../../components/StartDate/StartDate'
import {AccountSubscriptionProvider, useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {editSubscriptionSessionStore} from '../../../providers/AccountSubscription/session/stores'
import { DownloadCompanySamplePlan } from '../../quote/CapturePlanDetails/DownloadCompanySamplePlan'
import {BrokerPlanBuilder} from './BrokerPlanBuilder/BrokerPlanBuilder'
import styles from './BrokerQuote.module.css'
import {CompanyDetails} from './CompanyDetails/CompanyDetails'
import {DownloadPDF} from './DownloadPDF/DownloadPDF'
import {ProductDetails} from './ProductDetails/ProductDetails'
import {QuoteVersion} from './ProductDetails/QuoteVersion'
import {QuoteFooter} from './QuoteFooter/QuoteFooter'


const Content = () => {

    const disablePlanBuilder = () => !(useStore().getCompany()?.address)

    return (
        <>
            <div class={styles.container}>
                <section class={styles.rowStructure}>
                    <ProductDetails />
                    {/* TODO - Hide after PR */}
                    <QuoteVersion />
                </section>

                <section>
                    <CompanyDetails />
                </section>

                <section>
                    <h4>Plan configurator</h4>
                    <div class={disablePlanBuilder() ? styles.disabled : ''}>
                        {/* Only here for the view. TODO after Planbuilder redesign */}
                        {/* TODO 30 - 90 days range*/}
                        <StartDate datePicker />
                        <BrokerPlanBuilder />
                    </div>
                </section>

                <p class={styles.planDocLinks}>Download <DownloadCompanySamplePlan/> and <Ipid /></p>
            </div>

            <DownloadPDF />
            <QuoteFooter />
        </>
    )
}

const BrokerQuote = () => {
    return (
        <AccountSubscriptionProvider store={editSubscriptionSessionStore()} saveToServer={true}>
            {/* <QuoteController> */}
                <Content />
            {/* </QuoteController> */}
        </AccountSubscriptionProvider >
    )
}

export default BrokerQuote
