import {getAccountSubscription} from '../../../providers/AccountProvider'
import {useDocGenService} from '../../../providers/AppServiceProvider'
import {Component} from 'solid-js'
import {values} from '@peachy/utility-kit-pure'

import {
    AccountDto,
    CompanyPolicyDocData,
    DocGenTicket,
    mapToLegacyPlan,
    mapToLegacyPolicy,
    MrSubscription,
    toClass
} from '@peachy/core-domain-pure'
import {ROOT_DOMAIN} from '../../../App'


export const DownloadCompanyPlan: Component<{ class?: string }> = (props) => {

    const accountSubscription = getAccountSubscription(), docGenService = useDocGenService()

    const download = async () => {
        const ticket = createEmployerDocTicket(accountSubscription.account, accountSubscription.subscription)
        const link = await docGenService.getPdfLink(ticket, `PeachyPlan-${accountSubscription.account.company.name}.pdf`)

        window.open(link, `_CompanyPlanPdf`)
    }

    return (
        <a class={props.class} onClick={download}>View</a>
    )
}


export const createEmployerDocTicket = (account: AccountDto, subscription: MrSubscription): DocGenTicket<CompanyPolicyDocData> => ({
    templateName: 'CompanyPolicyDoc',
    docData: new CompanyPolicyDocData({
        domain: ROOT_DOMAIN,
        isSample: false,
        isAlteration: true,
        subscriptionStartDate: subscription.startDate,
        planNumber: toClass(subscription, MrSubscription).referenceNumber(),
        plans: values(subscription.plans).map(mapToLegacyPlan),
        policies: values(subscription.policies).map(policy => mapToLegacyPolicy(policy)),
        company: account.company,
        email: subscription.contactEmail,
        versionDate: subscription.versionDate,
        totalMonthlyPremium: subscription.totalMonthlyPremium
    } as CompanyPolicyDocData)
})
