import {MrPolicy, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'

import {Draft, mapById, newUUID, Valid, values} from '@peachy/utility-kit-pure'
import {debounce} from '@solid-primitives/scheduled'
import {subYears} from 'date-fns'
import {createContext, createEffect, createSignal, mergeProps, ParentComponent, Show, useContext} from 'solid-js'
import {Spinner} from '../../global/icons/Spinner/Spinner'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {getQuoteStore} from '../../providers/AccountSubscription/session/stores'

import {ISubscriptionQuoteValidator, SubscriptionQuoteClient} from '@peachy/subscription-client'
import {useSubscriptionService} from '../../providers/AppServiceProvider'
import {SubscriptionApiGateway} from '@peachy/subscription-pure'

const QuoteContext = createContext<SmeQuoteClient>()

const [isBusy, setIsBusy] = createSignal<boolean>(false)

export const QuoteController: ParentComponent<{ canQuote?: boolean }> = (propsIn) => {
    const props = mergeProps({ canQuote: true }, propsIn)

    const store = useStore()
    const quoteStorage = getQuoteStore()

    const canQuote = () => props.canQuote && !store.isEditing()

    const [quoteClient, setQuoteClient] = createSignal<SmeQuoteClient>()

    const validator: ISubscriptionQuoteValidator = {
        assertIsValidDraft(quote: Draft<MrSubscriptionQuoteResponse>): asserts quote is Valid<MrSubscriptionQuoteResponse> {
            if (!this.isValidDraft()) {
                throw 'Invalid Quote'
            }
        },

        isValidDraft(quote: Draft<MrSubscriptionQuoteResponse>): quote is Valid<MrSubscriptionQuoteResponse> {
            return store.hasValidSmeLifeCount()
        },

        isValidFinal(quote: Draft<MrSubscriptionQuoteResponse>): quote is Valid<MrSubscriptionQuoteResponse> {
            return store.isValidPolicies()
        }
    }

    const quoteHandler = () => {

        let subRequest = store.getSubscriptionRequest()

        subRequest = JSON.parse(JSON.stringify(subRequest))
        subRequest.quoteRequestId = newUUID()
        // default address and dob if missing for all lives

        subRequest.policies = preparePoliciesForQuote(subRequest.policies as MrPolicy[], store)

        console.log('QUOTE', )

        quoteClient().canUpdateQuote() ? quoteClient().updateQuote(subRequest) : quoteClient().updateDraft(subRequest)
        quoteClient().tryQuote()
    }



    const smeSubscriptionService = useSubscriptionService()
    setQuoteClient(new SmeQuoteClient(smeSubscriptionService, validator))


    const quote = () => {
        debounce(quoteHandler, 500)()
    }

    //this "listens" for any of the referenced property changes - if they do, trigger a quote
    createEffect(() => {

        if (!canQuote()) {
            return
        }

        setIsBusy(true)

        //plan -> life changes
        store.getLives().map(life => `${life.planId}:${life.id}`)
        const benefits = store.getPlans().flatMap(p => values(p.benefits))
        benefits.map(b=> `${b.limit}`)

        // plan benefit/excess changes
        store.getPlans().flatMap(plan => `${plan.benefits}:${plan.excess?.amountInPence}`)

        //policy start date changes
        store.getSubscriptionStartDate()
        quote()
    })

    createEffect(() => {
        if (quoteClient().currentQuoteState() === 'Valid') {
            quoteStorage.save(quoteClient().currentQuoteResponse())
        }

        setIsBusy(quoteClient().isBusy())
    })

    return (
        <Show when={quoteClient()} fallback={<Spinner />}>
            <QuoteContext.Provider value={quoteClient()}>
                {props.children}
            </QuoteContext.Provider>
        </Show>
    )
}

export function getQuote() {
    return useContext(QuoteContext).currentQuoteResponse()
}

export function isQuoteLoading() {
    return isBusy()
}




function preparePoliciesForQuote(policies: MrPolicy[], store) {
    const policiesWithAssignedLives = policies.filter(policy => values(policy.lives).some(life => life.planId))
    return policiesWithAssignedLives.map(policy => {

        const preparedLives = values(policy.lives).filter(life => life.planId).map(life => {
            return {
                ...life,
                address: life.address ?? store.getCompany()?.address,
                dateOfBirth: life.dateOfBirth ?? subYears(new Date, 30).getTime(),
                benefits: {}
            }
        })

        return {
            ...policy,
            lives: mapById(preparedLives)
        }
    })
}


export class SmeQuoteClient extends SubscriptionQuoteClient {
    constructor(subscriptionApi: SubscriptionApiGateway, quoteValidator: ISubscriptionQuoteValidator) {
        super(
            (request) => subscriptionApi.getSmeQuote(request),
            quoteValidator
        )
    }
}
