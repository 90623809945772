import {SessionStorage} from '../../../global/storage/SessionStorage'
import {useStore} from '../AccountSubscriptionProvider'
import {MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'

function getStoreForKey(key: string) {
    return new SessionStorage<MrSubscriptionQuoteResponse>(key)
}

export function subscriptionQuoteSessionStore() {
    return getStoreForKey('subscriptionQuote')
}

export function editSubscriptionSessionStore() {
    return getStoreForKey('editSubscription')
}

export function getQuoteStore() {
    const subscriptionRequestId = useStore().getSubscriptionRequest().subscription.id
    return getStoreForKey(`quote_${subscriptionRequestId}`)
}
