import {createToggleSignal, TextBox} from "@peachy/client-kit"
import {createSignal} from "solid-js"
import {useStore} from "../../../../providers/AccountSubscription/AccountSubscriptionProvider"
import {validateUser} from "../../../../service/validation/ValidationService"
import {CompanyDetail} from "./CompanyDetail"
import styles from './CompanyDetails.module.css'
import {User} from "@peachy/core-domain-pure"

export const CompanyEmail = () => {
    const store = useStore()

    const [email, setEmail] = createSignal(store.getAccount()?.contactEmail ?? '')
    const [isFocus, setIsFocus] = createToggleSignal(false)

    // TODO Refactor this properly in https://teampeachy.atlassian.net/browse/DEV-719
    const handleEmailBlur = (event: any) => {
        store.updateEmail(email())
        setIsFocus()
    }

    const errors = () => validateUser({email: email()} as User)

    const emailCss = () => {
        if (isFocus() || !email() || !errors().email) {
            return ''
        }

        return styles.invalid
    }

    const onInput = (event: any) => {
        setEmail(event)
    }

    return (
        <CompanyDetail title="Group admin email" class={styles.companyEmail}>
            <TextBox
                inputClass={emailCss()}
                placeholder="Email address"
                value={email()}
                onFocus={setIsFocus}
                onInput={(v) => onInput(v)}
                onBlur={handleEmailBlur} />
        </CompanyDetail>
    )
}