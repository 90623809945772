import { parse } from 'csv-parse/browser/esm'

export function parseExpectedFields<T extends readonly string[], U extends T[number]>(fields: T, text: string): Promise<Record<U, string>[]> {
    const delimiter = text.slice(0, text.indexOf('\n')).indexOf('\t') >= 0 ? '\t' : ','
    return new Promise((resolve, reject) => {
        parse(text, {
            delimiter: delimiter,
            columns: true,
            onRecord: (record, context) => {
                const cols = context.columns.map(({ name }) => name)
                return Object.keys(record).reduce((acc, k, i) => {
                    if (fields.includes(cols[i])) {
                        acc[k] = record[k]
                    }
                    return acc
                }, {} as { [k: string]: string })
            }
        }, (err, records) => err ? reject(err) : resolve(records))
    })
}
