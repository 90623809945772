import {currencyFromPence, NOOP, readableDate} from '@peachy/utility-kit-pure'
import {Component, For} from 'solid-js'
import {Button} from '../../../global/forms/Button'
import tableStyles from '../../dashboard/DashboardTable.module.css'
import {UserDetailsPanel} from '../panels/UserDetailsPanel/UserDetailsPanel'
import styles from './MyQuotes.module.css'

const MyQuotes = () => {

    const placeholderData: MyQuote[] = [
        { name: 'Harry Potter', lastEdited: 1704112178000, product: 'Individual', totalMonthlyPremium: 6000, members: 2, status: 'In progress' },
        { name: 'Monster Inc.', lastEdited: 1703334578000, product: 'Corporate', totalMonthlyPremium: 35080, members: 15, status: 'Ready to go' },
        { name: 'Google', lastEdited: 1703075378000, product: 'Corporate', totalMonthlyPremium: 19080, members: 7, status: 'In progress' },
        { name: 'Davey Jones', lastEdited: 1703939378000, product: 'Individual', totalMonthlyPremium: 3889, members: 1, status: 'Ready to go' }
    ]

    return (
        <div class={tableStyles.listContainer}>
            <section>
                <div class={styles.myQuotesHeader}>
                    <h2>My Quotes</h2>
                    <UserDetailsPanel/>
                </div>
                <table class={tableStyles.table}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Last edited</th>
                            <th>Product</th>
                            <th>Monthly premium</th>
                            <th>Number of members</th>
                            <th>Status</th>
                            <th></th> { /* Go to Quote */}
                            <th></th> { /* Delete quote */}
                        </tr>
                    </thead>

                    <tbody>
                        <For each={placeholderData}>
                            {(quote) => <MyQuoteRow  {...quote} />}
                        </For>
                    </tbody>
                </table>

                <div class={styles.addQuote}>
                    {/* <Button theme="primary" onClick={NOOP}>New individual quote</Button> */}
                    <Button theme="primary" onClick={NOOP}>New corporate quote</Button>
                </div>
            </section>
        </div>
    )
}

// Placeholder
type MyQuote = {
    name: string
    lastEdited: number
    product: 'Individual' | 'Corporate'
    totalMonthlyPremium: number
    members: number
    status: 'In progress' | 'Ready to go'
}

const MyQuoteRow: Component<MyQuote> = (props) => {

    return (
        <tr>
            <td>{props.name}</td>
            <td>{readableDate(props.lastEdited)}</td>
            <td>{props.product}  PMI</td>
            <td>{currencyFromPence(props.totalMonthlyPremium)}</td>
            <td>{props.members}</td>
            <td>{props.status}</td>
            <td><a class={styles.goToQuote}>Go to quote</a></td>
            <td><button onClick={NOOP}><i class="fas fa-trash" /></button></td>
        </tr >
    )
}

export default MyQuotes
