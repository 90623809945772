import {DateTime, Pence} from '../../some-types'
import {MrPaymentEvent} from './MrPaymentEvent'


export type PaymentPeriodStatus =
    | 'DUE' // payment for this period has not yet been attempted
    | 'FAILED' // payment for this period has been attempted and has failed
    | 'PARTIALLY_SETTLED' // some payment for this period has been received but not all - can happen due to plan changes
    | 'SETTLED' // payment for this period has been fully settled



export class MrPaymentPeriod {

    // incremental number representing the billing cycle count (ie months since start date for monthly billing)
    periodNumber: number

    // usually represents the start and end of a cover month - may be less if plan changes have occurred
    periodStartAt: DateTime
    periodEndAt: DateTime

    status: PaymentPeriodStatus

    // total amount due for the period. Needs updating if period changed due to plan changes
    // amountDue = amountToCollect + amountDiscounted
    amountDue: Pence

    // amount not covered by any discounts
    amountToCollect: Pence

    // amount covered by promo code or other discounts
    amountToDiscount: Pence

    // actual amount collected so far for this period
    amountCollected: Pence

    // amount received or discounted so far. Status is PARTIALLY_SETTLED if this !== amountDue
    amountSettled: Pence

    discountCode?: string

    paymentEvents: MrPaymentEvent[]
}
