import {Premium} from '@peachy/core-domain-pure'
import {KeyMapped, parseDateWithTimezone, UK_TIMEZONE} from '@peachy/utility-kit-pure'
import {getSum} from '../../util/Calc'
import {IndividualQuoteRequest} from '../core/IndividualQuoteRequest'
import {IndividualQuoteResponse} from '../core/IndividualQuoteResponse'
import {BenefitPriceBreakdown, PricingBreakdown} from '../core/PricingBreakdown'
import {QuoteBenefitType} from '../core/QuoteBenefitType'
import {QuoteRegion} from '../core/QuoteRegion'
import {LegacyQuoteRequest, LegacyQuoteResponse} from './Legacy'

export class QuoteLifeRequest {
    quoteRequestId: string
    quoteRequestTimestamp: number
    includePricingBreakdown?: boolean
    quoteParamDefaults?: QuoteParams
    quoteParamOverrides?: QuoteParams
    lives: KeyMapped<QuoteLife>
}

export class QuoteLifeResponse extends QuoteLifeRequest {
    quoteResponseId: string
    quoteResponseTimestamp: number
}



export type QuoteParameterSet = {
    defaults?: QuoteParams
    overrides?: QuoteParams
}


export type QuoteParams = {
    quoteModelVersion?: string
    renewalPeriodMonths?: number
    rateSet?: 'INDIVIDUAL' | 'SME' | 'VOLUNTARY'
    groupSize?: number
    startDate?: number
    timezone?: string
    moriDate?: number
    effectiveDate?: number
    annualBilling?: boolean,
    excess?: number,
    benefitSpecificExcess?: boolean
}

export type QuoteLife = {
    id: string,
    gender: string
    region: QuoteRegion
    postcode: string
    dateOfBirth: number
    benefits: KeyMapped<QuoteBenefit, QuoteBenefitType>
    quoteParamDefaults?: QuoteParams
    quoteParamOverrides?: QuoteParams
}

export type QuoteBenefit = QuoteParams & {
    id: QuoteBenefitType,
    limit?: number
    premium?: Premium
}


export function resolveQuoteParams(
    engineDefaults: QuoteParams,
    requestDefaults: QuoteParams,
    lifeDefaults: QuoteParams,
    quoteBenefit: QuoteBenefit,
    lifeOverrides: QuoteParams,
    requestOverrides: QuoteParams,
): QuoteBenefit {
    return {
        ...engineDefaults ?? {},
        ...requestDefaults ?? {},
        ...lifeDefaults ?? {},
        ...quoteBenefit ?? {},
        ...lifeOverrides ?? {},
        ...requestOverrides ?? {},
    } as QuoteBenefit
}

export function resolveQuoteEffectiveDate(quoteParams: QuoteParams) {
    return quoteParams.effectiveDate ?? quoteParams.moriDate ?? quoteParams.startDate
}

export function legacyQuoteRequestToQuoteLifeRequest(legacyQuoteRequest: LegacyQuoteRequest): QuoteLifeRequest {
    const legacyLives = [
        legacyQuoteRequest.primaryIndividual,
        populateWithDefaults(legacyQuoteRequest.secondaryIndividual, legacyQuoteRequest.primaryIndividual),
        ...legacyQuoteRequest.children?.map(it => populateWithDefaults(it, legacyQuoteRequest.primaryIndividual)) ?? [],
        ...legacyQuoteRequest.groupedIndividuals ?? []
    ].filter(it => !!it)
    const quoteLives: KeyMapped<QuoteLife> = {}
    legacyLives.forEach(life => quoteLives[life.lifeId] = toQuoteLife(life, legacyQuoteRequest))
    return {
        includePricingBreakdown: legacyQuoteRequest.primaryIndividual?.includeBreakdown, // not ideal we're now using this to dictate whole message but meh
        lives: quoteLives,
        quoteParamDefaults: getQuoteParams(legacyQuoteRequest),
        quoteParamOverrides: {},
        quoteRequestId: legacyQuoteRequest.quoteRequestId,
        quoteRequestTimestamp: Date.now()
    }
}

function populateWithDefaults(request: IndividualQuoteRequest, defaultRequest: IndividualQuoteRequest) {
    if (request) {
        request.coverStartDate = request.coverStartDate ?? defaultRequest.coverStartDate
        request.renewalPeriodMonths = request.renewalPeriodMonths ?? defaultRequest.renewalPeriodMonths
        request.address = request.address ?? defaultRequest.address
        request.region = request.region ?? defaultRequest.region
        request.address = request.address ?? defaultRequest.address
        request.requiredBenefits = request.requiredBenefits ?? defaultRequest.requiredBenefits
        request.benefitOverrides = request.benefitOverrides ?? defaultRequest.benefitOverrides
        request.benefitLimits = request.benefitLimits ?? defaultRequest.benefitLimits
    }
    return request
}

export function quoteLifeResponseToLegacyQuoteResponse(legacyQuoteRequest: LegacyQuoteRequest, quoteLifeResponse: QuoteLifeResponse): LegacyQuoteResponse {
    const lives = Object.values(quoteLifeResponse.lives)
    const primary = lives.find(it => it.id == legacyQuoteRequest.primaryIndividual?.lifeId)
    const secondary = lives.find(it => it.id == legacyQuoteRequest.secondaryIndividual?.lifeId)
    const children = lives.filter(it => legacyQuoteRequest.children?.map(it => it.lifeId).includes(it.id)) ?? []
    const grouped = lives.filter(it => legacyQuoteRequest.groupedIndividuals?.map(it => it.lifeId).includes(it.id)) ?? []
    return {
        children: children.map(it => toIndividualQuoteResponse(it)),
        groupedIndividuals: grouped.map(it => toIndividualQuoteResponse(it)),
        primaryIndividual: toIndividualQuoteResponse(primary),
        quoteRequestId: legacyQuoteRequest.quoteRequestId,
        quoteResponseId: quoteLifeResponse.quoteResponseId,
        secondaryIndividual: toIndividualQuoteResponse(secondary),
        timestamp: quoteLifeResponse.quoteResponseTimestamp,
        totalPolicyPrice: getSum(lives.map(it => getSum(Object.values(it.benefits).map(ben => ben.premium.total))))

    }
}

export function toIndividualQuoteResponse(quoteLife: QuoteLife): IndividualQuoteResponse {
    if (quoteLife) {
        const benefits = Object.values(quoteLife.benefits)
        const benefitPriceBreakdown: PricingBreakdown = {
            a_baseRiskCost: {},
            b_adjustedForCorrelation: {},
            c_adjustedForContingency: {},
            d_adjustedForLossRatio: {},
            e_adjustedForIpt: {},
            f_adjustedForDiscounts: {},
            g_adjustedForPriceResolution: {}
        }
        const pricesByBenefit: KeyMapped<number, QuoteBenefitType> = {}
        benefits.forEach(benefit => {
            benefitPriceBreakdown.a_baseRiskCost[benefit.id] = benefit.premium.baseRiskCost
            benefitPriceBreakdown.b_adjustedForCorrelation[benefit.id] = benefit.premium.adjustedForCorrelation
            benefitPriceBreakdown.c_adjustedForContingency[benefit.id] = benefit.premium.adjustedForContingency
            benefitPriceBreakdown.d_adjustedForLossRatio[benefit.id] = benefit.premium.adjustedForLossRatio
            benefitPriceBreakdown.e_adjustedForIpt[benefit.id] = benefit.premium.adjustedForIpt
            benefitPriceBreakdown.f_adjustedForDiscounts[benefit.id] = benefit.premium.adjustedForDiscounts
            benefitPriceBreakdown.g_adjustedForPriceResolution[benefit.id] = benefit.premium.adjustedForPriceResolution
            pricesByBenefit[benefit.id] = benefit.premium.total
        })
        return {
            lifeId: quoteLife.id,
            benefitPriceBreakdown: benefitPriceBreakdown as PricingBreakdown,
            pricesByBenefit: pricesByBenefit as BenefitPriceBreakdown,
            totalPlanPrice: getSum(benefits.map(it => it.premium.total))
        }
    }
}

export function toQuoteLife(individual: IndividualQuoteRequest, legacyQuoteRequest: LegacyQuoteRequest): QuoteLife {
    return {
        benefits: getQuoteBenefits(individual, legacyQuoteRequest),
        dateOfBirth: parseAsUkDate(individual.birthdate),
        gender: individual.gender,
        id: individual.lifeId,
        postcode: individual.address?.postcode,
        quoteParamDefaults: getQuoteParams(legacyQuoteRequest, individual),
        quoteParamOverrides: {},
        region: individual.region
    }
}

export function getQuoteBenefits(individual: IndividualQuoteRequest, legacyQuoteRequest: LegacyQuoteRequest): KeyMapped<QuoteBenefit> {
    const quoteBenefits: KeyMapped<QuoteBenefit> = {}
    individual.requiredBenefits.forEach(benefit => {
        const benefitStartDate = individual.benefitOverrides?.[benefit]?.coverStartDate
        const benefitRenewalPeriod = individual.benefitOverrides?.[benefit]?.renewalPeriodMonths
        const benefitSpecificExcess = individual.applyExcessAtBenefitLevel
        let benefitExcess = 0
        if (benefitSpecificExcess) {
            benefitExcess = individual.benefitLevelExcesses?.[benefit] ?? 0
        } else {
            if (individual.excessBenefits?.includes(benefit)) {
                benefitExcess = individual.overallExcess
            }
        }
        quoteBenefits[benefit] = {
            id: benefit,
            limit: individual.benefitLimits[benefit],
            ...{
                ...getQuoteParams(legacyQuoteRequest, individual),
                effectiveDate: parseAsUkDate(benefitStartDate ?? individual.coverStartDate),
                renewalPeriodMonths: benefitRenewalPeriod ?? individual.renewalPeriodMonths,
                excess: benefitExcess,
                benefitSpecificExcess: benefitSpecificExcess && !!benefitExcess
            }
        }
    })
    return quoteBenefits
}

function getQuoteParams(legacyQuoteRequest: LegacyQuoteRequest, individual?: IndividualQuoteRequest): QuoteParams {
    return {
        quoteModelVersion: `${legacyQuoteRequest.version}`, //TODO: Formalise model version naming convention
        rateSet: legacyQuoteRequest.type,
        annualBilling: legacyQuoteRequest.annualBilling,
        renewalPeriodMonths: individual?.renewalPeriodMonths,
        groupSize: legacyQuoteRequest.groupSize,
        effectiveDate: individual ? parseAsUkDate(individual.coverStartDate) : undefined
    }
}

function parseAsUkDate(date: string) {
    return parseDateWithTimezone(date, UK_TIMEZONE).getTime()
}
