import styles from './Child.module.css'

export default function () {
    return (
        <svg class={styles.child} version="1.1" viewBox="0 0 1367.26 1200" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M868.5,665.9l-0.9,0.8c-11.8,11.9-16.6,28.8-13,45.2c3.7,16.4,15.3,29.6,31,35.4s33.2,3.2,46.5-6.9l0.8-0.7 c20.4-17.7,36.8-40.2,47.5-65.1c10.7-24.8,15.6-52.2,14.4-79.2c-1.8-38.2-15.2-74.2-38.9-104.2c-23.7-30-55.7-51.4-92.4-62 c-36.8-10.5-75.2-9.3-111.2,3.6c-36,12.9-66.5,36.3-88.2,67.8c-21.7,31.5-32.8,68.3-32.1,106.5c0.6,32.3,9.6,63.3,26.2,90.6 c-22.8,14.3-44.2,30.8-63.9,49.2c-14.4,13.5-25.7,29.6-33.5,47.8c-7.8,18.1-11.8,37.4-11.8,57.1v33.1c0,26.4,10.3,51.2,28.9,69.8 c18.7,18.7,43.5,28.9,69.8,28.9h157.3c17.5,0,33.8-9.4,42.5-24.5c8.7-15.2,8.7-34,0-49.1s-25-24.5-42.5-24.5H647.8 c-0.1,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.2-0.2-0.4v-33.1c0-12.4,5.2-24.5,14.4-33c27.5-26,59.2-46.4,94.2-60.6 c11-4.4,20.1-12.6,25.6-23.1c5.5-10.6,6.9-22.8,4.1-34.3c-2.8-11.5-9.8-21.7-19.5-28.5l-0.1-0.1c-19.7-13.5-32.7-34.9-35.6-58.6 s4.5-47.6,20.4-65.5c15.8-17.8,38.5-28,62.3-28c0.1,0,0.2,0,0.3,0h3.6c20.8,1,40.4,9.6,55.1,24.3c14.8,14.8,23.6,34.4,24.6,55.3 C897.9,625.1,887.6,649.2,868.5,665.9L868.5,665.9z" />
            </g>
        </svg>
    )
}