import Stripe from 'stripe'


export const STRIPE_KEYS_NAME = 'peachy/stripe'

export type StripeKeys = {
    secretKey: string
    publicKey: string
    hookCheck: string
}


export type PaymentCustomer = {
    email: string
    fullName: string
}

export type ConfirmedPaymentIntent = {
    customer: PaymentCustomer
    paymentMethodId: string
}

export class PaymentIntent {
    clientSecret: string
    intentId: string
    status: string
}

export type CreatePaymentIntentRequest = {}

export type AbandonPaymentIntentRequest = {
    intentId: string
}


export type PeachySubscriptionRequest = {
    customer: PaymentCustomer
    productId: string
    idempotencyKey: string
    paymentMethodId: string
    quotePrice: number
    promoCode?: string
    startDate: Date
}

export type PeachySubscriptionResponse = {
    subscriptionId: string
    clientSecret: string
    subscription: Stripe.Subscription
}

export class PeachyPaymentCard {
    last4Digits: string
    brand: string
    expireMonth: number
    expireYear: number
}



export class Promo {
    id: string
    object: 'promotion_code'
    active: boolean
    code: string
    coupon: Coupon
    created: number
    customer: any
    expires_at: number
    livemode: boolean
    max_redemptions?: number
    metadata: Record<string, string> & {
        email_restrictions?: string
        blurb?: string
        capture_card_blurb?: string
        hide_from_quoted_price?: string
    }
    restrictions: {
        first_time_transaction: boolean
        minimum_amount?: number
        minimum_amount_currency?: number
    }
    times_redeemed: number
}

export class Coupon {
    /**
     * Unique identifier for the object.
     */
    id: string

    /**
     * String representing the object's type. Objects of the same type share the same value.
     */
    object: 'coupon'

    /**
     * Amount (in the `currency` specified) that will be taken off the subtotal of any invoices for this customer.
     */
    amount_off: number | null

    // applies_to?: Coupon.AppliesTo;

    /**
     * Time at which the object was created. Measured in seconds since the Unix epoch.
     */
    created: number

    /**
     * If `amount_off` has been set, the three-letter [ISO code for the currency](https://stripe.com/docs/currencies) of the amount to take off.
     */
    currency: string | null

    /**
     * Coupons defined in each available currency option. Each key must be a three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html) and a [supported currency](https://stripe.com/docs/currencies).
     */
    // currency_options?: {
    //     [key: string]: Coupon.CurrencyOptions;
    // };

    deleted?: void

    /**
     * One of `forever`, `once`, and `repeating`. Describes how long a customer who applies this coupon will get the discount.
     */
    duration: 'forever' | 'once' | 'repeating'

    /**
     * If `duration` is `repeating`, the number of months the coupon applies. Null if coupon `duration` is `forever` or `once`.
     */
    duration_in_months: number | null

    /**
     * Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode.
     */
    livemode: boolean

    /**
     * Maximum number of times this coupon can be redeemed, in total, across all customers, before it is no longer valid.
     */
    max_redemptions: number | null

    /**
     * Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     */
    // metadata: Stripe.Metadata | null;

    /**
     * Name of the coupon displayed to customers on for instance invoices or receipts.
     */
    name: string | null

    /**
     * Percent that will be taken off the subtotal of any invoices for this customer for the duration of the coupon. For example, a coupon with percent_off of 50 will make a %s100 invoice %s50 instead.
     */
    percent_off: number | null

    /**
     * Date after which the coupon can no longer be redeemed.
     */
    redeem_by: number | null

    /**
     * Number of times this coupon has been applied to a customer.
     */
    times_redeemed: number

    /**
     * Taking account of the above properties, whether this coupon can still be applied to a customer.
     */
    valid: boolean

    metadata: Record<string, string>
}
