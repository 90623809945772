import {Address, Company} from '@peachy/core-domain-pure'
import {Component} from 'solid-js'

export const InfoPanelBillingAddressDetails: Component<{ company: Company, billingAddress: Address }> = (props) => {
    const billingAddress = () => props.billingAddress?.display ?? props.company?.address?.display

    return (
        <p>Billing Address <span>{billingAddress()}</span></p>
    )
}
