import styles from './QuoteHeader.module.css'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'

export const QuoteHeader = () => {
    const store = useStore()

    return (
        <section class={styles.quoteHeader}>
            <h4>Let's build your company plan {store.getUser().firstname}</h4>
            <p>Customise options and drag your employees to build the right plan for your company</p>
            <p>We can give you an indicative price just by knowing how many members you want in each plan</p>
            <p>To get a final price we'll need each member's full details</p>
        </section>
    )
}
