import {JSX, onMount} from 'solid-js'
import {classList} from '@peachy/utility-kit-pure'
import styles from './TextBox.module.css'

export type TextBoxProps = JSX.InputHTMLAttributes<HTMLInputElement> &  {
    autoFocus?: boolean
    onEnter?: (value: string) => void
    onKeyUp?: (keyboardEvent: KeyboardEvent) => void

}

export function TextBox (props: TextBoxProps) {
    let htmlElement

    onMount(() => {
        if (props.autoFocus) {
            htmlElement.focus()
        }
    })

    const onKey = (keyboardEvent: KeyboardEvent) => {
        props.onKeyUp?.(keyboardEvent)
        if (keyboardEvent.key === 'Enter') {
            // @ts-ignore
            props.onEnter?.(keyboardEvent.currentTarget.value)
        }
    }

    return (
        <label class={classList(styles.label, props.class)}>
            <input
                {...props}
                onKeyUp={onKey}
                type={props.type ?? 'text'}
                ref={htmlElement}
                class={styles.textBox}
            />
            {props.children}
        </label>
    )
}
