import {getApiMetadata} from '@peachy/core-domain-client'
import {DocGenTicket} from '@peachy/core-domain-pure'
import {DocGenApi} from '@peachy/doc-gen-pure'

export type DocGenService = {
    getPdfLink: (request: DocGenTicket, fileName: string) => Promise<string>
}

export function createDocGenService(api: DocGenApi): DocGenService {
    const generateLink = (ticketId: string, asFile: string) => {
        const routes = getApiMetadata(api).routes
        return `${routes.renderPdfTicket}/${asFile}?ticketId=${ticketId}`
    }

    return {
        async getPdfLink (request: DocGenTicket, fileName: string): Promise<string> {
            try {
                const ticket = await api.preparePdfTicket(request)
                return generateLink(ticket.ticketId, fileName)
            } catch (e) {
                console.error(`Problem generating the PDF`, e)
                throw Error(e.toString())
            }
        }
    }
}
