import {Page} from '../Page'
import {LoginHeader} from '../components/Header/LoginHeader/LoginHeader'
import {IamModal} from '../components/Iam/IamModal'

export default function Login () {
    return (
        <Page header={<LoginHeader />}>
            <IamModal/>
        </Page>
    )
}
