import {GenderType} from '@peachy/core-domain-pure'
import {QuoteRegion} from './QuoteRegion'
import {QuoteBenefitOverrides, QuoteBenefitType, QuoteBenefitValues} from './QuoteBenefitType'


export type IndividualQuoteRequest = {
    lifeId: string
    birthdate: string
    includeBreakdown: boolean
    coverStartDate: string
    renewalPeriodMonths?: number
    gender: GenderType
    region?: QuoteRegion
    address: {
        display: string
        postcode: string
        addressComponents: string[]
        location: {
            lat: number
            lng: number
        }
    }
    requiredBenefits: QuoteBenefitType[]
    benefitOverrides?: QuoteBenefitOverrides
    benefitLimits: QuoteBenefitValues
    overallExcess?: number
    excessBenefits?: QuoteBenefitType[]
    applyExcessAtBenefitLevel?: boolean
    benefitLevelExcesses?: QuoteBenefitValues
}
