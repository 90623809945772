import {QuoteSummary} from './QuoteSummary'
import {StartDate} from '../../../components/StartDate/StartDate'
import {quoteNav} from '../Quote'
import styles from './PlanDetails.module.css'
import {PremiumDetails} from './PremiumDetails'
import {SubHeader} from './SubHeader'
import summaryStyles from './Summary.module.css'

export const PlanDetails = () => {
    return (
        <div class={styles.PlanDetails}>
            <div class={summaryStyles.DetailsContainer}>
                <SubHeader heading={'Plan Details'} onEditClick={quoteNav.toPlanDetails}/>
                <div class={summaryStyles.StartDate}>
                    <StartDate/>
                </div>
            </div>
            <div class={summaryStyles.PlanConfig}>
                <QuoteSummary/>
            </div>
            <div class={summaryStyles.DetailsContainer}>
                <PremiumDetails/>
            </div>
        </div>
    )
}
