import {NOOP} from '@peachy/utility-kit-pure'
import {Button} from '../../../../global/forms/Button'
import styles from './DownloadPDF.module.css'

export const DownloadPDF = () => {
    return (
        <section class={styles.container}>
            <Button theme="primary" onClick={NOOP}>Download PDF</Button>
        </section>
    )
}