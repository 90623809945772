import {classList} from '@peachy/utility-kit-pure'
import {ParentComponent} from 'solid-js'
import pageStyles from '../BrokerQuote.module.css'

export const CompanyDetail: ParentComponent<{title: string, class?: string}> = (props) => {
    const classes = classList(pageStyles.details, props.class)

    return <div class={classes}>
        <p class={pageStyles.subTitle}>{props.title}</p>
        {props.children}
    </div>
}