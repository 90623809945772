import {buildMachine, Machine, ON, StatePaths, TAGS} from '@peachy/client-kit'


const quoteMachineDef = {

    Draft: {
        [ON]: {
            updateDraft: () => quoteMachineDef.Draft,
            tryQuote: () => () => quoteMachineDef.ValidatingDraft
        }
    },

    ValidatingDraft: {
        [TAGS]: 'busy',
        [ON]: {
            validDraft: () => quoteMachineDef.Quoting,
            invalidDraft: () => quoteMachineDef.Draft
        }
    },

    Quoting: {
        [TAGS]: 'busy',
        [ON]: {
            quoteSuccess: () => quoteMachineDef.ValidatingFinal,
            quoteFailure: () => quoteMachineDef.Quotable
        }
    },

    Quotable: {
        [ON]: {
            editQuote: () => quoteMachineDef.Draft,
            updateQuote: () => quoteMachineDef.ValidatingDraft,
        }
    },

    ValidatingFinal: {
        [TAGS]: 'busy',
        [ON]: {
            validFinal: () => quoteMachineDef.Valid,
            invalidFinal: () => quoteMachineDef.Quotable
        }
    },

    Valid: {
        [ON]: {
            editQuote: () => quoteMachineDef.Draft,
            updateQuote: () => quoteMachineDef.ValidatingDraft,
        }
    },

} as const





export function createQuoteStateMachine() {
    return buildMachine(quoteMachineDef)
}


export type QuoteState = StatePaths<typeof quoteMachineDef>
export type QuoteStateMachine = Machine<typeof quoteMachineDef>