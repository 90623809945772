import {QuoteLifeRequest, QuoteLifeResponse} from '@peachy/quote-pure'
import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {Draft} from '@peachy/utility-kit-pure'

export class QuoteStore {

    private quoteRequestStore: Store<Draft<QuoteLifeRequest>>
    private updateQuoteRequestStore: SetStoreFunction<Draft<QuoteLifeRequest>>

    private quoteResponseStore: Store<QuoteLifeResponse>
    private updateQuoteResponseStore: SetStoreFunction<QuoteLifeResponse>

    constructor() {
        [this.quoteRequestStore, this.updateQuoteRequestStore] = createStore<Draft<QuoteLifeRequest>>({})
        ;[this.quoteResponseStore, this.updateQuoteResponseStore] = createStore<QuoteLifeResponse>({} as QuoteLifeResponse)
    }

    updateQuoteRequest(quoteRequest: Draft<QuoteLifeRequest>) {
        this.updateQuoteRequestStore(quoteRequest)
    }

    updateQuoteResponse(quoteResponse: QuoteLifeResponse) {
        this.updateQuoteResponseStore(quoteResponse)
    }

    clearQuoteResponse() {
        this.updateQuoteResponse(null)
    }

    quoteRequest() {
        return this.quoteRequestStore
    }

    quoteResponse() {
        return this.quoteResponseStore
    }
}
