import {Component} from 'solid-js'
import {InfoPanel} from '../pure/InfoPanel'

export const UserDetailsPanel: Component = () => {

    return (
        <InfoPanel>
            <h5>JOHN SMITH : PCY123J</h5>
            <p>The broker company</p>
        </InfoPanel>
    )
}
