import {createSignal} from 'solid-js'
import {CompanyTypeahead as PeachyCompanyTypeahead} from '../../../../components/Company/CompanyTypeahead'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {CompanyDetail} from './CompanyDetail'
import {ListSelectorAppearance} from '@peachy/client-kit'
import styles from './CompanyDetails.module.css'

export const CompanyTypeahead = () => {
    const store = useStore()
    
    const [selectedCompany, setSelectedCompany] = createSignal(store.getCompany() ?? undefined)

    const setCompany = (company) => {
        setSelectedCompany(company)
        store.updateCompany(company)
    }
    
    return (
        <CompanyDetail title="Company name">
            <PeachyCompanyTypeahead
                company={selectedCompany()}
                setCompany={setCompany}
                autoFocus
                placeholder="Enter whole words to see results"
                popup
                appearance={{
                    list: styles.companyTypeahead
                } as ListSelectorAppearance}
            />
        </CompanyDetail>
    )
}