import {PageRouter, toPage} from '../../components/PageRouter/PageRouter'
import {PageRouterPath} from '../../components/PageRouter/PageRouterPath'
import {QuoteController} from '../../components/Quote/QuoteController'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {CaptureAdminCompany} from './CaptureAdminUser/CaptureAdminCompany'
import {CaptureAdminEmail} from './CaptureAdminUser/CaptureAdminEmail'
import {CaptureAdminName} from './CaptureAdminUser/CaptureAdminName'
import {CapturePlanDetails} from './CapturePlanDetails/CapturePlanDetails'
import {Summary} from './Summary/Summary'
import {QuoteAndBuyHeader} from '../../components/Header/QuoteAndBuyHeader/QuoteAndBuyHeader'
import {Page} from '../../Page'

const PlanDetailsComponent = () => <QuoteController><CapturePlanDetails /></QuoteController>

const QUOTE_BASE_PATH = '/quote'
const QUOTE_COMPANY_PATH = `${QUOTE_BASE_PATH}/company`
const QUOTE_EMAIL_PATH = `${QUOTE_BASE_PATH}/email`
const QUOTE_PLANS_PATH = `${QUOTE_BASE_PATH}/plans`
const QUOTE_SUMMARY_PATH = `${QUOTE_BASE_PATH}/summary`

const quoteAndBuyRoutes = [
    {
        component: CaptureAdminName,
        path: QUOTE_BASE_PATH,
        canAccess: () => true
    },
    {
        component: CaptureAdminCompany,
        path: QUOTE_COMPANY_PATH,
        canAccess: () => !!(useStore().getSubscriptionRequest().account?.user?.lastname)
    },
    {
        component: CaptureAdminEmail,
        path: QUOTE_EMAIL_PATH,
        canAccess: () => !!(useStore().getSubscriptionRequest().account?.company?.id)
    },
    {
        component: PlanDetailsComponent,
        path: QUOTE_PLANS_PATH,
        canAccess: () => !!(useStore().getSubscriptionRequest().account?.user?.cognitoUserId)
    },
    {
        component: Summary,
        path: QUOTE_SUMMARY_PATH,
        canAccess: () => !!(useStore().canPurchase())
    }
] as PageRouterPath[]

export const quoteNav = {
    toBusinessDetails() {
        toPage(quoteAndBuyRoutes, QUOTE_COMPANY_PATH)
    },
    toPlanDetails() {
      toPage(quoteAndBuyRoutes, QUOTE_PLANS_PATH)
    }
}

export function QuoteAndBuy() {
    return (
        <Page header={<QuoteAndBuyHeader />}>
            <PageRouter routes={quoteAndBuyRoutes}></PageRouter>
        </Page>
    )
}
