import {Component} from 'solid-js'
import {LifeRowCellProps, validateFieldCss} from '../PolicyRow'
import tableStyles from '../ManageMembersTable.module.css'
import {createToggleSignal, TextBox} from '@peachy/client-kit'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'

export const FirstNameCell: Component<LifeRowCellProps> = (props) => {
    const store = useStore()
    const life = props.life

    const [isFocus, setIsFocus] = createToggleSignal(false)
    const textBoxCss = () => isFocus() ? '' : validateFieldCss(life, 'firstname')

    return <td class={tableStyles.name}>
        <TextBox value={life.firstname ?? ''}
                 onChange={firstname => {
                     store.updateLife(props.policyIndex, life.id, {firstname: firstname.trim()})
                 }}
                 class={textBoxCss()}
                 onFocus={setIsFocus}
                 onBlur={setIsFocus}
                 placeholder={props.placeholder}
                 readonly={props.readonly}
        />
    </td>
}
