import {Accessor} from 'solid-js'
import createBufferedSignal from './createBufferedSignal'


export function createThrottledSignal<T>(initialValue: T, maxRate: number, bufferSize: number = 2): ThrottledSignal<T> {

    const [value, push, advanceBuffer, buffer] = createBufferedSignal(initialValue, bufferSize)

    let timeoutHandle: any = 0
    let lastUpdatedAt = 0

    const checkBuffer = () => {
        if (buffer().length > 1 && !timeoutHandle) {
            const waitTime = Math.max((lastUpdatedAt + maxRate) - Date.now(), 0)
            timeoutHandle = setTimeout(() => {
                timeoutHandle = null
                lastUpdatedAt = Date.now()
                advanceBuffer()
                checkBuffer()
            }, waitTime)
        }
    }

    const pushValue = (value: T) => {
        if (push(value)) {
            checkBuffer()
            return true
        } else {
            return false
        }
    }

    return [value, pushValue, buffer]
}

export type ThrottledSignal<T> = [Accessor<T>, (value: T) => boolean, Accessor<T[]>]
