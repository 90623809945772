import {createStore} from 'solid-js/store'
import {fetchServiceConfig} from '@peachy/core-domain-client'
import {useServicePatch} from '@peachy/client-kit'
import {PlanConfig} from '@peachy/product-client'

type AppConfig = {
    plans: PlanConfig
}

const [store, setStore] = createStore<AppConfig>({ plans: {} } as AppConfig)

export async function configureApp() {
    const configJson = await fetchServiceConfig('SmePlanConfig', useServicePatch())

    setStore({
        plans: new PlanConfig(configJson.config) 
    })
}

export function usePlanConfig() {
    return store.plans
}
