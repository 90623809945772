import {createEffect, createSignal, Show} from 'solid-js'
import styles from './ManageMembersModal.module.css'
import {ModalProps} from '@peachy/client-kit'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {DismissibleModal} from '../../Modal/DismissibleModal'
import {ManageMembersTable} from '../table/ManageMembersTable'
import {Button} from '../../../global/forms/Button'
import {BulkAddMembers} from '../bulk-add/BulkAddMembers'

const ManageMembersBrief = () => <>
    <p>We can give you an indicative price just by knowing how many members you want in each plan</p>
    <p>To get a final price and buy the plan, we'll need all the details below.</p>
</>

export function ManageMembersModal(props: ModalProps) {
    const [addingBulk, setAddingBulk] = createSignal(false)
    const store = useStore()

    createEffect(() => {
        if (props.isOpen) {
            store.addPlaceholderPolicies()
        }
    })

    const close = () => {
        props.onDismiss?.()
        setAddingBulk(false)
        store.removePlaceholderPolicies()
    }

    return (
        <DismissibleModal isOpen={props.isOpen} class={styles.modal} onDismiss={close}>
            <Show when={!addingBulk()}>
                <AddMembers
                    onAddBulkButtonClick={() => setAddingBulk(true)}
                    onUpdateQuoteClick={close}/>
            </Show>
            <Show when={addingBulk()}>
                <BulkAddMembers onComplete={() => setAddingBulk(false)}/>
            </Show>
        </DismissibleModal>
    )
}

function AddMembers(props: { onAddBulkButtonClick: () => void, onUpdateQuoteClick: () => void }) {
    const store = useStore()

    return (
        <section>
            <h3>Add members</h3>

            <Show when={store.isFullValidation()} fallback={<ManageMembersBrief/>}>
                <p>Please complete the highlighted fields so we can give you a final price before you buy.</p>
                <p>At least 2 employees must be added to the plan.</p>
            </Show>

            <div class={styles.table}>
                <ManageMembersTable/>
            </div>

            <div class={styles.footerContainer}>
                <div class={styles.add}>
                    <Show when={store.canAddPolicy()} fallback={<div/>}>
                        <Button theme="secondary" onClick={() => store.addPolicy()}>Add employee</Button>
                        <Show when={store.canBulkAdd()}>
                            <Button theme="secondary" onClick={props.onAddBulkButtonClick}>Bulk upload</Button>
                        </Show>
                    </Show>
                </div>
                <div class={styles.done}>
                    <Button theme="primary" onClick={props.onUpdateQuoteClick}>Update quote</Button>
                </div>
            </div>
        </section>
    )
}
