import {AlivenessCheckStatus, IdCheckStatus, SanctionsCheckStatus} from './statuses'
import {MrLifeBenefit} from './MrLifeBenefit'
import Required from '../../validation/validators/Required'
import {GenderType, GenderTypeArray, LifeType} from '../Life'
import IsIn from '../../validation/validators/IsIn'
import Optional from '../../validation/validators/Optional'
import IsEmail from '../../validation/validators/IsEmail'
import {Type} from 'class-transformer'
import {Address} from '../Address/Address'
import {differenceInYears,} from 'date-fns'
import {Pence} from '../../some-types'
import {BenefitType} from '../PlanBenefit'
import {KeyMapped} from '@peachy/utility-kit-pure'
import {HasLifecycleStatus, LifecycleStatus} from './LifecycleStatus'

export class MrLife implements HasLifecycleStatus {

    planId?: string
    customPlanId?: string

    status: LifecycleStatus
    startDate: number
    endDate?: number
    cancellationReason?:string
    nextRenewalDate?: number
    lastRenewalDate?: number

    totalMonthlyPremium?: Pence

    benefits: KeyMapped<MrLifeBenefit, BenefitType> = {}

    id: string
    cognitoUserId?: string
    intercomUserId?: string

    @Required()
    type: LifeType

    @Required()
    firstname: string

    @Required()
    lastname: string

    // todo - age validation with respect to the start date!
    @Required()
    dateOfBirth: number

    @IsIn(GenderTypeArray)
    @Optional()
    gender?: GenderType

    @IsEmail()
    email?: string

    phone?: string
    @Type(() => Address)
    @Required()
    address: Address

    @Required()
    postcode: string

    @Required()
    marketingAccepted: boolean = false

    @Required()
    smsMarketingAccepted: boolean = false


    idCheckStatus?: IdCheckStatus
    sanctionsCheckStatus?: SanctionsCheckStatus
    alivenessCheckStatus?: AlivenessCheckStatus

    fullName?() {
        return `${this.firstname} ${this.lastname}`
    }

    age?() {
        let age = undefined
        if (this.dateOfBirth) {
            const date = new Date(this.dateOfBirth)
            if (date) {
                age = differenceInYears(Date.now(), this.dateOfBirth)
            }
        }
        return age
    }

    ageAt?(aDate: Date) {
        let ageAt = undefined
        if (this.dateOfBirth) {
            ageAt = differenceInYears(aDate, this.dateOfBirth)
        }
        return ageAt
    }
}
