import {classList} from '@peachy/utility-kit-pure'
import assigmentStyles from '../../../components/PlanBuilder/PlanAssignment/PlanEmployeeAssignment.module.css'
import styles from './PlanMemberCounter.module.css'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {Component, For} from 'solid-js'

const Counter: Component<{ count: number }> = (props) => <div class={styles.member}>{`${props.count} ${props.count === 1 ? 'member' : 'members'}`}</div>

export const PlanMembersCounter = () => {
    const store = useStore()

    return <div class={classList(assigmentStyles.planEmployees, styles.planMembers)}>
        <div/>
        <For each={store.getPlanIds()}>{
            (id) => {
                const livesCount = store.getLivesForPlan(id)?.length
                return livesCount ? <Counter count={livesCount}/> : <div/>
            }
        }
        </For>
    </div>
}
