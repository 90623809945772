import {QuoteApiDefinition, QuoteClientApi, QuoteLifeRequest, QuoteLifeResponse} from '@peachy/quote-pure'
import {makeApiClient} from '@peachy/core-domain-client'
import {Draft, dump, Valid} from '@peachy/utility-kit-pure'
import {createQuoteStateMachine, QuoteState, QuoteStateMachine} from './QuoteStateMachine'
import {Signer} from '@aws-amplify/core'
import {QuoteStore} from './QuoteStore'

export type IQuoteValidator = {
    assertIsValidDraft(quote: Draft<QuoteLifeRequest>): asserts quote is Valid<QuoteLifeRequest>
    isValidDraft(quote: Draft<QuoteLifeRequest>): quote is Valid<QuoteLifeRequest>
    isValidFinal(quote: Draft<QuoteLifeRequest>): quote is Valid<QuoteLifeRequest>
}

export type IQuoteServiceClient = {
    tryQuote(): void
    editQuote(): void
    updateDraft(quote: QuoteLifeRequest): void
    updateQuote(quote: QuoteLifeRequest): void

    currentQuoteResponse(): QuoteLifeResponse
    currentQuoteRequest(): Draft<QuoteLifeRequest>

    currentQuoteState(): any

    isBusy(): boolean
    isValid(): boolean

    canTryQuote(): boolean
    canEditQuote(): boolean
    canUpdateQuote(): boolean
    canUpdateDraft(): boolean
}

export class QuoteServiceClient implements IQuoteServiceClient {

    private quoteStateMachine: QuoteStateMachine
    private readonly quoteStore = new QuoteStore()
    private readonly quoteApi: Promise<QuoteClientApi>

    constructor(servicePatchUri: string, private quoteValidator: IQuoteValidator) {
        this.quoteApi = makeApiClient(QuoteApiDefinition, servicePatchUri, Signer)
        this.quoteStateMachine = createQuoteStateMachine()
    }

    updateDraft(quote: Draft<QuoteLifeRequest>) {
        this.quoteStateMachine.updateDraft(() => {
            this.quoteStore.updateQuoteRequest(quote)
        })
    }

    tryQuote() {
        this.quoteStateMachine.tryQuote(() => {
            this.validateDraft()
        })
    }

    editQuote() {
        this.quoteStateMachine.editQuote()
    }

    updateQuote(quote: Draft<QuoteLifeRequest>) {
        this.quoteStateMachine.updateQuote(() => {
            this.quoteStore.updateQuoteRequest(quote)
            this.validateDraft()
        })
    }


    private validateDraft() {
        if (this.quoteValidator.isValidDraft(this.quoteStore.quoteRequest())) {
            this.quoteStateMachine.validDraft(() => this.requestQuote())
        } else {
            this.quoteStateMachine.invalidDraft()
        }
    }

    private requestQuote() {

        const quoteRequest = this.quoteStore.quoteRequest()

        dump(quoteRequest)

        this.quoteValidator.assertIsValidDraft(quoteRequest)

        this.quoteStore.updateQuoteResponse(null)

        this.quoteApi.then(async (api) => {
            try {
            const quoteResponse = await api.getQuote(quoteRequest)
                if (quoteResponse.quoteRequestId === quoteRequest.quoteRequestId) {
                    this.quoteStore.updateQuoteResponse(quoteResponse)
                    this.quoteStateMachine.quoteSuccess(() => {
                        this.validateFinal()
                    })
                }
            } catch (e) {
                console.error('Error requesting quote', e)
                this.quoteStateMachine.quoteFailure()
            }
        })
    }


    private validateFinal() {
        if (this.quoteValidator.isValidFinal(this.quoteStore.quoteRequest())) {
            this.quoteStateMachine.validFinal()
        } else {
            this.quoteStateMachine.invalidFinal()
        }
    }

    currentQuoteRequest() {
        return this.quoteStore.quoteRequest()
    }

    currentQuoteResponse() {
        return this.quoteStore.quoteResponse()
    }

    currentQuoteState(): QuoteState {
        return this.quoteStateMachine.currentState()
    }

    isBusy() {
        return this.quoteStateMachine.hasTag('busy')
    }

    isValid() {
        return this.quoteStateMachine.isInState('Valid')
    }

    canTryQuote() {
        return this.quoteStateMachine.canHandle('tryQuote')
    }

    canUpdateQuote() {
        return this.quoteStateMachine.canHandle('updateQuote')
    }

    canUpdateDraft() {
        return this.quoteStateMachine.canHandle('updateDraft')
    }

    canEditQuote() {
        return this.quoteStateMachine.canHandle('editQuote')
    }
}
