import {enumerateKeys, NOOP} from '@peachy/utility-kit-pure'

/* todo: decide what to do with this module */

const IconClasses = {
    eyeOpen: 'fa-light fa-eye-slash',
    eyeShut: 'fa-light fa-eye'
} as const

export type IconProps = {
    icon: IconName
    onClick?: (e: MouseEvent) => void
}


export function Icon(props: IconProps) {
    const onClick = props.onClick ?? NOOP
    return <i onClick={onClick} style={{cursor: props.onClick ? 'pointer' : 'unset'}} class={IconClasses[props.icon]}></i>
}


export type IconName = keyof typeof IconClasses

export const Icons = enumerateKeys(IconClasses)