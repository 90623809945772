import {Component, createSignal, Show} from 'solid-js'
import {createToggleSignal} from '@peachy/client-kit'
import {PopupListSelector} from '@peachy/client-kit'
import styles from './Limit.module.css'

type LimitOption = {
    label: string
    value: string
}

type LimitProps = {
    label?: string
    value?: string
    values: LimitOption[]
    onUpdate: (value: string) => void
    isEditable: boolean
}

export const Limit: Component<LimitProps> = (props) => {
    const [currentValue, setCurrentValue] = createSignal(props.value)
    const [showList, toggleList] = createToggleSignal(false)

    const displayList = props.values.map(limitOption => limitOption.label)

    const getOptionByValue = (value: string): LimitOption => props.values.find(v => v.value === value)
    const getOptionByLabel = (label: string): LimitOption => props.values.find(v => v.label === label)

    let limitRef: HTMLParagraphElement

    const onSelect = (label: string) => {
        setCurrentValue(getOptionByLabel(label).value)
        toggleList()
        props.onUpdate(currentValue())
    }

    const displayValue = () => getOptionByValue(currentValue()).label

    return <>
        <Show when={props.label}>
            <label>{props.label}</label>
        </Show>
        <Show when={props.isEditable} fallback={<>{displayValue()}</>}>
            <p class={styles.text} ref={limitRef} onclick={toggleList}>{displayValue()} <i class="fa-solid fa-caret-down"/></p>
            <PopupListSelector
                list={displayList}
                onSelect={onSelect}
                onDismiss={toggleList}
                selection={currentValue()}
                enabled={showList()}
                locatorElement={limitRef}
                appearance={{
                    list: styles.list,
                    listItem: styles.listItem,
                    selectedItem: styles.selectedItem
                }}
            />
        </Show>
    </>
}
