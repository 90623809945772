import {Optional} from '@peachy/utility-kit-pure'
import {isEmpty} from 'lodash-es'
import {batch} from 'solid-js'
import {createStore, Store} from 'solid-js/store'

type  Id = string | number

type HasId = { id: Id }

type PartialItem<T extends HasId> = Partial<T>

type ListItemPatch<T extends HasId> = PartialItem<T> & Pick<T, 'id'>

export type ListStoreApi<T extends HasId> = {
    patch(patch: ListItemPatch<T>): void
    patchById(id: T['id'], changes: PartialItem<T>, eager?: boolean): void
    push(item: T): void
    pushOrPatch(patch: T): void
    pushIfNew(item: T): void
    removeById(id: T['id']): T
    set(newList: T[]): void
    get(id: T['id']): Optional<T>
    hasExactlyOneItem(): boolean
    last(): Optional<T>
    isEmpty(): boolean
    reduce<R>(reducer: (acc: R, item: T) => R, init?: R): R
}

export function createListStore<T extends HasId>(init: T[] = []): [Store<T[]>, ListStoreApi<T>] {

    const [items, setItems] = createStore(init)

    const api = {
        patch(patch: ListItemPatch<T>, pushIfNecessary = false) {
            let patched = false
            setItems(it => {
                patched = it.id === patch.id
                return patched
            }, patch as T)
            if (pushIfNecessary && !patched) {
                this.push(patch)
            }
        },

        pushOrPatch(item: T) {
            this.patch(item, true)
        },

        pushIfNew(item: T) {
            batch(() => {
                const alreadyExists = !!items.find(it => it.id === item.id)
                if (!alreadyExists) {
                    this.push(item)
                }
            })
        },

        patchById(id: Id, changes: PartialItem<T>) {
            this.patch({id, ...changes})
        },

        push(item: T) {
            setItems(items.length, item)
        },

        removeById(id: Id) {
            let removed: T
            setItems(prev => [...prev.filter(it => {
                if (it.id === id) {
                    removed = it
                }
                return it.id !== id
            })])
            return removed
        },

        get(id: Id) {
            return items.find(it => it.id === id)
        },

        set(newList: T[]) {
            setItems(newList)
        },

        hasExactlyOneItem() {
            return items.length === 1
        },

        last() {
            return items[items.length - 1]
        },

        isEmpty() {
            return isEmpty(items)
        },

        reduce<R>(reducer: (acc: R, item: T) => R, init?: R) {
            return items.reduce(reducer, init)
        }
    }

    return [items, api]

}
