import pageStyles from '../BrokerQuote.module.css'
import styles from './CompanyDetails.module.css'
import {CompanyEmail} from './CompanyEmail'
import {CompanyTypeahead} from './CompanyTypeahead'

export const CompanyDetails = () => {
    return (
        <div class={pageStyles.details}>
            <h4>Company details</h4>

            <div class={styles.content}>
                <CompanyTypeahead/>
                <CompanyEmail/>
            </div>
        </div>
    )
}