import {useNavigate} from '@solidjs/router'
import {Button} from '../../../global/forms/Button'
import {Component, createSignal, ParentComponent, Show} from 'solid-js'
import {clearAccountData, getSubscription} from '../../../providers/AccountProvider'
import {Price} from '../../../components/FormattedPrice/Price'
import {getQuoteStore} from '../../../providers/AccountSubscription/session/stores'
import {FormattedPrice} from '../../../components/FormattedPrice/FormattedPrice'
import {Card} from '../../../components/FormattedPrice/Card/Card'
import styles from './Summary.module.css'
import SummaryOfChanges from './SummaryOfChanges'
import {MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {useSubscriptionService} from '../../../providers/AppServiceProvider'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {useAppContext} from '../../../providers/AppContextProvider'

export default () => {
    const navigate = useNavigate()
    const subscriptionService = useSubscriptionService()
    const store = useStore()
    const appContext = useAppContext()

    const [submissionError, setSubmissionError] = createSignal<string>()
    const onBack = () => navigate('/plans')

    const onUpdate = async (event) => {
        event.preventDefault()
        appContext.setLoading(true)

        setSubmissionError(null)

        try {
            await subscriptionService.alterSmeSubscription(store.getSubscriptionRequest() as MrSubscriptionQuoteResponse)
            store.reset()
            clearAccountData()
            navigate('/plans', {replace: true})

        } catch (error) {
            console.error(error)
            setSubmissionError('Error: Unable to alter account. Please contact us if this continues.')
        } finally {
            appContext.setLoading(false)
        }
    }


    return (
        <div class={styles.Summary}>
            <header>
                <hgroup>
                    <h4>Almost done!</h4>
                    <h4>Please review your plan changes</h4>
                </hgroup>
            </header>

            <Section title="Summary of changes">
                <SummaryOfChanges/>
            </Section>

            <Section title="Cost">
                <Cost/>
            </Section>

            <Section>
                <h3>Are you happy with these changes?</h3>
                <div class={styles.ButtonContainer}>
                    <Button theme="secondary" onClick={onBack}>Back to edit</Button>
                    <Button theme="primary" onClick={onUpdate}>Update plan</Button>
                </div>
            </Section>

        </div>
    )
}

const Section: ParentComponent<{ title?: string }> = (props) => (
    <section class={styles.Content}>
        <Show when={props.title}><h5>{props.title}</h5></Show>
        {props.children}
    </section>
)

const Cost: Component = () => {
    const {totalMonthlyPremium: currentPremium} = getSubscription()
    // @ts-ignore
    const {totalMonthlyPremium: newPremium} = getQuoteStore().get()

    return <>
        <p>You currently pay <b><Price value={currentPremium} /> per month</b>. Following these plan changes and provided you don't make further changes, your monthly payment will now be:</p>
        <div class={styles.Premiums}>
            <Card class={styles.NewPremium}>
                <FormattedPrice price={newPremium}/>
            </Card>
        </div>
        <div class={styles.PremiumText}>
            <p>These changes will take effect immediately once you confirm below.</p>
            <p>Your <b>next payment</b> will be adjusted on a pro rata basis to account for changes made during the month.</p>
            <p>Any <b>new members</b> on plan will receive an email with their plan documents and instructions to download the Peachy app.</p>
            <p>Anyone who has been <b>removed</b> from the plan will receive an email notifying them that their plan has been cancelled.</p>
        </div>
    </>
}
