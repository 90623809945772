import {Show} from 'solid-js'
import {hasPreviousPage, previousPage} from './PageRouter'
import {Button} from '../../global/forms/Button'

export function PreviousButton () {
    return <Show when={hasPreviousPage()} fallback={<span/>}>
        <Button theme="secondary" role="link" onClick={previousPage}>
            <i class="fal fa-angle-left"/>
        </Button>
    </Show>
}