import pageStyles from '../BrokerQuote.module.css'
import {Commission} from './Commission'
import {ProductType} from './ProductType'

export const ProductDetails = () => {
    return (
        <div class={pageStyles.details}>
            <h4>Product details</h4>
            <div class={pageStyles.rowStructure}>
                <ProductType />
                {/* TODO - Hide after PR */}
                <Commission />
            </div>
        </div>
    )
}