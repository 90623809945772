import {createEffect, createSignal, onCleanup, ParentProps} from 'solid-js'
import {Dynamic} from 'solid-js/web'
import styles from './Expander.module.css'
import {classList} from '@peachy/utility-kit-pure'


export type ExpanderState = 'OPEN' | 'CLOSED'

export type ExpanderProps = ParentProps & {
    class?: string
    expanded: boolean
    latencyMillis?: number
    tag: keyof JSX.IntrinsicElements
    onTransitionStart?: (fromState: ExpanderState, toState: ExpanderState) => void
    onTransitionEnd?: (fromState: ExpanderState, toState: ExpanderState) => void
}


export function Expander(props: ExpanderProps) {

    let containerElement: HTMLElement

    const [renderChildren, setRenderChildren] = createSignal<boolean>(props.expanded)

    const onStart = (evt: TransitionEvent) => {
        if (props.expanded) {
            props.onTransitionStart?.('CLOSED', 'OPEN')
        } else {
            props.onTransitionStart?.('OPEN', 'CLOSED')
        }
    }
    const onEnd = (evt: TransitionEvent) => {
        if (!props.expanded) {
            setRenderChildren(false)
            props.onTransitionEnd?.('OPEN', 'CLOSED')
        } else {
            containerElement.style.maxHeight = 'unset'
            props.onTransitionEnd?.('CLOSED', 'OPEN')
        }
    }

    createEffect(() => {
        containerElement.addEventListener('transitionrun', onStart)
        containerElement.addEventListener('transitionend', onEnd)
        onCleanup(() => {
            containerElement.removeEventListener('transitionrun', onStart)
            containerElement.removeEventListener('transitionend', onEnd)
        })
    })

    createEffect(() => {
        containerElement.style.transition = `max-height ${props.latencyMillis ?? 200}ms`;
    })

    createEffect(() => {
        if (props.expanded) {
            setRenderChildren(true)
            requestAnimationFrame(() =>
                containerElement.style.maxHeight = containerElement.scrollHeight + 'px'
            )
        } else {
            containerElement.style.maxHeight = containerElement.scrollHeight + 'px'
            requestAnimationFrame(() =>
                containerElement.style.maxHeight = null
            )
        }
    })

    const classes = classList(styles.Expander, props.class)

    return (
        <Dynamic ref={containerElement} component={props.tag} class={classes}>
            {renderChildren() && props.children}
        </Dynamic>
    )
}