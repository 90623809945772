import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {createContext, useContext} from 'solid-js'

type AddressChange = {
    [policyId: string]: boolean
}

/*
    Keep track if the address has been changed from an empty/valid address
 */
export class AddressChangeStore {
    private addressChanges: Store<AddressChange>
    private setAddressChanges: SetStoreFunction<AddressChange>

    constructor() {
        [this.addressChanges, this.setAddressChanges] = createStore<AddressChange>()
    }

    public hasAddressChanged(id: string): boolean {
        return this.addressChanges[id]
    }

    public updateAddressChange(id: string, change: boolean) {
        this.setAddressChanges(id, change)
    }

    public deleteAddressChange(id: string) {
        this.setAddressChanges(id, undefined)
    }
}

const AddressChangeStoreContext = createContext<AddressChangeStore>()

export const AddressChangeStoreProvider = (props) => {
    return (
        <AddressChangeStoreContext.Provider value={new AddressChangeStore()}>
            {props.children}
        </AddressChangeStoreContext.Provider>
    )
}

export const useAddressChangeStore = (): AddressChangeStore => useContext(AddressChangeStoreContext)