export const __WORKSPACE_NAME = '@peachy/geo-location-client'

export * from './src/GeoLocationServiceClient'
        import * as me from '@peachy/geo-location-client'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/comp-kit/geo-location/client'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/geo-location-client'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    