import {DateTime, Pence} from '../../some-types'


export class MrPaymentEvent {

    stripeEventId: string
    stripeInvoiceId: string

    status: MrPaymentStatus

    occurredAt: DateTime

    amountCollected: Pence

    attempt: number
    refund?: {
        reason: string
    }
    failure?: {
        reason: string
        code: string
        nextAttemptAt?: number
    }
}

export type MrPaymentStatus =
    | 'FAILED'
    | 'RECEIVED'
    | 'REFUNDED'
