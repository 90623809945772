import {PaymentFailed} from './models/payments/PaymentFailed'
import {PaymentReceived} from './models/payments/PaymentReceived'
import {PaymentRefunded} from './models/payments/PaymentRefunded'

import {SubscriptionCreated} from './models/SubscriptionCreated'

import {RawStripeEvent} from './models/RawStripeEvent'
import {keysOf} from '@peachy/utility-kit-pure'
import {PolicyReposRequest} from './models/PolicyReposRequest'
import {PolicyReposCreated} from './models/PolicyReposCreated'
import {SubscriptionCancelled} from './models/SubscriptionCancelled'
import {CancelSubscriptionRequest} from './models/CancelSubscriptionRequest'
import {IdCheckEvent} from './models/kyc/IdCheckEvent'
import {IdCheckFailed} from './models/kyc/IdCheckFailed'
import {IdCheckPassed} from './models/kyc/IdCheckPassed'

import {IndividualSanctionsCheckRequest} from './models/kyc/IndividualSanctionsCheckRequest'
import {SanctionsCheckEvent} from './models/kyc/SanctionsCheckEvent'
import {SanctionsCheckFailed} from './models/kyc/SanctionsCheckFailed'
import {SanctionsCheckPassed} from './models/kyc/SanctionsCheckPassed'
import {IntercomEventRequest} from './models/IntercomEventRequest'
import {SanctionsCheckFlagged} from './models/kyc/SanctionsCheckFlagged'
import {IdCheckFlagged} from './models/kyc/IdCheckFlagged'
import {IdCheckRequest} from './models/kyc/IdCheckRequest'
import {OwnerSanctionsCheckRequest} from './models/kyc/company/OwnerSanctionsCheckRequest'


import {CompanyPolicyDocSendRequest} from './models/docs/activation/CompanyPolicyDocSendRequest'
import {IndividualPolicyDocSendRequest} from './models/docs/activation/IndividualPolicyDocSendRequest'
import {MemberPolicyDocSendRequest} from './models/docs/activation/MemberPolicyDocSendRequest'

import {CompanyCancellationEmailSendRequest} from './models/docs/cancellation/CompanyCancellationEmailSendRequest'
import {IndividualCancellationEmailSendRequest} from './models/docs/cancellation/IndividualCancellationEmailSendRequest'
import {MemberCancellationEmailSendRequest} from './models/docs/cancellation/MemberCancellationEmailSendRequest'

import {SubscriptionActivatedNotification} from './models/notifications/SubscriptionActivatedNotification'
import {SubscriptionAlteredNotification} from './models/notifications/SubscriptionAlteredNotification'
import {SubscriptionCancelledNotification} from './models/notifications/SubscriptionCancelledNotification'

import {LifeActivatedNotification} from './models/notifications/LifeActivatedNotification'
import {LifeAlteredNotification} from './models/notifications/LifeAlteredNotification'
import {LifeCancelledNotification} from './models/notifications/LifeCancelledNotification'
import {
    IndividualPolicyAlterationDocSendRequest
} from './models/docs/alteration/IndividualPolicyDocAlterationSendRequest'
import {CompanyPolicyAlterationDocSendRequest} from './models/docs/alteration/CompanyPolicyAlterationDocSendRequest'
import {MemberPolicyAlterationDocSendRequest} from './models/docs/alteration/MemberPolicyDocAlterationSendRequest'
import {LifeRepoUpsertRequest} from './models/life-repo/LifeRepoUpsertRequest'
import {SendIndividualPolicyDocReIssueRequest} from './models/docs/reissue/SendIndividualPolicyDocReIssueRequest'


export const Events = {

    SubscriptionActivatedNotification,
    SubscriptionAlteredNotification,
    SubscriptionCancelledNotification,

    LifeActivatedNotification,
    LifeAlteredNotification,
    LifeCancelledNotification,

    IndividualCancellationEmailSendRequest,
    CompanyCancellationEmailSendRequest,
    MemberCancellationEmailSendRequest,

    IndividualPolicyDocSendRequest,
    CompanyPolicyDocSendRequest,
    MemberPolicyDocSendRequest,

    IndividualPolicyAlterationDocSendRequest,
    CompanyPolicyAlterationDocSendRequest,
    MemberPolicyAlterationDocSendRequest,
    SendIndividualPolicyDocReIssueRequest,

    LifeRepoUpsertRequest,


    //-----------

    PolicyReposRequest,
    PolicyReposCreated,


    IdCheckEvent,
    IdCheckFlagged,
    IdCheckFailed,
    IdCheckPassed,
    IdCheckRequest,

    SanctionsCheckEvent,
    SanctionsCheckFailed,
    SanctionsCheckFlagged,
    SanctionsCheckPassed,
    IndividualSanctionsCheckRequest,

    OwnerSanctionsCheckRequest,

    IntercomEventRequest,

    RawStripeEvent,
    PaymentFailed,
    PaymentReceived,
    PaymentRefunded,
    SubscriptionCreated,
    CancelSubscriptionRequest,
    SubscriptionCancelled,


} as const




export type EventType = keyof typeof Events
export const EventTypes = keysOf<EventType>()

export type EventTypeFrom<ET extends EventType> = InstanceType<(typeof Events)[ET]>
