import {For, JSXElement, Signal} from 'solid-js'
import {keys} from 'lodash-es'

type TabMap<Tabs extends string> = {
    [tab in Tabs]: JSXElement
}


export type TabsProps<Tabs extends string> = {
    currentTab: Signal<Tabs>
    children: () => TabMap<Tabs>
}


export function Tabs<Tabs extends string>(props: TabsProps<Tabs>) {

    console.log('TABS')

    const tabMap = props.children()
    const tabs = keys(tabMap)

    const [currentTab, setCurrentTab] = props.currentTab

    return (
        <>
            <ul>
                <For each={tabs}>{(tab: Tabs, i) => {
                    return (
                        <li data-is-selected={tab === currentTab()} onClick={() => setCurrentTab(t => tab)}>{tab}</li>
                    )
                }
                }</For>
            </ul>
            <div>
                {tabMap[currentTab()]}
            </div>
        </>
    )

}
