import {parse} from 'csv-parse/browser/esm/sync'

export type Row = {[key:string]:string}

export function parseValues(data: string): Row[] {
    const records = parse(data) as string[][]
    const headers = records[0]
    const rows = records.splice(1)
    const results: Row[] = []

    rows.forEach(row => {
        const rowValues: Row = {}
        for (let i = 0; i < headers.length; i++) {
            rowValues[headers[i]] = row[i]
        }
        results.push(rowValues)
    })
    return results
}