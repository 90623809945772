import {API} from '@aws-amplify/api'
import {Company, toClass} from '@peachy/core-domain-pure'
import {LookupCompanyRequest, LookupCompanyResponse} from '@peachy/lookup-pure'

export class CompanyLookupService {
    constructor(readonly api: typeof API, readonly path: string = '/companies') {}

    async lookup(request: LookupCompanyRequest): Promise<LookupCompanyResponse> {

        console.log('Lookup company', request)

        const httpRequest = {
            headers: {},
            body: request,
        }
        try {
            const la = await this.api.post(LookupApiName, `${this.path}/search`, httpRequest)

            console.log('got company', la)

            return la

        } catch (e) {
            console.log('Arfer fugdake', e)
        }

    }

    async fetch(companyId: string): Promise<Company> {
        console.log('Fetch company', companyId)
        const httpRequest = {
            headers: {},
            body: {companyId},
        }
        const response = await this.api.post(LookupApiName, `${this.path}/get`, httpRequest)
        return toClass(response, Company)
    }
}

export class CharityLookupService extends CompanyLookupService {
    constructor(readonly api: typeof API, readonly path: string = '/charities') {
        super(api, path)
    }
}

const LookupApiName = 'LookupApi'
