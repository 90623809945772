import {LifeType, LifeTypes, MrPlan, MrPolicy, toClass} from '@peachy/core-domain-pure'
import {classList, currencyFromPence, KeyMapped, readableDate} from '@peachy/utility-kit-pure'
import {useNavigate} from '@solidjs/router'
import sortBy from 'lodash-es/sortBy'
import {Component, createMemo, For, Show} from 'solid-js'
import {getPolicies, getSubscription} from '../../../providers/AccountProvider'
import {Button} from '../../../global/forms/Button'
import tableStyles from '../DashboardTable.module.css'
import styles from './Members.module.css'


export const Members = () => {
    const { plans } = getSubscription()
    const navigate = useNavigate()

    const orderPolicies = () => sortBy(getPolicies().map(p => toClass(p, MrPolicy)), [
        p => p.getPrimaryLife().lastname,
        p => p.getPrimaryLife().firstname,
        'startDate'
    ])

    return (
        <div class={styles.listContainer}>
            <div class={styles.members}>
                <section class={styles.header}>
                    <h2>Members</h2>
                    {/* TODO In the figma design but not MVP */}
                    {/*<div class={styles.searchBar}>*/}
                    {/*    <i class="icon-search"/>*/}
                    {/*    <TextBox value={''} onChange={NOOP}/>*/}
                    {/*</div>*/}
                </section>

                <table class={classList(tableStyles.table, styles.membersTable)}>
                    <thead>
                        <tr>
                            {/* Empty header */}
                            <th/>
                            <th>First Name</th>
                            <th>Surname</th>
                            <th>Member type</th>
                            <th>Plan type</th>
                            <th>Monthly premium</th>
                            <th>Date issued</th>
                            {/* Edit */}
                            <th/>
                            {/* Empty header */}
                            <th/>
                        </tr>
                    </thead>

                    <tbody>
                        <For each={orderPolicies()}>
                            {(policy) => <MemberRow policy={toClass(policy, MrPolicy)} plans={plans} />}
                        </For>
                    </tbody>
                </table>
                <Button theme="dark" class={styles.manageButton} onClick={() => navigate('/plans', { replace: true })}>Manage members</Button>
            </div>
        </div>
    )
}

const MemberRow: Component<{ policy: MrPolicy, plans: KeyMapped<MrPlan> }> = (props) => {
    const primaryLife = props.policy.getPrimaryLife()
    const secondaryLife = props.policy?.getSecondaryLife()
    const dependantLives = props.policy?.getDependents()

    const primaryCss = () => secondaryLife || dependantLives.length ? styles.firstRow : ''
    const secondaryCss = () => dependantLives ? styles.middleRows : styles.lastRow
    const dependantCss = (index: number) => index === dependantLives.length - 1 ? styles.lastRow : styles.middleRows

    const lifeType = (lifeType: LifeType) => {
        switch (lifeType) {
            case LifeTypes.PRIMARY: return 'Employee'
            case LifeTypes.SECONDARY: return 'Secondary'
            case LifeTypes.DEPENDANT: return 'Dependant'
        }
    }

    const findPlan = (lifePlanId: string) => props.plans[lifePlanId]?.name ?? 'N/A'

    const policyDate = createMemo(() => readableDate(props.policy.startDate))

    return (<>
        <tr class={primaryCss()}>
            <td />
            <td>{primaryLife.firstname}</td>
            <td>{primaryLife.lastname}</td>
            <td>{lifeType(primaryLife.type)}</td>
            <td>{findPlan(primaryLife.planId)}</td>
            <td>{currencyFromPence(primaryLife.totalMonthlyPremium)}</td>
            <td>{policyDate()}</td>
            <td><a class={styles.edit} onClick={() => Intercom('showNewMessage', 'I want to change member details')} target={'_blank'}>Edit</a></td>
            <td />
        </tr>
        <Show when={secondaryLife}>
            <tr class={secondaryCss()}>
                <td />
                <td>{secondaryLife.firstname}</td>
                <td>{secondaryLife.lastname}</td>
                <td>{lifeType(secondaryLife.type)}</td>
                <td>{findPlan(secondaryLife.planId)}</td>
                <td>{currencyFromPence(secondaryLife.totalMonthlyPremium)}</td>
                <td>{policyDate()}</td>
                <td />
                <td />
            </tr>
        </Show>
        <Show when={dependantLives}>
            <For each={dependantLives}>
                {(dependantLife, index) => <tr class={dependantCss(index())}>
                    <td />
                    <td>{dependantLife.firstname}</td>
                    <td>{dependantLife.lastname}</td>
                    <td>{lifeType(dependantLife.type)}</td>
                    <td>{findPlan(dependantLife.planId)}</td>
                    <td>{currencyFromPence(dependantLife.totalMonthlyPremium)}</td>
                    <td>{policyDate()}</td>
                    <td />
                    <td />
                </tr>
                }
            </For>
        </Show>

        {/* Row Gap - TODO find a nicer way. Tricky due to secondary/dependant member rows */}
        <th class={styles.rowGap} />
    </>
    )
}
