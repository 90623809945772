import {Page} from '../../Page'
import {DashboardHeader} from '../Header/DashboardHeader/DashboardHeader'
import {BrokerDashboardHeader} from '../Header/BrokerDashboardHeader/BrokerDashboardHeader'
import {getAccountSubscription, getSubscriptionRequest} from '../../providers/AccountProvider'
import {useLocation, useNavigate} from '@solidjs/router'
import {useIamService} from '../../providers/AppServiceProvider'
import {MyQuotesPath} from '../../App'

export default function Home (props) {

    const navigate = useNavigate(), iamService = useIamService(), location = useLocation()

    // not signed in - forward to the quote page
    if (!iamService.isSignedIn()) {
        navigate('/quote', { replace: true })
        return
    }

    // in progress quote - forward to the quote plans page
    if (!getAccountSubscription() && getSubscriptionRequest()) {
        navigate('/quote/plans', { replace: true })
        return
    }
    // cancelled subscription - forward to the billing page
    if(getAccountSubscription()?.subscription.status === 'CANCELLED') {
        navigate('/billing', { replace: true })
        return
    }

    return (
        <Page header={location.pathname === MyQuotesPath ? <BrokerDashboardHeader /> : <DashboardHeader />}>
            {props.children}
        </Page>
    )
}
