import {Spinner} from '@peachy/client-kit'
import {AccountSubscriptionDto, MrPolicy, MrSubscriptionQuoteResponse, toClass} from '@peachy/core-domain-pure'
import {Draft, values} from '@peachy/utility-kit-pure'
import {createContext, createEffect, createSignal, Match, ParentComponent, Switch, useContext} from 'solid-js'
import {loadActiveSubscription} from '../components/Home/loadActiveSubscription'
import {loadSubscriptionQuote} from '../components/Home/loadSubscriptionQuote'
import {useIamService} from './AppServiceProvider'

type AccountData = {
    accountSubscription?: AccountSubscriptionDto
    draftSubscription?: Draft<MrSubscriptionQuoteResponse>
}

const AccountContext = createContext<AccountData>()
const [accountData, setAccountData] = createSignal<AccountData>()

async function loadData (): Promise<AccountData> {
    const [accountSub, draftSub] = await Promise.all([
        loadActiveSubscription(),
        loadSubscriptionQuote()
    ])

    return {
        accountSubscription: accountSub,
        draftSubscription: !(accountSub) ? draftSub : undefined // only set draft subscription if we don't have an an active subscription
    }
}

export const AccountProvider: ParentComponent = (props) => {
    const iamService = useIamService()

    createEffect(() => {
        if (iamService.isSignedIn() && !accountData()) {
            loadData().then(setAccountData)
        }
    })

    return <>
        <Switch fallback={<Spinner isShown={true}/>}>
            <Match when={!iamService.isSignedIn()}>
                {props.children}
            </Match>
            <Match when={iamService.isSignedIn() && accountData()}>
                <AccountContext.Provider value={ accountData() }>
                    {props.children}
                </AccountContext.Provider>
            </Match>
        </Switch>
    </>
}

export const getAccountContext = () => useContext(AccountContext)

export function getAccountSubscription() {
    const accountData = getAccountContext()
    return accountData?.accountSubscription
}

export function getAccount() {
    return getAccountSubscription()?.account
}

export function getPolicies() {
    return values(getAccountSubscription()?.subscription.policies).map(p => toClass(p, MrPolicy))
}

export function getPrimaryLives() {
    return getPolicies()?.map(policy => policy.getPrimaryLife())
}

export function getLives() {
    return getPolicies()?.flatMap(policy => Object.values(policy.lives))
}

export function getSubscription() {
    return getAccountSubscription()?.subscription
}

export function getPolicy(policyId: string) {
    return getPolicies()?.find(p => p.id === policyId)
}

export function getSubscriptionRequest() {
    const accountData = getAccountContext()
    return accountData?.draftSubscription
}

export function clearAccountData () {
    setAccountData() //clear account data
}
