import {Company} from '@peachy/core-domain-pure'
import {Component} from 'solid-js'
import {InfoPanelAddressDetails} from '../pure/InfoPanelAddressDetails'
import {SubHeader} from '../pure/SubHeader'
import {InfoPanel} from '../pure/InfoPanel'

export const YourPlanCompanyDetailsPanel: Component<{ company: Company }> = (props) => {
    return (
        <InfoPanel>
            <SubHeader heading={props.company?.name} onEditClick={() => Intercom('showNewMessage', 'I want to change my company information')} />
            <p>Company number <span>{props.company?.id}</span></p>
            <InfoPanelAddressDetails company={props.company} />
        </InfoPanel>
    )
}
