import {BenefitType as CoreBenefitType} from '@peachy/core-domain-pure'
import {QuoteBenefitType} from '../core/QuoteBenefitType'


export function toQuoteBenefitType(benefitType: CoreBenefitType): QuoteBenefitType {
    switch (benefitType) {
        case 'VIRTUAL_GP':
            return 'REMOTE_CARE'
        case 'MENTAL_HEALTH_IN':
            return 'MENTAL_HEALTH'
        case 'MENTAL_HEALTH_OUT':
            return 'MENTAL_HEALTH'
        case 'DENTAL':
            return 'DENTAL_OPTICAL'
        case 'OPTICAL':
            return 'DENTAL_OPTICAL'
        default:
            return benefitType as QuoteBenefitType
    }
}

export function toCoreBenefitType(BenefitType: QuoteBenefitType): CoreBenefitType[] {
    switch (BenefitType) {
        case 'DENTAL_OPTICAL':
            return ['DENTAL', 'OPTICAL']
        case 'MENTAL_HEALTH':
            return ['MENTAL_HEALTH_IN', 'MENTAL_HEALTH_OUT']
        case 'REMOTE_CARE':
            return ['VIRTUAL_GP']
        default:
            return [BenefitType] as CoreBenefitType[]
    }
}
