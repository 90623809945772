import styles from './TouchScreen.module.css'

export function TouchScreen() {

    Intercom('update', {"hide_default_launcher": true})

    return (
        <div class={styles.TouchScreen}>
            <p>We’re sorry, but this page is not yet built to work on small/touch screens. We’re working on it, but in the meantime please use a desktop computer to buy your Peachy plan.</p>
        </div>
    )
}