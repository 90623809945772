import 'reflect-metadata'
import {plainToInstance} from 'class-transformer'
import {Blueprint} from './mixin-kit'

export type Class<T> = new () => T

export type Merge<Base, Layer> = Omit<Base, keyof Layer> & Layer
//
// export function merge<L extends object, R extends object>(left: Class<L>, right: Class<R>): Class<Merge<L, R>> {
//     const merged = class {}
//     mixin(merged, left, right)
//     return merged as Class<Merge<L, R>>
// }








export function mixin<To, From>(target: Class<To>, base: Class<From>): Class<Merge<From, To>> {
    Object.getOwnPropertyNames(base.prototype).forEach(name => {
        if (!(name in target.prototype)) {
            Object.defineProperty(target.prototype, name, Object.getOwnPropertyDescriptor(base.prototype, name))
        }
    })
    return target as Class<Merge<From, To>>
}

export function makeMaker<T>(C: Class<T>): (blueprint: Blueprint<T>) => T {
    return (draft: Blueprint<T>) => {
        return plainToInstance(C, draft)
    }
}
