import {safeStringify} from './json-kit'

export function dump(o: any, tag?: string) {
    if (tag) console.log(tag)
    console.log(asDoc(o))
    console.log('')
}

// TODO find usages and see if we need json util
export function asDoc(o: any) {
    return JSON.stringify(o, null, 2)
}

type ErrorMetadata = {
    name?: string,
    message?: string
}

export interface Logger {
    debug: (message?: any, ...optionalParams: any[]) => void
    error: (error: any, metadata?: ErrorMetadata) => void
}

export function buildLogger({onDebug, onError}: {onDebug?: (message?: any, ...optionalParams: any[]) => void, onError?: (error: any, metadata?: ErrorMetadata) => void} = {}) {
    return {
        debug: (message?: any, ...optionalParams: any[]) => {
            try {
                const debugMessage = '[DEBUG] ' + safeStringify(message)
                const stringifiedRest = optionalParams.map(it => safeStringify(it))
                console.log(debugMessage, ...stringifiedRest)
                onDebug?.(debugMessage, ...stringifiedRest)
            } catch (e) {
                // er...
                console.error('encountered an error while logging')
                console.error(e)
            }
        },
        // should generate unique error uuids in logs and shown to user
        error: (error: any, metadata?: ErrorMetadata) => {
            try {
                const {name, message} = metadata ?? {}
                const definitelyAnError = (error instanceof Error) ? error : new Error(safeStringify(error)); //semicolon required
                (name || message) && console.error('[ERROR]', name, message, definitelyAnError)
                console.error(definitelyAnError)
                onError?.(definitelyAnError, metadata)
            } catch (e) {
                // er...
                console.error('encountered an error while logging an error, the irony!')
                console.error(e)
            }
        }
    }
}
