import {useNavigate} from '@solidjs/router'
import {StripeElements} from '@stripe/stripe-js'
import {createEffect, createSignal, onMount, Show} from 'solid-js'
import {Button} from '../../../global/forms/Button'
import {useAppContext} from '../../../providers/AppContextProvider'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {StripeCard} from './stripe/StripeCard'
import {Address, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {ConfirmedPaymentIntent, PaymentCustomer} from '@peachy/payments-pure'
import {BillingDetails} from './billing/BillingDetails'
import {usePaymentsService, useSubscriptionService} from '../../../providers/AppServiceProvider'
import {ErrorMessages} from '../../../global/errors/ValidationErrors'
import {format} from 'date-fns'
import styles from './CapturePaymentDetails.module.css'
import {IntercomBrowserClient} from '@peachy/intercom-browser-client'
import {API} from '@aws-amplify/api'
import {Auth} from '@aws-amplify/auth'
import {clearAccountData} from '../../../providers/AccountProvider'


export function CapturePaymentDetails() {
    const store = useStore()
    const paymentsService = usePaymentsService()
    const subscriptionService = useSubscriptionService()
    const appContext = useAppContext()
    const navigate = useNavigate()
    const intercom = new IntercomBrowserClient(API, Auth)

    const [stripeElements, setStripeElements] = createSignal<StripeElements>()
    const [stripeErrors, setStripeErrors] = createSignal<string[]>()
    const [submissionError, setSubmissionError] = createSignal<string>()

    const allErrors = () => {
        const paymentErrors = (stripeErrors() ?? [])
        const errors = paymentErrors.concat(submissionError() ?? [])
        // console.log('errors', errors)
        return errors
    }

    const clearErrors = () => setSubmissionError()
    const hasErrors = () => allErrors().length > 0

    onMount(async () => {
        await paymentsService.createPaymentIntent()
        setStripeElements(paymentsService.getStripe().elements(fontStyle))
    })

    createEffect(() => {
        appContext.setLoading(stripeElements() ? false : true)
    })

    const confirmCardPayment = async (): Promise<ConfirmedPaymentIntent> => {
        const account = store.getAccount()
        const paymentDetails = store.getPaymentDetails()

        const address = (paymentDetails?.hasSeparateBillingAddress) ? paymentDetails?.billingAddress : account.company?.address
        const paymentCustomer = { fullName: account.company?.name, email: account.user?.email } as PaymentCustomer

        return await paymentsService.confirmCardPaymentIntentV2(stripeElements(), address as Address, paymentCustomer)
    }

    const processPaymentDetails = async () => {
        try {
            const confirmedPaymentIntent = await confirmCardPayment()

            store.updatePayment({
                name: confirmedPaymentIntent.customer.fullName,
                email: confirmedPaymentIntent.customer.email,
                stripePaymentId: confirmedPaymentIntent.paymentMethodId
            })

        } catch (error) {
            console.error('STRIPE ERROR!', error)
            throw Error(error.message)
        }
    }

    const processSubscription = async () => {
        try {
            const subscriptionRequest = store.getSubscriptionRequest()
            subscriptionRequest.intercomVisitorId = intercom.getVisitorId()
            await subscriptionService.activateSmeSubscription(subscriptionRequest as MrSubscriptionQuoteResponse)
            clearAccountData()
        } catch (error) {
            // start a new payment intent if the subscription request fails (enables us be able to retry the payment setup)
            await paymentsService.createPaymentIntent(false)
            throw Error('Error: Unable to setup account. Please contact us if this continues.')
        }
    }

    const onSubmit = async (event) => {
        event.preventDefault()
        appContext.setLoading(true)

        clearErrors()

        try {
            await processPaymentDetails()
            await processSubscription()

            store.reset()

            navigate('/welcome', { replace: true })
        } catch (error) {
            setSubmissionError(error.message)
            throw Error(error.message)
        } finally {
            appContext.setLoading(false)
        }
    }

    let stripeCardRef, billingDetailsRef

    const isValid = () => stripeCardRef.isValid() && billingDetailsRef.isValid()

    return (
        <div>
            <Show when={stripeElements()}>
                <form id="payment-form" class={styles.form}>
                    <h4>Let's set up your payment</h4>
                    <p>Please use a company account. The first payment will be taken on {format(store.getSubscriptionStartDate(), 'd MMM yyyy')}.</p>

                    <Show when={hasErrors()}>
                        <ErrorMessages errors={allErrors()} />
                    </Show>

                    <StripeCard stripeElements={stripeElements()} ref={stripeCardRef} setErrors={setStripeErrors} />

                    <BillingDetails ref={billingDetailsRef} />

                    <Button theme="primary" disabled={!isValid()} onClick={onSubmit}>Buy now</Button>
                </form>
            </Show>
        </div>

    )
}

//TODO: move somewhere generic
const fontStyle = {
    fonts: [{
        cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap'
    }]
}
