import {Api, defineApi, postOf} from '@peachy/core-domain-pure'
import {QuoteLifeRequest, QuoteLifeResponse} from '@peachy/quote-pure'

export const QuoteApiDefinition = defineApi('LambdaQuoteApi')
    .withActions({
        getQuote: postOf(QuoteLifeRequest).fromAnyone().willReturn(QuoteLifeResponse)
    })


export type QuoteClientApi = Api<typeof QuoteApiDefinition>
