import {keysOf} from '@peachy/utility-kit-pure'
import {Pence} from '../some-types'


export const BenefitTypes = keysOf<BenefitType>()
export type BenefitType =
    | 'VIRTUAL_GP'
    | 'ADVICE_COUNSELLING'
    | 'HOSPITAL_CARE'
    | 'CONSULTATIONS_DIAGNOSTICS'
    | 'THERAPIES'
    | 'MENTAL_HEALTH_IN'
    | 'MENTAL_HEALTH_OUT'
    | 'DENTAL'
    | 'OPTICAL'


export const mandatoryBenefits = [
    BenefitTypes.MENTAL_HEALTH_IN,
    BenefitTypes.MENTAL_HEALTH_OUT,
    BenefitTypes.VIRTUAL_GP
]

export class PlanBenefit {
    type: BenefitType
    limit?: Pence
}

export type KeyedBy<K extends string, T = unknown> = {
    [_ in K]?: T
}


const planBenefitDisplayNames = {
    [BenefitTypes.ADVICE_COUNSELLING]: 'Advice & Counselling',
    [BenefitTypes.CONSULTATIONS_DIAGNOSTICS]: 'Consultations & Diagnostics',
    [BenefitTypes.DENTAL]: 'Dental',
    [BenefitTypes.HOSPITAL_CARE]: 'Hospital Care',
    [BenefitTypes.MENTAL_HEALTH_IN]: 'Mental Health',
    [BenefitTypes.MENTAL_HEALTH_OUT]: 'Mental Health',
    [BenefitTypes.OPTICAL]: 'Optical',
    [BenefitTypes.THERAPIES]: 'Therapies',
    [BenefitTypes.VIRTUAL_GP]: 'Virtual GP'
}

export const displayName = (benefitType: BenefitType) => planBenefitDisplayNames[benefitType]

export const nonPricedBenefits: BenefitType[] = [
    BenefitTypes.OPTICAL,
    BenefitTypes.MENTAL_HEALTH_OUT,
]


export const defaultBenefitLimits = {
    [BenefitTypes.HOSPITAL_CARE]: 1000000,
    [BenefitTypes.CONSULTATIONS_DIAGNOSTICS]: 100000,
    [BenefitTypes.THERAPIES]: 250000,
    [BenefitTypes.MENTAL_HEALTH_IN]: 2000000,
    [BenefitTypes.MENTAL_HEALTH_OUT]: 150000,
    [BenefitTypes.DENTAL]: 20000,
} satisfies KeyedBy<BenefitType, number>


export const availableBenefitLimits = {
    [BenefitTypes.HOSPITAL_CARE]: [
        1000000,
        2000000,
        3000000,
        4000000,
        5000000,
        6000000,
        7000000,
        8000000,
        9000000,
        10000000,
        11000000,
        12000000,
        13000000,
        14000000,
        15000000,
        16000000,
        17000000,
        18000000,
        19000000,
        20000000,
        21000000,
        22000000,
        23000000,
        24000000,
        25000000,
        26000000,
        27000000,
        28000000,
        29000000,
        30000000,
        31000000,
        32000000,
        33000000,
        34000000,
        35000000,
    ],
    [BenefitTypes.CONSULTATIONS_DIAGNOSTICS]: [
        50000,
        75000,
        100000,
        125000,
        150000,
        175000,
        200000,
        225000,
        250000,
        300000,
        350000,
        400000,
        450000,
        500000,
        1000000,
        1500000,
        2000000,
        2500000,
        3000000,
        3500000,
        4000000,
        4500000,
        5000000,
        5500000,
        6000000,
        6500000,
        7000000,
        7500000,
        8000000,
        8500000,
        9000000,
        9500000,
        10000000
    ]
} satisfies KeyedBy<BenefitType, readonly number[]>
