import medicalProceduresIndex from './medical-procedures-idx.json'
import {Document} from 'flexsearch'

export type MedicalProcedureSearchResultItem = {
    id: string
    doc: {
        description: string
        type: 'procedure' | 'consultation' | 'other'
    }
}

export class MedicalProcedureLookupService {

    private readonly index

    constructor() {
        this.index = new Document(medicalProceduresIndex.indexDefinition)
        Object.entries(medicalProceduresIndex.indexData).forEach(([key, data]) => this.index.import(key, data))
    }

    search(stem: string, options?: {limit?: number, minStem?: number}): MedicalProcedureSearchResultItem[] {
        let results: MedicalProcedureSearchResultItem[] = []
        const limit = options?.limit ?? 25
        if (stem.length >= (options?.minStem ?? 0)) {
            // @ts-ignore
            results = this.index.search(stem, limit, {pluck: 'description', suggest: true, enrich: true})
        }
        // limit passed to flexsearch doens't seem to work so...
        return results.slice(0, limit)
    }
}