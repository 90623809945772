import {classList} from '@peachy/utility-kit-pure'
import styles from './Toggle.module.css'

export interface ToggleProps {
    class?: string
    state: boolean
    setState: (b: boolean) => void
    onClick?: (e) => void
}

export const Toggle = (props: ToggleProps) => {
    const classes = classList(styles.toggle, props.class)

    const onKeyDown = (e) => {
        if (['ArrowDown', 'ArrowRight'].includes(e.key)) {
            props.setState(true)
        } else if (['ArrowUp', 'ArrowLeft'].includes(e.key)) {
            props.setState(false)
        }
    }

    const onClick = (e) => props.onClick && props.onClick(e)

    return (
        <input class={classes}
               onKeyDown={onKeyDown}
               onChange={() => props.setState(!props.state)}
               onClick={onClick}
               checked={props.state}
               type="checkbox"/>
    )
}

