import {Component} from 'solid-js'
import {StripeElementChangeEvent, StripeElements} from '@stripe/stripe-js'
import {CardCvc, CardExpiry, CardNumber} from '../../../../components/Payment/CardElements/CardElements'
import {createStore} from 'solid-js/store'
import {Dictionary} from '@peachy/utility-kit-pure'

interface StripeCardProps {
    stripeElements: StripeElements
    ref: any
    setErrors: (errorMessages: string[]) => void
}

export const StripeCard: Component<StripeCardProps> = (props) => {
    const stripeElements = props.stripeElements

    const [fieldValidation, setFieldValidation] = createStore<Dictionary<StripeElementChangeEvent>>({
        cardNumber: undefined,
        cardExpiry: undefined,
        cardCvc: undefined
    })

    const getFieldValidationValues = () => Object.values(fieldValidation)
    const isValid = () => getFieldValidationValues().every(event => event?.complete)

    //when a change occurs to a field, capture the field validation from stripe
    const onChange = (event: StripeElementChangeEvent) => {
        setFieldValidation(event.elementType, event)

        const localErrors = getFieldValidationValues().map(event => event?.error?.message).filter(message => Boolean(message))
        props.setErrors(localErrors)
    }

    props.ref({ isValid })

    return (
        <>
            <fieldset>
                <CardNumber stripeElements={stripeElements} onChange={onChange} placeholder="Card number" />
            </fieldset>

            <fieldset>
                <CardExpiry stripeElements={stripeElements} onChange={onChange} placeholder="Expiry MM/YY" />
                <CardCvc stripeElements={stripeElements} onChange={onChange} placeholder="Security code" />
            </fieldset>
        </>
    )
}
