import {Auth} from '@aws-amplify/auth'
import {dump} from '@peachy/utility-kit-pure'

type AuthType = typeof Auth

export type IamApi = {
    extractError: (e: any) => AuthenticationError
} & Pick<AuthType,
    'currentAuthenticatedUser' |
    'changePassword' |
    'completeNewPassword' |
    'confirmSignIn' |
    'confirmSignUp' |
    'verifyUserAttribute' |
    'verifyUserAttributeSubmit' |
    'updateUserAttributes' |
    'forgotPassword' |
    'forgotPasswordSubmit' |
    'resendSignUp' |
    'signIn' |
    'signOut' |
    'signUp'>


export function createIamApi(): IamApi {
    const aug = Auth as any
    aug.extractError = extractError
    return aug
}

function isTempPasswordExpireError(message: string): boolean {
    return message.includes('Temporary password has expired and must be reset by an administrator')
}

export type AuthenticationError =
    'PasswordPolicyException' | 'PasswordExpired' | 'InvalidCredentials' | 'ExceededMaxAttempts' | 'UserNotFound' | 'Unknown'

function extractError(e: any): AuthenticationError {
    console.log('Received raw error', e.toString())
    dump(e)

    const code = e.code
    const message = e.toString()

    switch (code) {
        case 'InvalidPasswordException':
            return 'PasswordPolicyException'
        case 'NotAuthorizedException':
            return isTempPasswordExpireError(message) ? 'PasswordExpired' : 'InvalidCredentials'
        case 'LimitExceededException':
            return 'ExceededMaxAttempts'
        case 'UserNotFoundException':
            return 'UserNotFound'
        default:
            return 'Unknown'
    }
}