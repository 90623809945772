import {ItemDropdown} from './ItemDropdown/ItemDropdown'

export const Commission = () => {

    const commissions = ['10% monthly', '20% monthly', '30% monthly']

    return (
        <ItemDropdown
            title="Commission Structure"
            list={commissions}
            initialValue={commissions[0]}
            placeholder="Select commission type"
            mapLabel={v => v}
        />
    )
}