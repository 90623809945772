import {PolicyType} from '../core/PolicyType'
import {IndividualQuoteRequest} from '../core/IndividualQuoteRequest'
import {IndividualQuoteResponse} from '../core/IndividualQuoteResponse'

// TODO: to be removed once production Q&B is moved to v2 and QuoteLife style quote request, this just enables us
//       to move to v2 with old style request should we desire

export type LegacyQuoteRequest = {
    quoteRequestId: string
    version?: number
    type?: PolicyType
    groupSize?: number
    primaryIndividual?: IndividualQuoteRequest
    secondaryIndividual?: IndividualQuoteRequest
    children?: IndividualQuoteRequest[]
    groupedIndividuals?: IndividualQuoteRequest[]
    includeSecondaryIndividual?: boolean
    annualBilling?: boolean
}

export type LegacyQuoteResponse = {
    quoteRequestId: string
    quoteResponseId: string
    timestamp: number
    primaryIndividual: IndividualQuoteResponse
    secondaryIndividual: IndividualQuoteResponse
    children: IndividualQuoteResponse[]
    groupedIndividuals?: IndividualQuoteResponse[]
    totalPolicyPrice: number
}