import {AccountSubscriptionDto, MrSubscriptionQuoteRequest, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'

import {ContentBucketResponse, ReissuePolicyDocResponse, SubscriptionApiGateway} from '@peachy/subscription-pure'

export type SubscriptionService = SubscriptionApiGateway

export function createSubscriptionService(api: SubscriptionApiGateway): SubscriptionService {

    return {

        async getSmeQuote(subRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.getSmeQuote(subRequest)
            } catch (e) {
                console.error(`Problem quoting sme subscription`, e)
                throw Error(e.toString())
            }
        },

        async activateSmeSubscription(subRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.activateSmeSubscription(subRequest)
            } catch (e) {
                console.error(`Problem creating sme subscription`, e)
                throw Error(e.toString())
            }
        },
        async alterSmeSubscription(subRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.alterSmeSubscription(subRequest)
            } catch (e) {
                console.error(`Problem altering sme subscription`, e)
                throw Error(e.toString())
            }
        },
        async saveSmeDraftQuote({ quoteId }, subRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.saveSmeDraftQuote({ quoteId }, subRequest)
            } catch (e) {
                console.error(`Problem saving a draft sme subscription`, e)
                throw Error(e.toString())
            }
        },
        async loadSmeDraftQuote({ quoteId }): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.loadSmeDraftQuote({ quoteId })
            } catch (e) {
                console.log(`No quote found for id: ${quoteId}`)
                // do nothing as this is expected - will just return nothing
            }
        },
        async loadSmeDraftQuotes(): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.loadSmeDraftQuotes()
            } catch (e) {
                console.log('No found quotes')
                // do nothing as this is expected - will just return nothing
            }
        },
        async latestSmeDraftQuote(): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.latestSmeDraftQuote()
            } catch (e) {
                console.log('No quote found')
                // do nothing as this is expected - will just return nothing
            }
        },

        async loadActiveSmeSubscription(): Promise<AccountSubscriptionDto> {
            try {
                return await api.loadActiveSmeSubscription()
            } catch (e) {
                // do nothing as this is expected - will just return nothing
                console.log('No Active Subscription')
            }
        },

        async adminLoadActiveSubscription({accountId, subscriptionId}) : Promise<AccountSubscriptionDto> {
            try {
                return await api.adminLoadActiveSubscription({accountId, subscriptionId})
            } catch (e) {
                // do nothing as this is expected - will just return nothing
                console.log('No Active Subscription')
            }
        },

        async reissueCompanyPolicyDoc(reissueRequest): Promise<ReissuePolicyDocResponse> {
            try {
                return await api.reissueCompanyPolicyDoc(reissueRequest)
            } catch (e) {
                // do nothing as this is expected - will just return nothing
            }
        },
        async activateIndividualSubscription(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.activateIndividualSubscription(subscriptionRequest)
            } catch (e) {
                console.error(`Problem activating individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async alterIndividualSubscription(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.alterIndividualSubscription(subscriptionRequest)
            } catch (e) {
                console.error(`Problem altering individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async getIndividualQuote(subscriptionRequest: MrSubscriptionQuoteRequest): Promise<MrSubscriptionQuoteResponse> {
            try {
                return await api.getIndividualQuote(subscriptionRequest)
            } catch (e) {
                console.error(`Problem quoting individual subscription`, e)
                throw Error(e.toString())
            }
        },

        async resolveContentBucket(): Promise<ContentBucketResponse> {
            try {
                return await api.resolveContentBucket()
            } catch (e) {
                console.error(`Problem resolving content bucket`, e)
                throw Error(e.toString())
            }
        },
    }
}
