

export type LifecycleStatus =
    | 'ACTIVE'
    | 'CANCELLED'


export type HasLifecycleStatus = {
    status: LifecycleStatus
    startDate?: number
    endDate?: number
    cancellationReason?: string
    nextRenewalDate?:number
    lastRenewalDate?:number
}




export function isCancelled(item: HasLifecycleStatus) {
    return item.status === 'CANCELLED'
}
export function isActive(item: HasLifecycleStatus) {
    return item.status === 'ACTIVE'
}
