import styles from './PremiumDetails.module.css'
import {SubHeader} from './SubHeader'
import {FormattedPrice} from '../../../components/FormattedPrice/FormattedPrice'
import {getQuoteStore} from '../../../providers/AccountSubscription/session/stores'
import {Show} from 'solid-js'
import {Card} from '../../../components/FormattedPrice/Card/Card'

export const PremiumDetails = () => {
    const { totalMonthlyPremium, totalAnnualPremium } = getQuoteStore().get()

    return (
        <>
            <SubHeader heading={'Cost'}/>

            <div class={styles.PremiumDetails}>
                <Show when={totalMonthlyPremium} fallback={<p>No Monthly Quote</p>}>
                    <Card class={styles.Month}>
                        <FormattedPrice price={totalMonthlyPremium} />
                    </Card>
                </Show>
                <Show when={totalAnnualPremium} fallback={<p>No Annual Quote</p>}>
                    <Card class={styles.Year}>
                        <FormattedPrice price={totalAnnualPremium} frequency="yearly"/>
                    </Card>
                </Show>
            </div>
        </>
    )
}
