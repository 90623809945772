import {Button} from '../../global/forms/Button'
import styles from './ManageMembers.module.css'
import {createToggleSignal} from '@peachy/client-kit'
import {useStore} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {ManageMembersModal} from './modal/ManageMembersModal'

export const ManageMembers = () => {
    const [isOpen, toggleIsOpen] = createToggleSignal(false)

    const store = useStore()

    const onOpen = () => {
        store.setFullValidation(false)
        store.startEditing()
        toggleIsOpen()
    }

    const onClose = () => {
        store.stopEditing()
        toggleIsOpen()
    }

    return (
        <>
            <Button theme="dark" class={styles.manageButton} onClick={onOpen}>Manage members</Button>
            <ManageMembersModal isOpen={isOpen()} onDismiss={onClose}/>
        </>
    )
}
