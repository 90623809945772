import {DatePickerStore} from './DatePickerStore'
import {DatePickerHeader} from './DatePickerHeader/DatePickerHeader'
import {Modal, ModalProps} from '../Modal/Modal'
import styles from './DatePicker.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {MonthTable} from './MonthTable/MonthTable'
import {createEffect} from 'solid-js'

export type DatePickerProps = Omit<ModalProps, 'class'> & {
    minDate: Date
    maxDate: Date
    selectedDate: Date
    appearance?: DatePickerAppearance
    headerText: string,
    submitText: string,
    onSelect: (selected: Date) => void
}

export function DatePicker(props: DatePickerProps) {

    const store = new DatePickerStore(props.minDate, props.maxDate, props.selectedDate)
    const onDismiss = () => {
        store.resetSelection()
        props.onDismiss?.()
    }

    const onSelect = () => {
        props.onSelect(store.selectedDate)
        props.onDismiss?.()
    }

    createEffect(() => {
        store.minDate = props.minDate
        store.maxDate = props.maxDate
    })

    const classes = classList(styles.DatePicker, props.appearance?.modal)
    return (
        <Modal isOpen={props.isOpen} onDismiss={onDismiss} class={classes} locatorElement={props.locatorElement}>
            <DatePickerHeader headerText={props.headerText} store={store} appearance={props.appearance}/>
            <MonthTable store={store} appearance={props.appearance}/>
            <button onClick={onSelect} class={props.appearance.submit}>{props.submitText}</button>
        </Modal>
    )
}


export type DatePickerAppearance = {
    modal?: string,
    header?: string
    nudgeSelectors?: string
    nudgeSelector?: string
    nudgeSelectorText?: string
    nudgeSelectorModal?: string
    nudgeSelectorList?: string
    nudgeSelectorListItem?: string
    nudgeSelectorListItemSelected?: string
    nudgeLeft?: string
    nudgeRight?: string
    monthTable?: string
    weekNames?: string
    allDays?: string
    inMonth?: string
    outMonth?: string
    weekday?: string
    weekend?: string
    today?: string
    selected?: string
    submit?: string
    disabled?: string
}



