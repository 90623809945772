import {Stripe} from 'stripe'
import {PaymentIntent} from './domain'

export async function createPaymentIntent(stripe: Stripe): Promise<PaymentIntent> {

    const paymentIntent = await stripe.setupIntents.create({
        usage: 'off_session',
    })

    console.log('Created payment intent', paymentIntent)

    return {
        clientSecret: paymentIntent.client_secret,
        intentId: paymentIntent.id,
        status: paymentIntent.status
    }
}

export async function abandonPaymentIntent(intentId: string, stripe: Stripe): Promise<PaymentIntent> {

    const paymentIntent = await stripe.setupIntents.cancel(intentId, {
        cancellation_reason: 'abandoned'
    })

    console.log('Abandoned payment intent', paymentIntent)

    return {
        intentId: paymentIntent.id,
        clientSecret: paymentIntent.client_secret,
        status: paymentIntent.status
    }
}
