import {enumerate} from '@peachy/utility-kit-pure'
import {QuoteBenefitType, QuoteBenefitTypes} from './QuoteBenefitType'


export const ExcessDiscounts = enumerate([
    'HOSPITAL_CARE_EXCESS_DISCOUNT',
    'CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT',
    'THERAPIES_EXCESS_DISCOUNT',
    'OVERALL_EXCESS_DISCOUNT'
] as const)

export type ExcessDiscount = keyof typeof ExcessDiscounts

export const excessDiscountNameFor = (benefitType: QuoteBenefitType) => {
    switch(benefitType) {
        case QuoteBenefitTypes.HOSPITAL_CARE: return ExcessDiscounts.HOSPITAL_CARE_EXCESS_DISCOUNT
        case QuoteBenefitTypes.CONSULTATIONS_DIAGNOSTICS: return ExcessDiscounts.CONSULTATIONS_DIAGNOSTICS_EXCESS_DISCOUNT
        case QuoteBenefitTypes.THERAPIES: return ExcessDiscounts.THERAPIES_EXCESS_DISCOUNT
        default: return null
    }
}
