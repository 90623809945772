import Stripe from 'stripe'
import {StripeEventObjectType, StripeObject, StripeObjectType} from './models/stripe-object-types'
import {StripeEventType} from './models/stripe-event-types'

export const STRIPE_KEYS_NAME = 'peachy/stripe'


export type StripeEventOf<T extends StripeObjectType> = StripeEvent & {
    data: {
        object: StripeObject<T>
    }
}



export type StripeEvent<T extends StripeEventType = undefined> =
    T extends undefined
        ? Stripe.Event &
        {
            id: string
            type: StripeEventType
            data: {
                object: StripeObject
            }
        }
        : Stripe.Event &
        {
            id: string
            type: T
            data: {
                object: StripeEventObjectType<T>
            }
        }


export function isStripeEventOfType<T extends StripeEventType>(type: T, e: StripeEvent): e is StripeEvent<T> {
    return e.type === type
}


export function hasStripeObjectType<T extends StripeObjectType>(type: T, e: StripeEvent): e is StripeEventOf<T> {
    return e.data.object.object === type
}


export async function getPromo(code: string, stripe: Stripe) {
    const promos = await stripe.promotionCodes.list({code, active: true, expand: ['data.customer']})
    const promo = promos.data[0]
    if (promo) {
        const limitedToCustomerEmail = (promo.customer as Stripe.Customer)?.email
        if (limitedToCustomerEmail) {
            promo.metadata.email_restrictions = (promo.metadata.email_restrictions ?? '') + ' ' + limitedToCustomerEmail
        }
        // don't expose customer data here!
        promo.customer = undefined
        return promo
    }
}

export async function getCoupon(id: string, stripe: Stripe) {
    return stripe.coupons.retrieve(id)
}