import {batch} from 'solid-js'
import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {BENCH_ID} from './AccountSubscriptionStore'
import {MrLife, MrPlan} from '@peachy/core-domain-pure'
import {Draft} from '@peachy/utility-kit-pure'

type PlanLifeAssignment = Record<string, string[]>

/**
 * Stores and manages all the details relating to employee orders per plan
 */
export class PlanLifeAssignmentStore {
    private planLifeAssignmentStore: Store<PlanLifeAssignment>
    private updatePlanLifeAssignmentStore: SetStoreFunction<PlanLifeAssignment>

    constructor(plans: MrPlan[], lives: Draft<MrLife>[]) {
        [this.planLifeAssignmentStore, this.updatePlanLifeAssignmentStore] = createStore<PlanLifeAssignment>(createPlanLifeAssignmentStore(plans, lives))
    }

    public addLifeOrder(planId: string, lifeId: string) {
        this.updatePlanLifeAssignmentStore(planId, (items) => items ? [lifeId].concat(items) : [lifeId])
    }

    public updateLifeOrder(fromPlanId: string, toPlanId: string, lifeId: string, orderIndex: number) {
        batch(() => {
            //remove from current plan
            this.updatePlanLifeAssignmentStore(fromPlanId, (items) => items.filter((id) => id !== lifeId))

            //add to new plan
            this.updatePlanLifeAssignmentStore(toPlanId, (items) => {
                const updatedItems = items ?? []
                return [...updatedItems.slice(0, orderIndex), lifeId, ...updatedItems.slice(orderIndex)]
            })
        })
    }

    public orderLives(planId?: string, lives?: Draft<MrLife>[]) {
        const lifeOrderForPlan = this.planLifeAssignmentStore[planId]
        const orderedLives = lifeOrderForPlan?.map(lifeId => lives.find(life => life.id === lifeId))
        return orderedLives?.filter(life => life !== undefined) //remove any lives that no longer exist
    }
}

function createPlanLifeAssignmentStore(plans: MrPlan[], lives: Draft<MrLife>[]): PlanLifeAssignment {
    const data = {
        [BENCH_ID]: lives.filter(life => !life.planId).map(life => life.id)
    }

    plans.forEach(plan => {
        const lifeIds = lives.filter(life => plan.id === life.planId).map(life => life.id)
        data[plan.id] = lifeIds
    })

    return data
}
