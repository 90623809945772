import {Draft, Valid} from '@peachy/utility-kit-pure'
import {SubscriptionQuoteStore} from './SubscriptionQuoteStore'
import {createQuoteStateMachine, QuoteState, QuoteStateMachine} from '@peachy/quote-solid-client'
import {MrSubscriptionQuoteRequest, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'

export type ISubscriptionQuoteValidator = {
    assertIsValidDraft(quote: Draft<MrSubscriptionQuoteRequest>): asserts quote is Valid<MrSubscriptionQuoteRequest>
    isValidDraft(quote: Draft<MrSubscriptionQuoteRequest>): quote is Valid<MrSubscriptionQuoteRequest>
    isValidFinal(quote: Draft<MrSubscriptionQuoteRequest>): quote is Valid<MrSubscriptionQuoteRequest>
}

export type SubscriptionQuoteServiceClient = {
    tryQuote(): void
    editQuote(): void
    updateDraft(quote: Draft<MrSubscriptionQuoteRequest>): void
    updateQuote(quote: Draft<MrSubscriptionQuoteRequest>): void

    currentQuoteResponse(): MrSubscriptionQuoteResponse
    currentQuoteRequest(): Draft<MrSubscriptionQuoteRequest>

    currentQuoteState(): any

    isBusy(): boolean
    isValid(): boolean

    canTryQuote(): boolean
    canEditQuote(): boolean
    canUpdateQuote(): boolean
    canUpdateDraft(): boolean
}




export type QuoteFunction = (request: MrSubscriptionQuoteRequest) => Promise<MrSubscriptionQuoteResponse>


export class SubscriptionQuoteClient implements SubscriptionQuoteServiceClient {

    private quoteStateMachine: QuoteStateMachine
    private readonly quoteStore = new SubscriptionQuoteStore()

    constructor(private getQuote: QuoteFunction, private quoteValidator: ISubscriptionQuoteValidator) {
        this.quoteStateMachine = createQuoteStateMachine()
    }

    updateDraft(quote: Draft<MrSubscriptionQuoteRequest>) {
        this.quoteStateMachine.updateDraft(() => {
            this.quoteStore.updateQuoteRequest(quote)
        })
    }

    tryQuote() {
        this.quoteStateMachine.tryQuote(() => {
            this.validateDraft()
        })
    }

    editQuote() {
        this.quoteStateMachine.editQuote()
    }

    updateQuote(quote: Draft<MrSubscriptionQuoteRequest>) {
        this.quoteStateMachine.updateQuote(() => {
            this.quoteStore.updateQuoteRequest(quote)
            this.validateDraft()
        })
    }


    private validateDraft() {
        if (this.quoteValidator.isValidDraft(this.quoteStore.quoteRequest())) {
            this.quoteStateMachine.validDraft(() => this.requestQuote())
        } else {
            this.quoteStateMachine.invalidDraft()
        }
    }

    private requestQuote() {

        const quoteRequest = this.quoteStore.quoteRequest()
        this.quoteValidator.assertIsValidDraft(quoteRequest)

        this.quoteStore.updateQuoteResponse(null)

        console.log('Really trying to quote!!')

        this.getQuote(quoteRequest)
            .then(quoteResponse => {
                console.log('We got a quote!!', quoteResponse)
                if (quoteResponse.quoteRequestId === quoteRequest.quoteRequestId) {

                    this.quoteStore.updateQuoteResponse(quoteResponse)
                    this.quoteStateMachine.quoteSuccess(() => {
                        this.validateFinal()
                    })
                }
            })
            .catch(reason => {
                console.error('Error requesting quote', reason)
                this.quoteStateMachine.quoteFailure()
           })

   }


    private validateFinal() {
        if (this.quoteValidator.isValidFinal(this.quoteStore.quoteRequest())) {
            this.quoteStateMachine.validFinal()
        } else {
            this.quoteStateMachine.invalidFinal()
        }
    }

    currentQuoteRequest() {
        return this.quoteStore.quoteRequest()
    }

    currentQuoteResponse() {
        return this.quoteStore.quoteResponse()
    }

    currentQuoteState(): QuoteState {
        return this.quoteStateMachine.currentState()
    }

    isBusy() {
        return this.quoteStateMachine.hasTag('busy')
    }

    isValid() {
        return this.quoteStateMachine.isInState('Valid')
    }

    canTryQuote() {
        return this.quoteStateMachine.canHandle('tryQuote')
    }

    canUpdateQuote() {
        return this.quoteStateMachine.canHandle('updateQuote')
    }

    canUpdateDraft() {
        return this.quoteStateMachine.canHandle('updateDraft')
    }

    canEditQuote() {
        return this.quoteStateMachine.canHandle('editQuote')
    }
}
