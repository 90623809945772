import tableStyles from '../ManageMembersTable.module.css'
import {useAddressChangeStore} from '../../store/AddressChangeStore'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {Component, Show} from 'solid-js'
import {LifeRowCellProps} from '../PolicyRow'

export const RemovePolicy: Component<LifeRowCellProps> = (props) => {
    const addressChangeStore = useAddressChangeStore()
    const store = useStore()

    return <td class={tableStyles.delete}>
        <Show when={store.canRemovePolicy()}>
            <button onClick={() => {
                addressChangeStore.deleteAddressChange(props.policyId)
                store.removePolicy(props.policyIndex)
            }}>
                <i class="fas fa-trash"/>
            </button>
        </Show>
    </td>
}
