export async function fetchServiceConfig<T = any>(service: string, servicePatchUri: string): Promise<T> {
    const serviceConfigUrl = `${servicePatchUri}/${service}.json`

    const response = await fetch(serviceConfigUrl, {
        headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Expires': '0'
        }}
    )

    return response.json()
}
