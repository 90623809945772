import {Button, ButtonProps} from './Button'

type FileReaderButtonProps = ButtonProps & {
    onFileRead: (data: string) => void
    accept?: string
}

export function FileReaderButton(props: FileReaderButtonProps) {
    let inputRef: HTMLInputElement

    const readFile = event => {
        const [file] = event.target.files
        const reader = new FileReader()
        reader.onload = event => props.onFileRead(event.target.result.toString())
        reader.readAsText(file)
    }

    return (
        <>
            <Button {...props} onClick={() => inputRef.click()}>{props.children}</Button>
            <input ref={inputRef} type="file" onChange={readFile} accept={props.accept} hidden/>
        </>
    )
}
