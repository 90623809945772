import styles from './Partner.module.css'

export default function () {
    return (
        <svg class={styles.partner} version="1.1" viewBox="0 0 1344.8 1200" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="M387.1,864.1c0,31.8,12.6,62.3,35.1,84.8s53,35.2,84.9,35.2h228c17.2,0,33-9.2,41.6-24s8.6-33.2,0-48s-24.4-24-41.6-24h-228 c-6.4,0-12.5-2.5-17-7s-7-10.6-7-17v-48c0-24.5,10.2-47.9,28.1-64.7c41.8-39.6,90.6-71,144-92.5c10.8-4.2,19.7-12.3,25.1-22.6 s6.8-22.2,4-33.5c-2.8-11.3-9.6-21.2-19.1-27.8c-34.1-23.4-56.5-60.3-61.5-101.3c-5-41,7.8-82.2,35.3-113.2 c27.5-30.9,66.9-48.5,108.2-48.4h5.8c36,1.5,70.2,16.5,95.8,42s40.7,59.5,42.5,95.6c2.1,43.6-15.9,85.8-48.8,114.5 c-11.5,11.6-16.2,28.3-12.7,44.2s15,29,30.3,34.6s32.5,3.1,45.5-6.8c27.1-23.6,48.5-53,62.7-86s20.7-68.8,19-104.7 c-2.3-50.2-20.3-98.4-51.5-137.8s-73.9-68.1-122.2-81.9c-48.3-13.8-99.7-12.2-147,4.8c-47.3,16.9-88.1,48.3-116.6,89.6 c-28.5,41.4-43.4,90.6-42.4,140.8s17.6,98.9,47.7,139.1c-39.6,22.6-76.4,49.7-109.7,80.9c-18.4,17.3-33.1,38.3-43.2,61.5 s-15.2,48.3-15.2,73.6L387.1,864.1L387.1,864.1z" />
            </g>
        </svg>
    )
}