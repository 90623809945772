import {MrSubscription, toClass} from '@peachy/core-domain-pure'
import {classList} from '@peachy/utility-kit-pure'
import {Component, createMemo} from 'solid-js'
import {getAccount, getSubscription} from '../../../../providers/AccountProvider'
import styles from '../InfoPanels.module.css'
import {BillingCompanyDetailsPanel} from './BillingCompanyDetailsPanel'
import {BillingPaymentDetailsPanel} from './BillingPaymentDetailsPanel'

export const BillingInfoPanels: Component = () => {
    const subscription = createMemo(() => toClass(getSubscription(), MrSubscription))

    return (
        <div class={classList(styles.infoPanels, styles.billingInfoPanels)}>
            <BillingPaymentDetailsPanel subscription={subscription()}/>
            <BillingCompanyDetailsPanel company={getAccount()?.company} billingAddress={subscription().paymentDetails?.billingAddress} />
        </div>
    )
}
