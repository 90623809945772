import {Exclusion} from './Exclusion'

export const ChronicConditions = () => {
    return (
        <Exclusion name={'Chronic conditions'}>
            <p>A chronic condition is a disease, illness or injury that has one or more of the following characteristics:</p>
            <ul>
                <li>It needs on-going or long-term monitoring through consultations, examinations, check-ups and/or tests</li>
                <li>It needs on-going or long-term control or relief of symptoms</li>
                <li>It requires your rehabilitation, or for you to be specially trained to cope with it</li>
                <li>It continues indefinitely</li>
                <li>It has no known cure</li>
                <li>It comes back or is likely to come back</li>
            </ul>

            <p>Examples of chronic conditions include diabetes, asthma and multiple sclerosis.</p>
            <p>We don't cover treatment for chronic conditions including investigations, regular monitoring or consultations under any benefit except for Virtual GP, Advice & Counselling and Dental & Optical.</p>

            <p>We will cover treatment of an acute flare-up of a chronic condition providing this is not part of the normal recurring nature of the condition and is required to return you to your state of health immediately before the acute flare-up</p>

        </Exclusion>
    )
}