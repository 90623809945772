import styles from './AddPartner.module.css'

export default function () {

    return (
        <svg class={styles.addPartner} version="1.1" viewBox="0 0 1344.79 1200" xmlns="http://www.w3.org/2000/svg">
            <g>
            <path d="m1107.12,780.08h-60v-60c0-17.15-9.15-33-24-41.57-14.85-8.57-33.15-8.57-48,0s-24,24.42-24,41.57v60h-60c-17.15,0-33,9.15-41.57,24-8.57,14.85-8.57,33.15,0,48s24.42,24,41.57,24h60v60c0,17.15,9.15,33,24,41.57,14.85,8.57,33.15,8.57,48,0s24-24.42,24-41.57v-60h60c17.15,0,33-9.15,41.57-24,8.57-14.85,8.57-33.15,0-48s-24.42-24-41.57-24" />
                <path d="m387.12,864.08c0,31.82,12.65,62.35,35.15,84.85,22.5,22.5,53.03,35.15,84.85,35.15h228c17.15,0,33-9.15,41.57-24,8.57-14.85,8.57-33.15,0-48s-24.42-24-41.57-24h-228c-6.36,0-12.47-2.53-16.97-7.03-4.5-4.5-7.03-10.6-7.03-16.97v-48c.03-24.51,10.2-47.92,28.08-64.68,41.79-39.59,90.63-70.97,144-92.52,10.82-4.25,19.73-12.29,25.07-22.62,5.34-10.33,6.75-22.24,3.98-33.53-2.78-11.29-9.56-21.19-19.09-27.85-34.11-23.36-56.53-60.26-61.55-101.3-5.02-41.04,7.83-82.25,35.29-113.16,27.46-30.91,66.88-48.52,108.22-48.35h5.76c36.04,1.55,70.21,16.51,95.79,41.95s40.72,59.53,42.45,95.57c2.1,43.62-15.9,85.81-48.84,114.48-11.47,11.62-16.25,28.27-12.68,44.2,3.57,15.93,14.99,28.96,30.32,34.58s32.46,3.07,45.48-6.79c27.12-23.56,48.55-52.96,62.71-85.98s20.65-68.82,19.01-104.7c-2.3-50.19-20.3-98.38-51.45-137.8-31.15-39.42-73.89-68.06-122.18-81.9-48.3-13.84-99.71-12.17-147.02,4.77-47.3,16.93-88.09,48.29-116.62,89.63-28.54,41.35-43.38,90.61-42.43,140.84.95,50.23,17.63,98.9,47.7,139.14-39.59,22.59-76.4,49.73-109.68,80.88-18.44,17.34-33.13,38.28-43.16,61.52-10.03,23.24-15.19,48.29-15.16,73.6v48.01Z" />
            </g>
        </svg>
    )
}   