import {createSignal} from 'solid-js'
import {IamError} from '../components/IamError'
import {PasswordPolicy} from '../components/PasswordPolicy'
import {isValidPassword} from '../../../to-abstract/validate'
import {PasswordBox} from '../../../global/forms/PasswordBox/PasswordBox'
import {useIamService} from '../../../providers/AppServiceProvider'
import {Button} from '../../../global/forms/Button'

export function CompleteNewPassword() {
    const iamService = useIamService()

    const [password, setPassword] = createSignal('')

    const setNewPassword = () => {
        if (isValidPassword(password())) iamService.completeNewPassword(password())
    }

    return <>
        <h3>Change password</h3>
        <IamError/>
        <PasswordBox
            value={password()}
            onInput={(e) => setPassword(e.currentTarget.value)}
            onEnter={setNewPassword}
            placeholder={'new password'}
        />
        <PasswordPolicy/>

        <Button theme="primary" onClick={setNewPassword} disabled={!isValidPassword(password())}>Change</Button>
        <a onClick={iamService.cancelNewPassword}>Cancel &rarr; </a>
    </>

}
