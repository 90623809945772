import {useNavigate} from '@solidjs/router'
import {useStore} from '../../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {createToggleSignal} from '@peachy/client-kit'
import {Button} from '../../../../global/forms/Button'
import {ManageMembersModal} from '../../../../components/ManageMembers/modal/ManageMembersModal'
import styles from './QuoteFooter.module.css'

export const LetsGoButton = () => {
    const [isEmployeeModalOpen, toggleEmployeeModal] = createToggleSignal(false)

    const store = useStore(), navigate = useNavigate()

    const onOpen = () => {
        store.setFullValidation(true)

        if (!store.canPurchase()) {
            store.startEditing()
            toggleEmployeeModal()
            return
        }

        navigate('/plans/summary')
    }

    const onClose = () => {
        toggleEmployeeModal()
        store.stopEditing()
    }

    return (
        <span class={styles.ButtonContainer}>
            <Button theme="light" onClick={onOpen} class={styles.ProceedButton}>Let's go →</Button>
            <ManageMembersModal isOpen={isEmployeeModalOpen()} onDismiss={onClose} />
        </span>
    )
}
