import {CancellationType} from '../../domain/models/CancellationType'
import {AccountType} from '../../domain/models/Account'


export type SubscriptionEventData = {
    accountId: string
    accountType: AccountType
    subscriptionId: string
}

export type LifeEventData = SubscriptionEventData & {
    policyId: string
    lifeId: string
    cognitoId: string
}

export type CancellationEventData = {
    cancellationType: CancellationType
    cancellationReason: string
}


export type LifeTurnover = {
    addedLifeIds: string[]
    removedLifeIds: string[]
}
