import {enumerate} from '@peachy/utility-kit-pure'
import {ItemDropdown} from './ItemDropdown/ItemDropdown'

const ProductTypes = enumerate(['CORPORATE_PMI'] as const)
type ProductType = keyof typeof ProductTypes

// Placeholder
class Product {
    constructor(readonly type: ProductType, readonly displayName: string) { }
}

export const ProductType = () => {

    const products = [
        new Product(ProductTypes['CORPORATE_PMI'], 'Corporate PMI')
    ]

    return (
        <ItemDropdown
            title="Product type"
            list={products}
            initialValue={products[0]}
            placeholder="Select product type"
            mapLabel={(product: Product) => product.displayName}
        />
    )
}