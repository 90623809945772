import {Component} from 'solid-js'
import {currencyFromPence} from '@peachy/utility-kit-pure'
import {InfoPanelNextPaymentDate} from '../pure/InfoPanelNextPaymentDate'
import {InfoPanelPlanRenewalDate} from '../pure/InfoPanelPlanRenewalDate'
import {MrSubscription} from '@peachy/core-domain-pure'
import {InfoPanel} from '../pure/InfoPanel'

export const BillingPaymentDetailsPanel: Component<{ subscription: MrSubscription }> = (props) => {
    return (
        <InfoPanel>
            <h5>{currencyFromPence(props.subscription.totalMonthlyPremium)}</h5>
            <InfoPanelNextPaymentDate nextPaymentDate={props.subscription.getNextPaymentDate()} />
            <InfoPanelPlanRenewalDate startDate={new Date(props.subscription.startDate)} />
        </InfoPanel>
    )
}
