import {Component, createMemo} from 'solid-js'
import styles from '../InfoPanels.module.css'
import {classList} from '@peachy/utility-kit-pure'
import {YourPlanPolicyDetailsPanel} from './YourPlanPolicyDetailsPanel'
import {YourPlanPaymentDetailsPanel} from './YourPlanPaymentDetailsPanel'
import {YourPlanCompanyDetailsPanel} from './YourPlanCompanyDetailsPanel'
import {getAccount, getAccountSubscription, getPolicies, getSubscription} from '../../../../providers/AccountProvider'
import {MrSubscription, toClass} from '@peachy/core-domain-pure'

export const YourPlanInfoPanels: Component<{ class?: string }> = (props) => {
    const subscription = createMemo(() => toClass(getSubscription(), MrSubscription))

    return (
        <div class={classList(styles.infoPanels, styles.yourPlanInfoPanels)}>
            <YourPlanPolicyDetailsPanel subscription={subscription()} policies={getPolicies()} />
            <YourPlanPaymentDetailsPanel
                nextPaymentDate={subscription().getNextPaymentDate()}
                totalMonthlyPremium={getAccountSubscription().subscription.totalMonthlyPremium} />
            <YourPlanCompanyDetailsPanel company={getAccount()?.company} />
        </div>
    )
}
