export type Bookmark = string

export const ZERO_BOOKMARK: Bookmark = '0-0'

export const bookmarkAt = (moment: number, index = 0) => `${moment}-${index}`

export const sortBookmarks = (bookmarks: Bookmark[]) =>
    bookmarks
        .filter((b): b is Bookmark => !!b)
        .map(splitBookmark)
        .sort(compareRankedBookmarks)
        .map(r => r.bookmark)

export const maxBookmark = (bookmarks: Bookmark[]) =>
    bookmarks
        .filter((b): b is Bookmark => !!b)
        .map(splitBookmark)
        .reduce(maxRankedBookmark).bookmark


// -------------------
type RankedBookmark = { bookmark: string, timestamp: number, index: number }

export function compareBookmarks(a: Bookmark, b: Bookmark) {
    return compareRankedBookmarks(splitBookmark(a), splitBookmark(b))
}


export function getBookmarkProvider() {
    let lastBookmark = {
        timestamp: Date.now(),
        index: 0
    }
    return () => {
        const now = Date.now()
        if (lastBookmark.timestamp < now) {
            lastBookmark = {
                timestamp: now,
                index: 0
            }
        } else {
            lastBookmark = {
                timestamp: lastBookmark.timestamp,
                index: lastBookmark.index + 1
            }
        }
        return `${lastBookmark.timestamp}-${lastBookmark.index}`
    }
}


export const splitBookmark = (bookmark: Bookmark): RankedBookmark => {
    const [timestamp, index] = bookmark.split('-').map(n => +n)
    return {bookmark, timestamp, index}
}


const maxRankedBookmark = (a: RankedBookmark, b: RankedBookmark) => {
    if (!a) return b
    if (!b) return a
    if (a.timestamp == b.timestamp) return a.index > b.index ? a : b
    return a.timestamp > b.timestamp ? a : b
}

const compareRankedBookmarks = (a: RankedBookmark, b: RankedBookmark) =>
    compareNumbers(a.timestamp, b.timestamp)
    || compareNumbers(a.index, b.index)


const compareNumbers = (a: number, b: number) =>
    Math.sign(a - b)
