import {User} from '../User'
import {Company} from '../Company'
import {AccountType, AccountTypes} from '../Account'

import {KeyMapped} from '@peachy/utility-kit-pure'
import Required from '../../validation/validators/Required'
import IsEmail from '../../validation/validators/IsEmail'
import {Type} from 'class-transformer'
import {MrSubscription} from './MrSubscription'
import {HasLifecycleStatus, LifecycleStatus} from './LifecycleStatus'


export class MrAccount implements HasLifecycleStatus {
    id: string
    type: AccountType

    status: LifecycleStatus
    startDate?: number
    endDate?: number
    cancellationReason?: string

    @Required()
    name: string
    @Required()
    contactName: string
    @Required()
    @IsEmail()
    contactEmail: string

    @Type(() => User)
    @Required<MrAccount>('', ({instance}) => instance.type === AccountTypes.COMPANY)
    user?: User

    @Type(() => Company)
    @Required<MrAccount>('Please enter a valid company', ({instance}) => instance.type === AccountTypes.COMPANY)
    company?: Company

    stripeCustomerId?: string
    subscriptions: KeyMapped<MrSubscription>
}
