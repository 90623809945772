import {JSXElement} from 'solid-js'
import {format} from 'date-fns'
import {NULL} from './TreeNode'
import {isArray, isBoolean, isDateObject, isNullish, isNumber, isObject, isString} from '@peachy/utility-kit-pure'


export type Tag = string | number

export type PromiseOrValue<T> = T | Promise<T>

export type TreeResolver<V = unknown, N = unknown> = {
    hasChildren: (value: V) => boolean
    resolveChildren: (value: V) => PromiseOrValue<N>
    displayValue: (value: V, node: N, path:Tag[], showChildren?: boolean) => JSXElement

    onMetaClick?: (value: V, node: N, path:Tag[], element: HTMLElement) => void
    onAltClick?: (value: V, node: N, path:Tag[], element: HTMLElement) => void
    onCtrlClick?: (value: V, node: N, path:Tag[], element: HTMLElement) => void
    onShiftClick?: (value: V, node: N, path:Tag[], element: HTMLElement) => void
}


export const SimpleTreeResolver: TreeResolver = {
    hasChildren(value: unknown) {
        if (isNullish(value)) return false
        if (isArray(value)) return !!value.length
        if (isDateObject(value)) return false
        if (isObject(value)) return !!Object.keys(value).length
        return false
    },
    resolveChildren(value: unknown) {
        return Promise.resolve(value)
    },
    displayValue(value: unknown, node: unknown, path:Tag[], showChildren = false) {
        if (isNullish(value)) return NULL
        if (isString(value)) return `'${value}'`
        if (isBoolean(value) || isNumber(value)) return value.toString()
        if (isDateObject(value)) return format(value, 'dd/MM/yyyy')

        if (isArray(value)) {
            if (!value.length) {
                return '[]'
            } else if (showChildren) {
                return '['
            } else {
                return '[..]'
            }
        }

        if (isObject(value)) {
            if (!Object.keys(value).length) {
                return '{}'
            } else if (showChildren) {
                return '{'
            } else {
                return '{..}'
            }
        }

        return 'NO DISPLAY VALUE'
    },
}
