import {createToggleSignal} from '@peachy/client-kit'
import {createSignal, Show} from 'solid-js'
import {PopupListSelector} from '../../../../../components/PopupListSelector/PopupListSelector'
import pageStyles from '../../BrokerQuote.module.css'
import styles from './ItemDropdown.module.css'

type ItemDropdown<T> = {
    title?: string,
    list: T[],
    initialValue?: T,
    onSelect?: () => void,
    mapLabel: (value: T) => string
    placeholder?: string
}

export const ItemDropdown = <T extends unknown>(props: ItemDropdown<T>) => {

    const [currentValue, setCurrentValue] = createSignal(props.mapLabel(props.initialValue ?? props.list[0]))
    const [showList, toggleList] = createToggleSignal(false)

    let limitRef: HTMLParagraphElement

    const onSelect = (_: string, index) => {
        setCurrentValue(props.mapLabel(props.list[index]))
        props.onSelect?.()
        toggleList()
    }

    return (
        <div class={styles.container}>
            <Show when={props.title}>
                <p class={pageStyles.subTitle}>{props.title}</p>
            </Show>

            <p class={styles.input} ref={limitRef} onclick={toggleList}>{currentValue()} <i class={'fa-solid fa-caret-down'} /></p>
            <PopupListSelector
                list={props.list.map(i => props.mapLabel(i))}
                onSelect={onSelect}
                onDismiss={toggleList}
                selection={currentValue()}
                enabled={showList()}
                locatorElement={limitRef}
                appearance={{
                    list: styles.list,
                    listItem: styles.listItem,
                }}
                placeholder={props.placeholder}
            />
        </div>
    )
}