import {createToggleSignal} from '../../hooks/createToggleSignal'
import {TreeNode, TreeNodeProps} from './TreeNode'
import {Show} from 'solid-js'

export type TreePropProps = TreeNodeProps & {
    tag: string | number,
    value: unknown
}

export function TreeProp(props: TreePropProps) {
    const [showChildren, toggleShowChildren] = createToggleSignal()

    const toggle = (e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()

        if (e.metaKey && props.treeResolver.onMetaClick) {
            props.treeResolver.onMetaClick(props.value, props.node, props.path, e.currentTarget as HTMLElement)
        }

        else if (e.altKey && props.treeResolver.onAltClick) {
            props.treeResolver.onAltClick(props.value, props.node, props.path, e.currentTarget as HTMLElement)
        }

        else if (e.shiftKey && props.treeResolver.onShiftClick) {
            props.treeResolver.onShiftClick(props.value, props.node, props.path, e.currentTarget as HTMLElement)
        }

        else {
            toggleShowChildren()
        }
    }

    const hasChildren = () => props.treeResolver.hasChildren(props.value)

    return (
        <li onClick={toggle} data-open={hasChildren() ? showChildren() : null}>
            <dl>
                <dt>{props.tag}: </dt>
                <dd>{props.treeResolver.displayValue(props.value, props.node, props.path, showChildren())}</dd>
            </dl>
            <Show when={hasChildren()}>
                <TreeNode showChildren={showChildren()} node={props.value} treeResolver={props.treeResolver} path={props.path}/>
            </Show>
        </li>
    )
}

