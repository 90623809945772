import {createStore, SetStoreFunction, Store} from 'solid-js/store'
import {Draft} from '@peachy/utility-kit-pure'
import {MrSubscriptionQuoteRequest, MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'

export class SubscriptionQuoteStore {

    private quoteRequestStore: Store<Draft<MrSubscriptionQuoteRequest>>
    private updateQuoteRequestStore: SetStoreFunction<Draft<MrSubscriptionQuoteRequest>>

    private quoteResponseStore: Store<MrSubscriptionQuoteResponse>
    private updateQuoteResponseStore: SetStoreFunction<MrSubscriptionQuoteResponse>

    constructor() {
        [this.quoteRequestStore, this.updateQuoteRequestStore] = createStore<Draft<MrSubscriptionQuoteRequest>>({})
        ;[this.quoteResponseStore, this.updateQuoteResponseStore] = createStore<MrSubscriptionQuoteResponse>(null)
    }

    updateQuoteRequest(quoteRequest: Draft<MrSubscriptionQuoteRequest>) {
        this.updateQuoteRequestStore(quoteRequest)
    }

    updateQuoteResponse(quoteResponse: MrSubscriptionQuoteResponse) {
        this.updateQuoteResponseStore(quoteResponse)
    }

    clearQuoteResponse() {
        this.updateQuoteResponse(null)
    }

    quoteRequest() {
        return this.quoteRequestStore
    }

    quoteResponse() {
        return this.quoteResponseStore
    }
}
