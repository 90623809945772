import {createEffect, onCleanup} from 'solid-js'

export type KeyboardEventType = 'keyup' | 'keydown' | 'keypress'
export type KeyHandler = (evt: KeyboardEvent) => void




export function useOnKey(type: KeyboardEventType, handler: KeyHandler, key?: string) {
    const keyHandler = (evt: KeyboardEvent) => {
        if (!key || evt.key === key) {
            handler(evt)
        }
    }
    createEffect(() => {
        window.addEventListener(type, keyHandler)
        onCleanup(() => {
            window.removeEventListener(type, keyHandler)
        })
    })
}


export function useOnKeyDown(handler: KeyHandler, key?: string) {
    useOnKey('keydown', handler, key)
}
export function useOnKeyUp(handler: KeyHandler, key?: string) {
    useOnKey('keyup', handler, key)
}


export function useOnEscapeKey(handler: KeyHandler) {
    useOnKeyDown(handler, 'Escape')
}

export function useOnEnterKey(handler: KeyHandler) {
    useOnKeyDown(handler, 'Enter')
}
