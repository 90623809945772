import {Expander} from '@peachy/client-kit'
import {createSignal, ParentComponent} from 'solid-js'
import styles from './Exclusion.module.css'

export const Exclusion: ParentComponent<{ name: string }> = (props) => {
    const [isExpanded, setIsExpanded] = createSignal(false)

    return (
        <li class={styles.Exclusion}>
            <div onClick={() => setIsExpanded(e => !e)}>
                <h6>{props.name}</h6>
                <span>{isExpanded() ? '-' : '+'}</span>
            </div>
            <Expander expanded={isExpanded()} tag={'article'}>
                {props.children}
            </Expander>
        </li>
    )
}