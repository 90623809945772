export function PasswordPolicy() {
    return (
        <ul>
            <li>Must contain at least 1 number</li>
            <li>Must contain at least 1 uppercase letter</li>
            <li>Must contain at least 1 lowercase letter</li>
            <li>Must contain at least 1 special character</li>
            <li>Minimum length of 8 characters</li>
        </ul>
    )

}

