import {A} from '@solidjs/router'
import {PeachyLogo} from '../../../global/assets/PeachyLogo/PeachyLogo'
import {Header, LogoutLink} from '../Header'
import styles from './BrokerDashboardHeader.module.css'
import {MyQuotesPath} from '../../../App'


export function BrokerDashboardHeader() {

    return <Header class={styles.header}>
        <div>
            <A href={MyQuotesPath}>My quotes</A>
            {/* <A href={"/my-clients"}>My clients</A> */}
        </div>
        <PeachyLogo/>
        <div>
            <LogoutLink />
        </div>
    </Header>
}
