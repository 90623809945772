import {TextBox, TextBoxProps} from '../TextBox/TextBox'
import {Icon, Icons} from '../Icon'
import {createSignal} from 'solid-js'
import styles from './PasswordBox.module.css'

export function PasswordBox(props: Omit<TextBoxProps, 'type'>) {

    const [secret, setSecret] = createSignal(true)

    const icon = () => secret() ? Icons.eyeShut : Icons.eyeOpen
    const mode = () => secret() ? 'password' : 'text'

    return (
        <TextBox {...props} placeholder={props.placeholder ?? 'password'} type={mode()} class={styles.PasswordBox}>
            <Icon icon={icon()} onClick={() => setSecret(s => !s)}/>
        </TextBox>
    )
}