import {KeyMapped} from '@peachy/utility-kit-pure'
import {EmailData} from './EmailData'
import {Plan} from '../Plan'
import {ChangedLife} from '../../../event/models/ChangedLife'

export type CompanyPolicyDocChangesEmailData = EmailData & {
    plans: KeyMapped<Plan>
    addedLives?: ChangedLife[]
    removedLives?: ChangedLife[]
}
