import styles from './AddChild.module.css'

export default function () {
    return (
        <svg class={styles.addChild} version="1.1" viewBox="0 0 1367.26 1200" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path class="cls-1" d="m1104.2,835.53c-8.74-15.15-25.04-24.55-42.53-24.55h-25.39v-25.39c0-17.49-9.41-33.79-24.55-42.53-15.15-8.74-33.96-8.75-49.11,0-15.15,8.74-24.55,25.04-24.55,42.53v25.39h-25.39c-17.49,0-33.79,9.41-42.53,24.55-8.74,15.15-8.74,33.96,0,49.11,8.74,15.15,25.04,24.55,42.53,24.55h25.39v25.38c0,17.49,9.41,33.79,24.55,42.53,7.57,4.37,16.06,6.56,24.55,6.56s16.98-2.19,24.55-6.56c15.15-8.74,24.55-25.04,24.55-42.53v-25.38h25.39c17.49,0,33.79-9.41,42.53-24.55,8.74-15.15,8.74-33.96,0-49.11Z" />
                <path class="cls-1" d="m868.49,665.92l-.88.83c-11.79,11.94-16.64,28.85-12.97,45.22,3.67,16.38,15.26,29.6,31.02,35.38,15.76,5.78,33.15,3.18,46.53-6.94l.84-.68c20.4-17.73,36.82-40.23,47.46-65.08,10.65-24.84,15.62-52.25,14.39-79.25-1.75-38.19-15.21-74.23-38.92-104.24-23.71-30-55.66-51.42-92.42-61.95-36.76-10.53-75.21-9.28-111.21,3.6-36,12.89-66.5,36.33-88.21,67.8-21.72,31.47-32.81,68.31-32.09,106.53.61,32.31,9.61,63.3,26.2,90.61-22.8,14.29-44.24,30.78-63.91,49.19-14.4,13.54-25.67,29.6-33.5,47.75-7.83,18.14-11.79,37.37-11.77,57.11v33.11c0,26.38,10.28,51.19,28.93,69.84,18.66,18.66,43.46,28.93,69.84,28.93h157.26c17.49,0,33.79-9.41,42.53-24.55,8.74-15.15,8.74-33.96,0-49.11s-25.04-24.55-42.53-24.55h-157.26c-.14,0-.29-.06-.4-.17-.1-.1-.16-.24-.16-.38v-33.09c.02-12.44,5.23-24.45,14.37-33.02,27.46-26.02,59.17-46.4,94.25-60.57,11.04-4.36,20.12-12.56,25.58-23.11,5.47-10.56,6.91-22.75,4.07-34.31-2.85-11.55-9.78-21.67-19.53-28.49l-.13-.09c-19.73-13.51-32.71-34.88-35.62-58.62-2.91-23.74,4.54-47.61,20.42-65.48,15.81-17.79,38.49-27.98,62.34-27.98.11,0,.23,0,.34,0h3.62c20.8.97,40.37,9.59,55.14,24.28,14.85,14.77,23.58,34.42,24.58,55.34,1.22,25.36-9.06,49.46-28.21,66.13Z" />
            </g>
        </svg>
    )
}