import {Match, Show, Switch} from 'solid-js'
import {useIamService} from '../../../providers/AppServiceProvider'

export function IamError() {
    const iamService = useIamService()

    return (
        <Show when={!!iamService.error()}>
            <Switch fallback={<mark>Login details not recognised</mark>}>
                <Match when={iamService.error() === 'PasswordPolicyException'}><mark>Password does not comply with policy</mark></Match>
                <Match when={iamService.error() === 'PasswordExpired'}><mark>Password expired - a new password has been sent your email</mark></Match>
                <Match when={iamService.error() === 'ExceededMaxAttempts'}><mark>Woah there! Too many attempts! Please try again later...</mark></Match>
            </Switch>
        </Show>
    )

}
