import styles from './MoreInfoModal.module.css'
import {DismissibleModal} from './DismissibleModal'
import {classList} from '@peachy/utility-kit-pure'
import {ModalProps} from '@peachy/client-kit'


export function MoreInfoModal(props: ModalProps) {
    const modalClasses = classList(styles.moreInfoModal, props.class)
    return (
        <DismissibleModal isOpen={props.isOpen} onDismiss={props.onDismiss} class={modalClasses} >
            {props.children}
        </DismissibleModal>
    )
}
