import {WebZoneConfig} from './src/config-types/WebZoneConfig'
import {CloudZoneConfig} from './src/config-types/CloudZoneConfig'
import {ZoneInfo} from './src/config-types/ZoneInfo'

export * from './src/config-types/CloudZoneConfig'
export * from './src/config-types/WebZoneConfig'
export * from './src/config-types/ZoneInfo'

export * from './src/get-zone-config'


const zoneTypeMap = {
    WebZoneConfig,
    CloudZoneConfig,
    ZoneInfo
} as const


export type ZoneTypeMap = typeof zoneTypeMap
export type ZoneConfigType = keyof ZoneTypeMap
export type ZoneConfig = InstanceType<ZoneTypeMap[ZoneConfigType]>


        import * as me from '@peachy/zone-config-pure'
        
        Object.entries(me).forEach(([exportedAs, exported]) => {
            if (exported && Object.isExtensible(exported)) {
                exported[Symbol.for('__WORKSPACE_HOME')] = '/Users/dalph/DalphSpace/peachy-home/repos/peachy-mono-repo/zone-config/pure'
                exported[Symbol.for('__WORKSPACE_NAME')] = '@peachy/zone-config-pure'
                exported[Symbol.for('__EXPORTED_AS')] = exportedAs
            }
        })
    