import {Match, Switch} from 'solid-js'
import {DashboardPlanBuilder} from './DashboardPlanBuilder'
import {AccountSubscriptionProvider} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {getSolidStore, isEditingPlan, YourPlanProvider} from './YourPlanProvider'
import {EditPlan} from './EditPlan'
import {YourPlanInfoPanels} from '../panels/YourPlanInfoPanels/YourPlanInfoPanels'
import styles from './YourPlan.module.css'

export const YourPlan = () => {
    return (
        <YourPlanProvider>
            <Switch>
                <Match when={isEditingPlan()}><EditPlan/></Match>
                <Match when={!isEditingPlan()}><ViewPlan/></Match>
            </Switch>
        </YourPlanProvider>
    )
}

const ViewPlan = () => (
    <AccountSubscriptionProvider store={getSolidStore()}>
        <Content />
    </AccountSubscriptionProvider>
)

const Content = () => (
    <div class={styles.YourPlan}>
        <h2>Your Plan</h2>
        <YourPlanInfoPanels/>
        <DashboardPlanBuilder />
    </div>
)
