import Stripe from 'stripe'
import {StripeEventType, StripeEventTypes} from './stripe-event-types'
import {keysOf} from '@peachy/utility-kit-pure'

export type StripeObjectType = keyof StripeObjectTypeMap
export const StripeObjectTypes = keysOf<StripeObjectType>()


export type StripeObject<T extends StripeObjectType = undefined> =
    T extends undefined
        ? {
            object: StripeObjectType
            id: string
            metadata?: Record<string, any>
        }
        : StripeObjectTypeMap[T]


type StripeObjectTypeMap = {
    'customer': Stripe.Customer
    'setup_intent': Stripe.SetupIntent
    'payment_method': Stripe.PaymentMethod
    'card': Stripe.PaymentMethod.Card
    'invoice': Stripe.Invoice
    'checkout.session': Stripe.Checkout.Session
    'dispute': Stripe.Dispute
    'discount': Stripe.Discount
    'subscription': Stripe.Subscription
    'payout': Stripe.Payout
    'account': Stripe.Account
    'capability': Stripe.Capability
    'credit_note': Stripe.CreditNote
    'balance': Stripe.Balance
    'file': Stripe.File
    'coupon': Stripe.Coupon
    'line_item': Stripe.InvoiceLineItem
    'invoiceitem': Stripe.InvoiceItem
    'payment_intent': Stripe.PaymentIntent
    'charge': Stripe.Charge
    'source': Stripe.Source
    'tax_id': Stripe.TaxId
    'payment_link': Stripe.PaymentLink
    'person': Stripe.Person
    'plan': Stripe.Plan
    'price': Stripe.Price
    'product': Stripe.Product
    'quote': Stripe.Quote
    'recipient': Stripe.Recipient
    'source_transaction': Stripe.SourceTransaction
    'subscription_schedule': Stripe.SubscriptionSchedule

}

export type StripeEventObjectType<T extends StripeEventType> =
    T extends CustomerEventType ? Stripe.Customer :
        T extends PaymentIntentEventType ? Stripe.PaymentIntent :
            T extends SetupIntentEventType ? Stripe.SetupIntent :
                T extends SubscriptionEventType ? Stripe.Subscription :
                    T extends SubscriptionScheduleEventType ? Stripe.SubscriptionSchedule :
                        T extends InvoiceEventType ? Stripe.Invoice :
                            T extends InvoiceItemEventType ? Stripe.InvoiceItem :
                                T extends CouponEventType ? Stripe.Coupon :
                                    T extends ChargeEvents ? Stripe.Charge :
                                        T extends DisputeEvents ? Stripe.Dispute :
                                            T extends CheckoutEvents ? Stripe.Checkout.Session :
                                                T extends CreditNoteEventType ? Stripe.CreditNote :
                                                    T extends DiscountEventType ? Stripe.Discount :
                                                        T extends SourceEventType ? Stripe.Source :
                                                            T extends TaxIdEventType ? Stripe.TaxId :
                                                                T extends PaymentLinkEventType ? Stripe.PaymentLink :
                                                                    T extends PaymentMethodEventType ? Stripe.PaymentMethod :
                                                                        T extends PayoutEventType ? Stripe.Payout :
                                                                            T extends PersonEventType ? Stripe.Person :
                                                                                T extends PlanEventType ? Stripe.Plan :
                                                                                    T extends PriceEventType ? Stripe.Price :
                                                                                        T extends ProductEventType ? Stripe.Product :
                                                                                            T extends QuoteEventType ? Stripe.Quote :
                                                                                                T extends RecipientEventType ? Stripe.Recipient :
                                                                                                    T extends SourceTransactionEventType ? Stripe.SourceTransaction :
                                                                                                        T extends AccountEventType ? Stripe.Account :
                                                                                                            StripeObject


type ChargeEvents =
    | typeof StripeEventTypes['charge.captured']
    | typeof StripeEventTypes['charge.expired']
    | typeof StripeEventTypes['charge.failed']
    | typeof StripeEventTypes['charge.pending']
    | typeof StripeEventTypes['charge.refunded']
    | typeof StripeEventTypes['charge.succeeded']
    | typeof StripeEventTypes['charge.updated']

type DisputeEvents =
    | typeof StripeEventTypes['charge.dispute.created']
    | typeof StripeEventTypes['charge.dispute.updated']
    | typeof StripeEventTypes['charge.dispute.closed']
    | typeof StripeEventTypes['charge.dispute.funds_withdrawn']
    | typeof StripeEventTypes['charge.dispute.funds_reinstated']

type CheckoutEvents =
    | typeof StripeEventTypes['checkout.session.completed']
    | typeof StripeEventTypes['checkout.session.expired']
    | typeof StripeEventTypes['checkout.session.async_payment_succeeded']
    | typeof StripeEventTypes['checkout.session.async_payment_failed']

type CouponEventType =
    | typeof StripeEventTypes['coupon.created']
    | typeof StripeEventTypes['coupon.updated']
    | typeof StripeEventTypes['coupon.deleted']

type CreditNoteEventType =
    | typeof StripeEventTypes['credit_note.created']
    | typeof StripeEventTypes['credit_note.updated']
    | typeof StripeEventTypes['credit_note.voided']

type CustomerEventType =
    | typeof StripeEventTypes['customer.created']
    | typeof StripeEventTypes['customer.deleted']
    | typeof StripeEventTypes['customer.updated']

type DiscountEventType =
    | typeof StripeEventTypes['customer.discount.created']
    | typeof StripeEventTypes['customer.discount.updated']
    | typeof StripeEventTypes['customer.discount.deleted']

type SourceEventType =
    | typeof StripeEventTypes['customer.source.created']
    | typeof StripeEventTypes['customer.source.updated']
    | typeof StripeEventTypes['customer.source.deleted']
    | typeof StripeEventTypes['customer.source.expiring']
    | typeof StripeEventTypes['source.canceled']
    | typeof StripeEventTypes['source.chargeable']
    | typeof StripeEventTypes['source.failed']
    | typeof StripeEventTypes['source.mandate_notification']
    | typeof StripeEventTypes['source.refund_attributes_required']

type SubscriptionEventType =
    | typeof StripeEventTypes['customer.subscription.created']
    | typeof StripeEventTypes['customer.subscription.updated']
    | typeof StripeEventTypes['customer.subscription.deleted']
    | typeof StripeEventTypes['customer.subscription.pending_update_applied']
    | typeof StripeEventTypes['customer.subscription.pending_update_expired']
    | typeof StripeEventTypes['customer.subscription.trial_will_end']

type SubscriptionScheduleEventType =
    | typeof StripeEventTypes['subscription_schedule.created']
    | typeof StripeEventTypes['subscription_schedule.updated']
    | typeof StripeEventTypes['subscription_schedule.completed']
    | typeof StripeEventTypes['subscription_schedule.canceled']
    | typeof StripeEventTypes['subscription_schedule.aborted']
    | typeof StripeEventTypes['subscription_schedule.expiring']
    | typeof StripeEventTypes['subscription_schedule.released']


type TaxIdEventType =
    | typeof StripeEventTypes['customer.tax_id.created']
    | typeof StripeEventTypes['customer.tax_id.updated']
    | typeof StripeEventTypes['customer.tax_id.deleted']

type InvoiceEventType =
    | typeof StripeEventTypes['invoice.created']
    | typeof StripeEventTypes['invoice.sent']
    | typeof StripeEventTypes['invoice.updated']
    | typeof StripeEventTypes['invoice.upcoming']
    | typeof StripeEventTypes['invoice.paid']
    | typeof StripeEventTypes['invoice.voided']
    | typeof StripeEventTypes['invoice.deleted']
    | typeof StripeEventTypes['invoice.payment_succeeded']
    | typeof StripeEventTypes['invoice.payment_action_required']
    | typeof StripeEventTypes['invoice.payment_failed']
    | typeof StripeEventTypes['invoice.finalized']
    | typeof StripeEventTypes['invoice.finalization_failed']
    | typeof StripeEventTypes['invoice.marked_uncollectible']

type InvoiceItemEventType =
    | typeof StripeEventTypes['invoiceitem.created']
    | typeof StripeEventTypes['invoiceitem.updated']
    | typeof StripeEventTypes['invoiceitem.deleted']


type PaymentIntentEventType =
    | typeof StripeEventTypes['payment_intent.created']
    | typeof StripeEventTypes['payment_intent.succeeded']
    | typeof StripeEventTypes['payment_intent.canceled']
    | typeof StripeEventTypes['payment_intent.requires_action']
    | typeof StripeEventTypes['payment_intent.payment_failed']
    | typeof StripeEventTypes['payment_intent.amount_capturable_updated']
    | typeof StripeEventTypes['payment_intent.partially_funded']
    | typeof StripeEventTypes['payment_intent.processing']

type PaymentLinkEventType =
    | typeof StripeEventTypes['payment_link.created']
    | typeof StripeEventTypes['payment_link.updated']

type PaymentMethodEventType =
    | typeof StripeEventTypes['payment_method.attached']
    | typeof StripeEventTypes['payment_method.detached']
    | typeof StripeEventTypes['payment_method.updated']
    | typeof StripeEventTypes['payment_method.automatically_updated']

type PayoutEventType =
    | typeof StripeEventTypes['payout.canceled']
    | typeof StripeEventTypes['payout.created']
    | typeof StripeEventTypes['payout.failed']
    | typeof StripeEventTypes['payout.updated']
    | typeof StripeEventTypes['payout.paid']

type PersonEventType =
    | typeof StripeEventTypes['person.created']
    | typeof StripeEventTypes['person.updated']
    | typeof StripeEventTypes['person.deleted']

type PlanEventType =
    | typeof StripeEventTypes['plan.created']
    | typeof StripeEventTypes['plan.updated']
    | typeof StripeEventTypes['plan.deleted']

type PriceEventType =
    | typeof StripeEventTypes['price.created']
    | typeof StripeEventTypes['price.updated']
    | typeof StripeEventTypes['price.deleted']

type ProductEventType =
    | typeof StripeEventTypes['product.created']
    | typeof StripeEventTypes['product.updated']
    | typeof StripeEventTypes['product.deleted']

type QuoteEventType =
    | typeof StripeEventTypes['quote.accepted']
    | typeof StripeEventTypes['quote.created']
    | typeof StripeEventTypes['quote.canceled']
    | typeof StripeEventTypes['quote.finalized']

type RecipientEventType =
    | typeof StripeEventTypes['recipient.created']
    | typeof StripeEventTypes['recipient.updated']
    | typeof StripeEventTypes['recipient.deleted']

type SetupIntentEventType =
    | typeof StripeEventTypes['setup_intent.created']
    | typeof StripeEventTypes['setup_intent.succeeded']
    | typeof StripeEventTypes['setup_intent.setup_failed']
    | typeof StripeEventTypes['setup_intent.requires_action']
    | typeof StripeEventTypes['setup_intent.canceled']

type SourceTransactionEventType =
    | typeof StripeEventTypes['source.transaction.created']
    | typeof StripeEventTypes['source.transaction.updated']


type AccountEventType =
    | typeof StripeEventTypes['account.updated']
    | typeof StripeEventTypes['account.application.authorized']
    | typeof StripeEventTypes['account.application.deauthorized']
    | typeof StripeEventTypes['account.external_account.created']
    | typeof StripeEventTypes['account.external_account.deleted']
    | typeof StripeEventTypes['account.external_account.updated']
    | typeof StripeEventTypes['customer.deleted']

