import {PreviousButton} from '../../PageRouter/PreviousButton'
import {PeachyLogo} from '../../../global/assets/PeachyLogo/PeachyLogo'
import {Header, LogoutLink} from '../Header'
import styles from './QuoteAndBuy.module.css'
import {Button} from '../../../global/forms/Button'
import {ROOT_DOMAIN_URL} from '../../../App'
import {Show} from 'solid-js'
import {useIamService} from '../../../providers/AppServiceProvider'

export function QuoteAndBuyHeader() {
    const iamService = useIamService()

    return <Header class={styles.header}>
        <PreviousButton/>
        <PeachyLogo/>
        <Show when={iamService.isSignedIn()} fallback={<CloseButton href={ROOT_DOMAIN_URL}/>}>
            <LogoutLink/>
        </Show>
    </Header>
}

function CloseButton(props: { href: string }) {
    return <Button role="link" theme="secondary" onClick={() => window.location.href = props.href}>
        <i class="fal fa-times"/>
    </Button>
}
