import isEmail from 'validator/es/lib/isEmail'

export {isEmail}

export {
    validatePassword,
    PasswordRequirements,
} from './validators/IsValidPassword'

export type {PasswordRequirement} from './validators/IsValidPassword'
