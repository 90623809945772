import {ItemDropdown} from './ItemDropdown/ItemDropdown'
import pageStyles from '../BrokerQuote.module.css'

export const QuoteVersion = () => {

    const versions = [
        'Directors only',
        'Whole team opt 1',
        'Whole team opt 2',
        'Duplicate quote'
    ]

    return (
        <div class={pageStyles.details}>
            <h4>Quote version</h4>
            <ItemDropdown
                list={versions}
                initialValue={versions[0]}
                placeholder="Choose version or duplicate"
                mapLabel={q => q}
            />
        </div>
    )
}