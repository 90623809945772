import {FAQS_URL} from '../../App'
import {Button} from '../../global/forms/Button'
import styles from './Help.module.css'

export const Help = () => {
    return (
        <section class={styles.Help}>
            <h2>NEED MORE HELP?</h2>
            <p>Check our FAQs page or get in touch with our team.</p>
            <div>
                <a href={FAQS_URL} target={'_blank'}><Button theme="secondary">FAQs</Button></a>
                <Button theme="secondary" onClick={() => Intercom('show')}>Help</Button>
            </div>
        </section>
    )
}
