import {NOOP} from '@peachy/utility-kit-pure'
import {IStore} from './IStore'

export class SolidStore<T> implements IStore<T> {
    constructor (readonly value: T) {}

    save (obj: T) {
        NOOP
    }

    get (): T {
       return this.value
    }

    delete () {
        NOOP
    }

    exists () {
        return !!this.value
    }

}
