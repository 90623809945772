import {Exclusion} from './Exclusion'

export const PreExistingConditions = () => {
    return (
        <Exclusion name={'Pre-existing conditions'}>
            <ul>
                <li>
                A pre-existing condition is any medical condition or related condition (whether diagnosed or not) 
                that any plan member has received medication, advice or treatment for, have experienced symptoms of, 
                or were aware existed in the three years before their cover start date</li>
                <li>
                We don't cover pre-existing conditions unless plan members have been treatment and symptom free for two continuous years while with Peachy
                </li>
                <li>
                This exclusion does not apply to Virtual GP, Advice & Counselling and Dental & Optical care (if you add this benefit)
                </li>
            </ul>
        </Exclusion>
    )
}