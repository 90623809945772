import {AccountSubscriptionProvider} from '../../providers/AccountSubscription/AccountSubscriptionProvider'
import {subscriptionQuoteSessionStore} from '../../providers/AccountSubscription/session/stores'
import {QuoteAndBuy} from './Quote'

const QuoteAndBuyProvider = () => {
    return (
            <AccountSubscriptionProvider store={subscriptionQuoteSessionStore()} saveToServer={true}>
                <QuoteAndBuy />
            </AccountSubscriptionProvider>
    )
}

export default QuoteAndBuyProvider
