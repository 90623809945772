import {DatePicker, DatePickerProps} from '@peachy/client-kit'
import styles from './PeachyDatePicker.module.css'
import buttonStyles from '../../global/forms/Button.module.css'
import {classList} from '@peachy/utility-kit-pure'

export function PeachyDatePicker(props: DatePickerProps) {
    // TODO if we can get composes working with our css modules build code, can refactor
    const appearance = {
        ...styles,
        submit: classList(buttonStyles.button, buttonStyles.solid, buttonStyles.primary),
    }

    return <DatePicker
        isOpen={props.isOpen}
        minDate={ props.minDate}
        maxDate={ props.maxDate}
        selectedDate={ props.selectedDate}
        appearance={ appearance}
        headerText={ props.headerText}
        submitText={ props.submitText}
        onSelect={ props.onSelect}
        onDismiss={props.onDismiss}
    />
}