import {enumerate} from '@peachy/utility-kit-pure'

export const QuoteBenefitTypes = enumerate([
    'HOSPITAL_CARE',
    'CONSULTATIONS_DIAGNOSTICS',
    'MENTAL_HEALTH',
    'THERAPIES',
    'DENTAL_OPTICAL',
    'REMOTE_CARE',
    'ADVICE_COUNSELLING',
] as const)

export type QuoteBenefitType = keyof typeof QuoteBenefitTypes

export type QuoteBenefitValues = {
    [_k in QuoteBenefitType]?: number
}

export type QuoteBenefitOverrides = {
    [_k in QuoteBenefitType]?: {
        coverStartDate?: string
        renewalPeriodMonths?: number
    }
}

export const BENEFITS_WITH_LIMITS: QuoteBenefitType[] = [QuoteBenefitTypes.HOSPITAL_CARE, QuoteBenefitTypes.CONSULTATIONS_DIAGNOSTICS, QuoteBenefitTypes.THERAPIES]

export const BENEFITS_WITH_EXCESS: QuoteBenefitType[] = [QuoteBenefitTypes.HOSPITAL_CARE, QuoteBenefitTypes.CONSULTATIONS_DIAGNOSTICS, QuoteBenefitTypes.THERAPIES]

export const FIXED_PRICE_BENEFITS: QuoteBenefitType[] = [QuoteBenefitTypes.DENTAL_OPTICAL, QuoteBenefitTypes.REMOTE_CARE, QuoteBenefitTypes.ADVICE_COUNSELLING]
