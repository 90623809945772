import {createContext, createEffect, onCleanup, ParentProps, useContext} from 'solid-js'
import {KeyHandler} from '../hooks/useOnKey'
import {invokeHandlers, Key, KeyboardHandlerEntry, KeyEventType} from './keyboard-controller-kit'

const KeyboardContextKey = createContext<KeyboardHandlerEntry[]>([])

const heldKeys = new Set<Key>()

export function KeyboardController(props: ParentProps) {

    const handlerStack: KeyboardHandlerEntry[] = []

    const onKeys = (evt: KeyboardEvent) => {
        if (evt.type === 'keydown') {
            heldKeys.add(evt.key)
        } else if (evt.type === 'keyup') {
            heldKeys.delete(evt.key)
        }

        const applyModifierClass = (key: boolean, className: string) => {
            if (key) {
                document.body.classList.add(className)
            } else {
                document.body.classList.remove(className)
            }
        }

        applyModifierClass(evt.metaKey, 'metaPressed')
        applyModifierClass(evt.altKey, 'altPressed')
        applyModifierClass(evt.ctrlKey, 'ctrlPressed')
        applyModifierClass(evt.shiftKey, 'shiftPressed')
        applyModifierClass(heldKeys.has(' '), 'spacePressed')

        invokeHandlers(evt, handlerStack)
    }

    document.addEventListener('keyup', onKeys)
    document.addEventListener('keydown', onKeys)

    onCleanup(() => {
        document.removeEventListener('keyup', onKeys)
        document.removeEventListener('keydown', onKeys)
    })

    return (
        <KeyboardContextKey.Provider value={handlerStack}>
            {props.children}
        </KeyboardContextKey.Provider>
    )
}



export function useOnKey(handler: KeyHandler, type?: KeyEventType, key?: Key) {
    createEffect(() => {
        const listenerStack = useContext(KeyboardContextKey)
        if (!listenerStack) throw new Error('No ancestor KeyboardController component')
        const handlerEntry: KeyboardHandlerEntry = {
            type, key, handler
        }
        listenerStack.push(handlerEntry)
        onCleanup(() => {
            const i = listenerStack.findIndex(e => e === handlerEntry)
            if (i >= 0) {
                listenerStack.splice(i, 1)
            }
        })
    })
}


export function useOnKeyDown(handler: KeyHandler, key?: Key) {
    useOnKey(handler, 'keydown', key)
}

export function useOnKeyUp(handler: KeyHandler, key?: Key) {
    useOnKey(handler, 'keyup', key)
}


export function useOnEscapeKey(handler: KeyHandler) {
    useOnKeyDown(handler, 'Escape')
}

export function useOnEnterKey(handler: KeyHandler) {
    useOnKeyDown(handler, 'Enter')
}
