import {QuoteHeader} from '../../../components/Quote/QuoteHeader/QuoteHeader'
import {QuoteFooter} from '../../../components/Quote/QuoteFooter/QuoteFooter'
import {AnnualLimitInfo} from '../../../components/AnnualLimitInfo/AnnualLimitInfo'
import {Help} from '../../../components/Help/Help'
import {WhatsNotCovered} from '../../../components/WhatsNotCovered/WhatsNotCovered'
import {StartDate} from '../../../components/StartDate/StartDate'
import {Ipid} from '../../../components/Documents/Ipid/Ipid'
import {QuotePlanBuilder} from './QuotePlanBuilder'
import {DownloadCompanySamplePlan} from './DownloadCompanySamplePlan'
import styles from './CapturePlanDetails.module.css'

export function CapturePlanDetails() {
    return (
        <>
            <QuoteHeader/>
            <div class={styles.planDetails}>
                <StartDate datePicker/>
                <QuotePlanBuilder/>
                <AnnualLimitInfo/>
                <p class={styles.planDocLinks}>Download <DownloadCompanySamplePlan/> and <Ipid /></p>
            </div>
            <WhatsNotCovered/>
            <Help/>
            <QuoteFooter/>
        </>
    )
}
