import {classList} from '@peachy/utility-kit-pure'
import {JSX, splitProps} from 'solid-js'
import styles from './Button.module.css'

type ButtonTheme = 'primary' | 'secondary' | 'light' | 'dark' | 'darkSecondary'

export type ButtonProps = JSX.ButtonHTMLAttributes<HTMLButtonElement> & {
    theme?: ButtonTheme
}

const themeStyles = {
    primary: styles.primary,
    secondary: styles.secondary,
    light: styles.light,
    dark: styles.dark,
    darkSecondary: styles.darkSecondary
}

const roleStyles = {
    button: styles.solid,
    link: styles.link
}

export function Button (props: ButtonProps) {
    const [otherProps, buttonProps] = splitProps(props, ['theme'])

    const themeCss = themeStyles[otherProps.theme]
    const roleCss = roleStyles[buttonProps.role ?? 'button']

    const classes = classList(styles.button, buttonProps.class, themeCss, roleCss)

    return (
        <button {...buttonProps} class={classes}>
            {buttonProps.children}
        </button>
    )
}
