import {MrSubscriptionQuoteResponse} from '@peachy/core-domain-pure'
import {subscriptionQuoteSessionStore} from '../../providers/AccountSubscription/session/stores'
import {Draft} from '@peachy/utility-kit-pure'
import {useSubscriptionService} from '../../providers/AppServiceProvider'

export async function loadSubscriptionQuote (): Promise<Draft<MrSubscriptionQuoteResponse>> {
    const sessionStore = subscriptionQuoteSessionStore()

    sessionStore.delete() // delete any in-flight subscription requests

    const storedSubscriptionRequest = sessionStore.get()

    if (storedSubscriptionRequest) {
        return storedSubscriptionRequest
    }

    const subscriptionRequest = await useSubscriptionService().latestSmeDraftQuote()

    if (subscriptionRequest) {
        sessionStore.save(subscriptionRequest)
    }

    return subscriptionRequest as Draft<MrSubscriptionQuoteResponse>
}
