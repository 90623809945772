import {classList} from '@peachy/utility-kit-pure'
import {useIamService} from '../../providers/AppServiceProvider'
import {
    editSubscriptionSessionStore,
    subscriptionQuoteSessionStore
} from '../../providers/AccountSubscription/session/stores'
import {Component, ParentComponent, Show} from 'solid-js'
import {ROOT_DOMAIN_URL} from '../../App'
import styles from './Header.module.css'

export const Header: ParentComponent<{ class?: string }> = (props) => {

    const classes = classList(styles.header, props.class)

    return <header class={classes}>
        {props.children}
    </header>
}

export const LogoutLink: Component = () => {
    const iamService = useIamService()

    const logout = () => {
        subscriptionQuoteSessionStore().delete()
        editSubscriptionSessionStore().delete()
        iamService.signOut(ROOT_DOMAIN_URL)
    }

    return (
        <Show when={iamService.isSignedIn()} fallback={<></>}>
            <a onClick={logout}>Log out</a>
        </Show>
    )
}
