import {MrLife, MrPolicy} from '@peachy/core-domain-pure'
import {Draft} from '@peachy/utility-kit-pure'
import {Component} from 'solid-js'
import {useStore} from '../../../providers/AccountSubscription/AccountSubscriptionProvider'
import {validateLifeField} from '../../../service/validation/ValidationService'
import styles from './PolicyRow.module.css'
import {DependantRows} from './row/DependantRows'
import {PrimaryLifeRow} from './row/PrimaryLifeRow'
import {SecondLifeRow} from './row/SecondLifeRow'

export const validateRequiredFieldCss = (life: Draft<MrLife>, field: keyof Draft<MrLife>) => validateLifeField(life, field) ? '' : styles.invalidField
export const validateOptionalFieldCss = (life: Draft<MrLife>, field: keyof Draft<MrLife>) => life[field] && !validateLifeField(life, field) ? styles.invalidField : ''
export const isValidOptionalField = (life: Draft<MrLife>, field: keyof Draft<MrLife>) => life[field] && validateLifeField(life, field)

export const validateFieldCss = (life: Draft<MrLife>, field: keyof Draft<MrLife>) => {
    const store = useStore()
    return store.isFullValidation() ? validateRequiredFieldCss(life, field) : validateOptionalFieldCss(life, field)
}
export type LifeRowCellProps = {
    life?: Draft<MrLife>
    policyId?: string
    policyIndex?: number
    readonly?: boolean
    placeholder?: string
    onBlur?: () => void
}

export const PolicyRow: Component<{
    policy: Draft<MrPolicy>,
    policyIndex: number
}> = (props) => {

    return (
        <>
            <PrimaryLifeRow policy={props.policy} policyIndex={props.policyIndex} />
            <SecondLifeRow policy={props.policy} policyIndex={props.policyIndex} />
            <DependantRows policy={props.policy} policyIndex={props.policyIndex} />
        </>
    )
}
